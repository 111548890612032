import React, { useState } from "react";
import { Button, IconButton, Typography, Divider } from "@material-ui/core";
import { useEffect } from "react";
import { PropertyRenderer } from "../propertyspanel/propertyRenderer";
import DeleteIcon from "@material-ui/icons/Delete";
import {
   
    MuiSelect,
  

} from "../../common/inputs";
import {TextField} from "@material-ui/core"


export const Customedate = (props) => {
    //const [propertiesState, setPropertiesState] = useState([]);

    const { handleChange, name, data, toRender, values, properties, index, propertyKey, propertiesOptions } = props;

    //   const handlePropertiesChange = (key, val, index) => {

    //     let propertiesStateCopy = JSON.parse(JSON.stringify(propertiesState));
    //     propertiesStateCopy.forEach((property, propertyIndex, array) => {
    //       if (propertyIndex === index) {
    //         array[index] = {
    //           ...property,
    //           [key]: val,
    //         };
    //       }
    //     });
    //     setPropertiesState(propertiesStateCopy);
    //   };

    //   const createNewObject = () => {
    //     let result = Object.keys(toRender).reduce((prev, curr) => {
    //       return {
    //         ...prev,
    //         [curr]: "",
    //       };
    //     }, {});
    //     return result;
    //   };

    //   const removeObject = (index) => {
    //     let propertiesStateCopy = JSON.parse(JSON.stringify(propertiesState));
    //     propertiesStateCopy.forEach((property, propertyIndex, array) => {
    //       if (propertyIndex === index) {
    //         array.splice(propertyIndex, 1);
    //       }
    //     });
    //     setPropertiesState(propertiesStateCopy);
    //   };

    //   useEffect(() => {
    //     if (values.length > 0) {
    //       setPropertiesState(values);
    //     } else {
    //       let newObject = createNewObject();
    //       console.log(newObject);
    //       setPropertiesState([newObject]);
    //     }
    //   }, []); //empty=> added dependencies

    //   useEffect(() => {
    //     handleChange(name, propertiesState);
    //   }, [name, propertiesState]);

    return (
        <div style={{ display: "grid", gap: "16px 0px" }}>

            <MuiSelect
                value={properties[propertyKey] ?? ""}
                onChange={(e) => {
                    handleChange(propertyKey, e.target.value, index);
                }}
                label={propertyKey}
                options={propertiesOptions.options}
            />

            {(() => {

                if (properties[propertyKey] == "Custome") {
                    
                    return (
                    <TextField
                        id="outlined-name"
                        label={`${propertyKey}value`}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{ shrink: true}}
                        value={properties[`${propertyKey}value`] ?? ""}
                        onChange={(e) => {
                            handleChange(`${propertyKey}value`, e.target.value, index);
                        }}

                    />
                    )

                }

                // else if (properties[propertyKey] == "Current") {
                    
                //     return (
                //     <TextField
                //         id="outlined-name"
                //         label={`${propertyKey} value`}
                //         variant="outlined"
                //         type="date"
                //         InputLabelProps={{ shrink: true}}
                //         disabled={true}
                //         value={new Date()}
                //         onChange={(e) => {
                //             handleChange(`${propertyKey} value`, "new Date()", index);
                //         }}

                //     />
                //     )
                // }
            })()}


        </div>
    );
};

