import {
  makeStyles,
  Typography,
  Button,
  Grid,
  Divider,
  CardActionArea,
  Card,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React from "react";
import { DrawerContext, AuthContext } from "../../contexts";
import { AddForm, MainForm } from "../../components";
import { useQuery } from "react-query";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
const useStyles = makeStyles((theme) => ({
  gridPadding: {
    padding: "1em 1.25em",
  },
  menuList: {
    fontSize: "0.9em",
    cursor: "pointer",
  },
  textResponsive: {
    fontWeight: "600",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.2rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2.2rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem !important",
    },
  },
}));
const getForm = async () => {
  const myHeader = new Headers();
  myHeader.append("content-type", "application/json");
  const res = await fetch(process.env.REACT_APP_READ_DOCUMENT, {
    headers: myHeader,
    method: "post",
    body: JSON.stringify({
      db_name: process.env.REACT_APP_DBNAME,
      entity: "form_details",
      filter: `form_details.metadataid=='${localStorage.getItem(
        "main_metadata_id"
      )}' AND form_details.activestatus==true`,
      return_fields: "form_details",
    }),
  });
  return res.json();
};
const Home = () => {
  const { data, status, refetch } = useQuery("form_details", getForm, {
    refetchOnWindowFocus: false,
  });
  const classes = useStyles();
  let cardData = data?.result;
  const drawer = React.useContext(DrawerContext);
  const auth = React.useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showForm, setShowForm] = React.useState(false);
  const [clickedCardIndex, setClickedCardIndex] = React.useState(0);
  const [formData, setFormData] = React.useState({});
  const [showMoreOption, setShowMoreOption] = React.useState(null);
  const handlePropsData = (val, index) => {
    setTimeout(() => refetch(), 100);
    if (index !== -1) {
      let cardListData = [...cardData];
      cardListData[index] = val;
      cardData = cardListData;
    } else {
      cardData = [...cardData, val];
    }
  };
  const drawerClose = () => {
    drawer.setDrawer({
      ...drawer,
      open: false,
    });
  };
  const handleClick = (event, index) => {
    setClickedCardIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = async () => {
    console.log(cardData[clickedCardIndex]);
    const myHeader = new Headers();
    myHeader.append("content-type", "application/json");
    await fetch(process.env.REACT_APP_SOFT_DELETE, {
      headers: myHeader,
      method: "post",
      body: JSON.stringify({
        db_name: process.env.REACT_APP_DBNAME,
        entity: "form_details",
        filter: `form_details.formMetadataId=='${cardData[clickedCardIndex].formMetadataId}'`,
      }),
    });
    setTimeout(() => refetch(), 100);

    // let totalCardData = [...cardData];
    // totalCardData.splice(clickedCardIndex, 1);
    // cardData = totalCardData;
    handleClose();
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(cardData[clickedCardIndex].formMetadataId);
    handleClose();
  };

  const handleEdit = () => {
    let totalCardData = [...cardData];
    totalCardData = totalCardData.filter((e, i) => i === clickedCardIndex);
    drawer.setDrawer({
      ...drawer,
      open: true,
      component: (
        <AddForm
          editData={totalCardData}
          dataIndex={clickedCardIndex}
          setCardData={handlePropsData}
          drawerClose={drawerClose}
        />
      ),
      onclose: drawerClose(),
    });
    handleClose();
  };

  const handleCardClick = (e, index) => {
    localStorage.setItem("formId", e.formMetadataId);
    localStorage.setItem("layoutValue", e.layout);
    auth.refresh();
    setFormData(e);
    setShowForm(true);
  };
  const addForm = () => {
    drawer.setDrawer({
      ...drawer,
      open: true,
      component: (
        <AddForm setCardData={handlePropsData} drawerClose={drawerClose} />
      ),
      onclose: drawerClose(),
    });
  };
  return (
    <div style={{ boxSizing: "border-box" }}>
      {!showForm && (
        <div>
          <Grid container className={classes.gridPadding}>
            <Grid container item xs={12} xl={12} md={12} sm={12} lg={12}>
              <Grid item xs={7} sm={9} md={10} lg={10} xl={11}>
                <Typography variant="h6">Form Configurator</Typography>
              </Grid>
              <Grid
                item
                xs={5}
                sm={3}
                md={2}
                lg={2}
                xl={1}
                container
                justifyContent="flex-end"
              >
                <Button
                  size="small"
                  startIcon={<AddIcon />}
                  color="primary"
                  variant="contained"
                  onClick={() => addForm()}
                >
                  Add Form
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider />

          <Grid container style={{ margin: "1em 0", padding: "0.5em" }}>
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
              {status === "success" &&
                data?.result.map((e, index) => (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    lg={2}
                    xl={2}
                    key={`${e}+${index}`}
                  >
                    <Card
                      onMouseEnter={() => setShowMoreOption(index)}
                      onMouseLeave={() => setShowMoreOption(null)}
                    >
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr ",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "grid",
                            alignSelf: "center",
                          }}
                        >
                          <CardActionArea
                            style={{ padding: "1em 1.2em" }}
                            onClick={() => handleCardClick(e, index)}
                          >
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "0.3fr 1fr",
                              }}
                            >
                              <div
                                style={{
                                  display: "grid",
                                  alignItems: "center",
                                }}
                              >
                                <AssignmentOutlinedIcon />
                              </div>
                              <div>
                                <Typography>{e.form}</Typography>
                                <Typography variant="caption">
                                  {e.type}
                                </Typography>
                              </div>
                            </div>
                          </CardActionArea>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "50%",
                            transform: "translate(-10%,-50%)",
                          }}
                        >
                          <div
                            style={{
                              visibility:
                                showMoreOption === index ? "visible" : "hidden",
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={(e) => handleClick(e, index)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </div>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem className={classes.menuList} onClick={handleCopy}>
          <ListItemIcon>
            <FileCopyIcon fontSize="small" />
          </ListItemIcon>
          Copy
        </MenuItem>
        <MenuItem className={classes.menuList} onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          Edit
        </MenuItem>
        <MenuItem className={classes.menuList} onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
      {showForm && <MainForm formData={formData} setShowForm={setShowForm} />}
    </div>
  );
};

export default Home;
