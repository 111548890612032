import React from "react";
// import TextField from "@material-ui/core/TextField";
import { Typography, Grid, Drawer, Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styles from "./styles";
import { generateComponent } from "./formsList";
import { withAllContexts } from "../../../HOCs";
//import { AlertProps } from "../../../utils";
import CloseIcon from "@material-ui/icons/Close";

const initialstates = {
  list: [],
  states: {},
  error: {},
};
function GenerateForm(props) {
  const classes = styles(props);
  const [state, setState] = React.useState(initialstates);
  const [isFirst, setIsFirst] = React.useState(false);
  const { ispadTop, nocancel, nosave = false, parent_id } = props;
  const handleChange = (type, val, isUpperCase, limit) => {
    if (limit) {
      if (val?.length > limit) {
        return false;
      }
    }
    if (props.onchangefun) {
      props.onchangefun(type, val?.value, val);
    }
    setState({
      ...state,
      states: {
        ...state.states,
        [type]: isUpperCase ? val.toUpperCase()?.replaceAll(" ", "") : val,
      },
    });
  };

  const validation = () => {
    let error = {};
    state.list.map((val) => {
      if (val.required) {
        if (
          val?.componet_type !== "select" &&
          state?.states[val?.state_name]?.length === 0
        ) {
          return (error[val.state_name] = true);
        } else if (val?.componet_type === "select") {
          if (
            state?.states[val?.state_name]
              ? Object.keys(state?.states[val?.state_name])?.length === 0
              : true
          )
            return (error[val.state_name] = true);
        } else if (val?.componet_type === "textField_select") {
          if (
            state?.states[val?.state_name]?.type
              ? Object.keys(state?.states[val?.state_name]?.type)?.length === 0
              : true
          ) {
            return (error[val.state_name] = true);
          }
          if (state?.states[val?.state_name]?.value?.length === 0) {
            return (error[val.state_name] = true);
          }
        }
      } else {
        return (error[val.state_name] = false);
      }
      return error;
    });
    if (
      error &&
      Object.keys(error).length > 0 &&
      Object.keys(error).filter((val) => error[val] === true).length > 0
    ) {
      setState({
        ...state,
        error: error,
      });
      return true;
    } else {
      setState({
        ...state,
        error: error,
      });
      return false;
    }
  };

  const handlesave = () => {
    if (!props?.children && validation()) {
      props.alert.setSnack("mandatory");
      return false;
    }
    if (props?.save) {
      // setState({})
      props?.children ? props.save() : props.save(state.states);
    }
  };

  React.useEffect(() => {
    if (!props?.children && !props?.loading && props.open) {
      let statesList = {};
      props.json.map((val) => {
        return (statesList[val.state_name] = val.value);
      });
      setState({
        ...state,
        list: props.json,
        states: statesList,
      });
      setIsFirst(true);
    }
    if (!props.open) {
      setIsFirst(false);
    }
  }, [props?.children, props.json, props?.loading, props.open, state]);

  React.useEffect(() => {
    if ((state?.list?.length ?? -1) > 0 && isFirst) {
      setState({
        ...state,
        list: props.json,
      });
    }
  }, [isFirst, props.json, state]);

  const handlecancel = () => {
    if (props?.handlecancel) {
      setState({});
      props.handlecancel();
    }
  };

  return (
    <Drawer
      id={`${parent_id}_generateform_parent_drawer`}
      className={classes.drawer}
      // variant="persistent"
      anchor="right"
      open={props.open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {
        <div
          id={`${parent_id}_generateform_parent_div`}
          style={{ paddingTop: ispadTop ? "55px" : "" }}
        >
          <Avatar
            id={`${parent_id}_generateform_close_avatar`}
            className={classes.large}
            onClick={() => handlecancel()}
          >
            <CloseIcon />
          </Avatar>
          <div id={`${parent_id}_generateform_header_div`}>
            <div
              id={`${parent_id}_generateform_header_div`}
              className={classes.drawerHead}
            >
              <Typography
                id={`${parent_id}_generateform_header_typography`}
                className={classes.headerMain}
              >
                {props.header}
              </Typography>
            </div>

            <Grid
              id={`${parent_id}_generateform_header_grid`}
              container
              direction="row"
              style={{ padding: "10px" }}
            >
              {/* <Grid
                id={`${parent_id}_generateform_header_sub_grid`}
                item xs={12}> */}
              {props?.children
                ? props?.children
                : state?.list?.length > 0 &&
                  state?.list?.map((val) => {
                    return generateComponent({
                      data: val,
                      handleChange: handleChange,
                      state: state,
                      classes: classes,
                      parent_id: parent_id,
                    });
                  })}
              <div
                id={`${parent_id}_generateform_button_div`}
                className={classes.btnPosition}
              >
                {!nocancel && (
                  <Button
                    id={`${parent_id}_generateform_Cancel_button`}
                    variant={"text"}
                    color={"primary"}
                    style={{ marginTop: 20, marginRight: 10 }}
                    size={"small"}
                    contained
                    onClick={() => handlecancel()}
                  >
                    Cancel
                  </Button>
                )}
                {!nosave && (
                  <Button
                    id={`${parent_id}_generateform_Save_button`}
                    disabled={props?.loading ?? false}
                    variant={"contained"}
                    color={"primary"}
                    style={{ marginTop: 20 }}
                    size={"small"}
                    contained
                    className={classes.saveButton}
                    onClick={() => handlesave()}
                  >
                    {props?.loading ? "Saving..." : "Save"}
                  </Button>
                )}
              </div>
              {/* </Grid> */}
            </Grid>
          </div>
        </div>
      }
    </Drawer>
  );
}

export default withAllContexts(GenerateForm);
