import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({}));

export const MuiMultiSelect = (props) => {
  console.log(props);
  const classes = useStyles(props);
  const {
    label,
    value,
    onChange,
    options,
    creatable,
    stringToBoject,
    optionViewKey,
  } = props;

  console.log(options);

  const optionOnChange = (newValue) => {
    const stringToBoject = (string) => {
      if (string?.length > 0) {
        let object = { value: string, [optionViewKey]: string };
        return object;
      } else {
        return string;
      }
    };
    let string = newValue[newValue?.length - 1] ?? "";
    if (stringToBoject(string)) {
      newValue[newValue?.length - 1] = stringToBoject(string);
      onChange(newValue);
    }
  };

  return (
    // <Autocomplete
    //   multiple
    //   id="tags-filled"
    //   options={options}
    //   value={value}
    //   // freeSolo={creatable}
    //   freeSolo={creatable}
    //   getOptionLabel={(option) => {
    //     return optionViewKey ? option[optionViewKey] : option;
    //   }}
    //   filterSelectedOptions={options.length > 0 ? true : false}
    //   onChange={(e, newValue) => {
    //     console.log(newValue);
    //     stringToBoject ? optionOnChange(newValue) : onChange(newValue);
    //   }}
    //   renderInput={(params) => (
    //     <TextField
    //       {...params}
    //       variant={"outlined"}
    //       label={label}
    //       size="small"
    //       margin={"dense"}
    //       placeholder={`${
    //         creatable ? "Create option and select" : "Select option"
    //       }`}
    //     />
    //   )}
    // />
    <Autocomplete
      multiple
      freeSolo
      onChange={(e, newValue) => {
        stringToBoject ? optionOnChange(newValue) : onChange(newValue);
      }}
      options={options}
      value={value}
      getOptionLabel={(option) => {
        return optionViewKey ? option[optionViewKey] : option;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Multiple values"
          placeholder="Favorites"
        />
      )}
      renderTags={(value, getTagProps) =>
        value?.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
    />
  );
};

MuiMultiSelect.defaultProps = {
  onChange: () => {},
  options: [],
  label: "Multi Select",
  value: [],
  creatable: false,
  stringToBoject: false,
  optionViewKey: null,
};
