import React from "react";
import { Grid } from "@material-ui/core";
import { AuthContext } from "../../../contexts";
const MemoizedSectionRender = (props) => {
  const auth = React.useContext(AuthContext);
  let {
    selectSection,
    id,
    children,
    value,
    index,
    style,
    className,
    ...other
  } = props;
  React.useEffect(() => {
    console.log("sectionSelect", selectSection);
    if (selectSection !== null && selectSection !== undefined) {
      auth.user.AllScreenList.forEach((screen) => {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.component.startsWith("Section")) {
            let GetChild = auth.user.section.filter(
              (e) => e?.componentName === selectSection?.title
            );
            if (component.id === id)
              component["child"] = GetChild[0].render.child;
          }
        });
      });
    } else if (selectSection === null && selectSection !== undefined) {
      auth.user.AllScreenList.forEach((screen) => {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.component.startsWith("Section")) {
            if (component.id === id) delete component["child"];
          }
        });
      });
    }
  }, [auth.user.AllScreenList, auth.user.section, selectSection]);

  return (
    <Grid className={className} item {...other}>
      {children}
    </Grid>
  );
};
export const SectionRender = React.memo(MemoizedSectionRender);
