import React, { useRef, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Menu,
  MenuItem,
  Divider,
  Chip,
  TextField,
  Hidden,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";

import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { useState } from "react";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { AuthContext, DialogContext, AlertContext } from "../../contexts";
import isEqual from "react-fast-compare";
// import * as All from "qdm-component-library";
// import * as AllPackages from "functional-component-library";
// import * as AllPackages from "atp_functional_component";
import * as AllMaterialCore from "@material-ui/core";
// import * as AllMaiCus from "custom-material-ui-component";
// import * as AllMaiCus from "custom-material-ui-component";
import * as AllMaterialLab from "@material-ui/lab";
// import * as AtpMasterLayout from "atp-master-layout";
// import * as AtpLogin from "atp-login-components";
import * as AtpTable from "atp-table";
// import * as AtpGeneralMaster from "atp-general-master";
// import * as AtpAuthentication from "atp-authentication";
// import * as AtpAuthorization from "atp-authorization";
// import * as AtpReportViewer from "report_viewer_atp";
import * as CustomText from "./func/index";
import * as AtpStepper from "atp-stepper";
import "./styles/styles.css";
import "../../App.css";
import { customStyle, idm_delete } from "../../utils";
import { AlertProps } from "../../utils";
import {
  layoutComponentsList,
  cloneComponent,
  deleteComponent,
  componentDeepIdRemover,
  makeid,
} from "./commonFunction";
import cloneDeep from "lodash/cloneDeep";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "30px",
    display: "flex",
    flexWrap: "wrap",
    minWidth: "2200px",
    maxWidth: "2200px",
  },
  screenArea: {
    // width: "320px",
    height: "520px",
    // margin: "1px auto auto auto",
    padding: "20px",
    paddingBottom: "90px",
  },
  arrowLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  middleLabel: {
    backgroundColor: "#fff",
    color: "black",
    fontWeight: 500,
    padding: 10,
    marginBottom: 10,
  },
  webView: {
    // maxWidth: "1920px",
    // maxWidth: "max-content",
    // minWidth: "1620px",
    // height: "1080px"
    // height: "100vh",
    // minHeight: (props) => (props.selectedAllScreen ? "fit-content" : "100vh"),
  },
  mobileView: {
    maxWidth: "375px",
    minWidth: "375px",
    minHeight: "812px",
  },
  componentView: {
    // maxWidth: "fit-content",
    maxWidth: "100vw",
    minWidth: "760px",
    minHeight: "812px",
  },
  screenHeader: {
    textAlign: "center",
    position: "relative",
    cursor: "pointer",
    userSelect: "none",
    width: "auto",
    //background: "#cbd5e1",
    display: "flex",
    // borderTopLeftRadius: "10px",
    // borderTopRightRadius: "10px",
  },
  titleDetails: {
    minWidth: "226px",
    background: "#424242",
    color: "#fff",
    padding: "8px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    boxShadow: "2px -3px 6px 0px rgb(0 0 0 / 4%)",
  },
  screenSetting: {
    padding: "4px 9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "#000000b3",
    cursor: "pointer",
    borderRadius: "50px",
    "&:hover": {
      background: "rgb(0 0 0 / 8%)",
      color: "#007aff !important",
    },
  },
  screenDelete: {
    padding: "4px 9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "#000000b3",
    cursor: "pointer",
    borderRadius: "50px",
    "&:hover": {
      background: "#f5005714",
      color: "red !important",
    },
  },
  projectTemplateTitle: {
    paddingLeft: "16px",
    fontSize: "15px",
    textTransform: "capitalize",
    marginTop: "10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: "6px",
  },
  dragArea: {
    width: "76px",
    border: "1px dashed " + theme.palette.text.hint,
    display: "grid",
    textAlign: "center",
    alignItems: "center",
    wordBreak: "break-all",
    padding: "5px 10px",
    margin: "5px auto",
    minHeight: "108px",
    borderRadius: "4px",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  collapseView: {
    color: "gray",
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      flexWrap: "wrap",
      gap: "5px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialog: {
    minWidth: 400,
    padding: "20px",
  },
  templateName: {
    marginBottom: "30px",
  },
  templateNameInput: {
    fontSize: 14,
    padding: "12px 0px",
  },
}));
const initialState = {
  mouseX: null,
  mouseY: null,
};
function ComponentsRenderer({
  component,
  index,
  returnComponentId,
  screenName,
  draggable,
  pathId,
  screenId,
  screenIndex,
  previewScreen,
  layoutType,
  Parentindex,
  handleContextMenu,
  setSelectedComponent,
}) {
  const styleDeclaration = (compName, style, customStyle) => {
    let Compstyle = customStyle[compName];
    return { ...Compstyle, ...style };
  };

  const getComponentId = (event, index, component, indexs) => {
    returnComponentId(
      index,
      component,
      screenId,
      event,
      pathId,
      screenIndex,
      screenName,
      indexs
    );
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    // alert(component.component)
  };

  const onDragStart = (evt) => {
    // evt.preventDefault();
    let id = evt.target.getAttribute("data-id");
    let element = evt.target;
    element.classList.add("vivifySmall");
    element.classList.add("pulsate");
    // element.classList.add("dragged");
    evt.dataTransfer.setData("moveToComponents", id);
    localStorage.setItem("drag_item_id", id);
    evt.dataTransfer.effectAllowed = "move";
    // setTimeout(() => {
    //   document.getElementById(id).className += " hide";
    // }, 0);
    // evt.stopPropagation();
    // evt.nativeEvent.stopImmediatePropagation();
  };
  let comp,
    Layer,
    propertiesList,
    attributesList,
    nestedComments,
    htmlChildren = "";

  if (
    component.frameWork?.startsWith("material") ||
    component.frameWork === "qdm" ||
    component.frameWork === "looper" ||
    component.frameWork === "functional-lib" ||
    component.frameWork === "atp-master-layout" ||
    component.frameWork === "atp-login" ||
    component.frameWork === "atp-table" ||
    component.frameWork === "atp-general-master" ||
    component.frameWork === "atp-authentication" ||
    component.frameWork === "atp-authorization" ||
    component.frameWork === "report_viewer_atp" ||
    component.frameWork === "atp-stepper" ||
    component.frameWork === "custom-material-ui-component" ||
    component.frameWork === "functional-components-layout"
  ) {
    comp = component?.component === "Modal" ? "Row" : component?.component;
    if (component.frameWork === "functional-lib") {
      // Layer = AllPackages[comp];
      return false;
    } else if (component.frameWork?.startsWith("material")) {
      if (component.frameWork === "material/core") {
        Layer = AllMaterialCore[comp] ?? AllMaterialCore["Button"];
      } else if (component.frameWork === "material/lab") {
        Layer = AllMaterialLab[comp] ?? AllMaterialLab["Autocomplete"];
      }
    } else if (component.frameWork.startsWith("functional-components-layout")) {
      debugger
      Layer = CustomText[comp] ?? <text>not render pls check</text>;
    }
    // else if (component.frameWork === "custom-material-ui-component") {
    //   Layer = AllMaiCus[comp] ?? <text>not render pls check</text>;
    // }
    // else if (component.frameWork === "atp-master-layout") {
    //   Layer = AtpMasterLayout[comp] ?? <text>not render pls check</text>;
    // } else if (component.frameWork === "atp-login") {
    //   Layer = AtpLogin[comp] ?? <text>not render pls check</text>;
    // } 
    else if (component.frameWork === "atp-table") {
       Layer = AtpTable[comp] ?? <text>not render pls check</text>;}
    // } else if (component.frameWork === "atp-general-master") {
    //   Layer = AtpGeneralMaster[comp] ?? <text>not render pls check</text>;
    // } else if (component.frameWork === "atp-authentication") {
    //   Layer = AtpAuthentication[comp] ?? <text>not render pls check</text>;
    // } else if (component.frameWork === "atp-authorization") {
    //   Layer = AtpAuthorization[comp] ?? <text>not render pls check</text>;
    // } else if (component.frameWork === "report_viewer_atp") {
    //   Layer = AtpReportViewer[comp] ?? <text>not render pls check</text>;
    // } 
    else if (component.frameWork === "atp-stepper") {
      Layer = AtpStepper[comp] ?? <text>not render pls check</text>;
    }
    // } else {
    //   Layer = All[comp];
    // }
    if (component?.child && component?.child.length > 0) {
      nestedComments = (component.child || []).map((component, index) => {
        return (
          <ComponentsRenderer
            Parentindex={`${Parentindex}_${index}`}
            layoutType={layoutType}
            key={component?.id}
            index={index}
            component={component}
            type="child"
            returnComponentId={returnComponentId}
            screenName={screenName}
            draggable={draggable}
            pathId={pathId}
            screenId={screenId}
            screenIndex={screenIndex}
            previewScreen={previewScreen}
            handleContextMenu={handleContextMenu}
            setSelectedComponent={setSelectedComponent}
          />
        );
      });
    } else if (typeof component?.properties?.children) {
      nestedComments = component?.properties?.children;
    }
    if (comp === "Col" && layoutType === 2) {
      if (component?.properties) {
        // component.properties.lg = component.properties.xs
        propertiesList = {
          ...component.properties,
        };
        propertiesList.lg = propertiesList.xs;
      }
    } else {
      propertiesList = JSON.parse(JSON.stringify(component?.properties));
    }
  }

  function ComponentWraper({ children }) {
    return (
      <div
        draggable={draggable}
        id={component.id}
        data-id={component.id}
        data-name={component.name}
        onClick={(e) => {
          setSelectedComponent(component);
          getComponentId(e, index, component, Parentindex);
        }}
        onDragStart={(e) => onDragStart(e)}
        onMouseDown={(e) => {
          if (e.button === 2) {
            handleContextMenu(e, component);
          }
        }}
        className={`${
          component?.attributes?.class ? component.attributes.class : ""
        }${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
        style={component.inlineStyles}
      >
        {children}
      </div>
    );
  }

  if (component.component === "Autocomplete") {
    return (
      <ComponentWraper>
        <Autocomplete
          options={[]}
          key={index}
          id={component.id}
          className={`${
            component?.attributes?.class ? component.attributes.class : ""
          }`}
          disabled={component?.properties?.disabled ?? true}
          defaultValue={[]}
          data-id={component.id}
          data-name={component.name}
          style={component.inlineStyles}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionLabel={(option) => option?.title ?? option.label ?? option}
          renderInput={(params) => (
            <TextField
              onClick={(e) => {
                setSelectedComponent(component);
                getComponentId(e, index, component, Parentindex);
              }}
              {...params}
              label={
                component?.properties?.value
                  ? component?.properties?.value
                  : component?.properties?.label
              }
              variant="outlined"
              // {...component?.properties}
              style={component.inlineStyles}
            />
          )}
          {...propertiesList}
        />
      </ComponentWraper>
    );
  }

  if (component.component === "Hidden") {
    return (
      <ComponentWraper>
        <Hidden
          draggable={draggable}
          onDragStart={(e) => onDragStart(e)}
          onClick={(e) => {
            setSelectedComponent(component);
            getComponentId(e, index, component, Parentindex);
          }}
          onMouseDown={(e) => {
            if (e.button === 2) {
              handleContextMenu(e, component);
            }
          }}
          {...propertiesList}
          key={index}
          className={`${
            component?.attributes?.class ? component.attributes.class : ""
          } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
          data-id={component.id}
          data-name={component.name}
          inLineStyles={component.inlineStyles}
          id={component.id}
        >
          {nestedComments}
        </Hidden>
      </ComponentWraper>
    );
  }

  if (component.component === "Login") {
    return (
      <ComponentWraper>
        <Layer
          {...propertiesList}
          style={styleDeclaration(
            component.name,
            component.inlineStyles,
            customStyle
          )}
          id={component.id}
        />
      </ComponentWraper>
    );
  }

  if (component.frameWork === "html" || component.frameWork === "dynamic") {
    propertiesList = component.properties ?? {};
    attributesList = component.attributes ?? {};
    Layer = component.component;

    if (component?.child && component?.child.length > 0) {
      htmlChildren = (component.child || []).map((component, index) => {
        return (
          <ComponentsRenderer
            Parentindex={`${Parentindex}_${index}`}
            layoutType={layoutType}
            key={component?.id}
            index={index}
            component={component}
            type="child"
            returnComponentId={returnComponentId}
            screenName={screenName}
            draggable={draggable}
            pathId={pathId}
            screenId={screenId}
            screenIndex={screenIndex}
            previewScreen={previewScreen}
            handleContextMenu={handleContextMenu}
            setSelectedComponent={setSelectedComponent}
          />
        );
      });
    } else if (
      component?.properties?.name ||
      typeof component?.properties?.children === "string"
    ) {
      htmlChildren =
        component.properties?.name ?? component?.properties?.children ?? "";
    } else {
      htmlChildren = "";
    }
  }
  if (component.component === "Typography") {
    //! REPLACING THE CONTENT FIELDS IN MESSAGE CATALOGUE TO CHILDREN FOR TEXT FIELD TO BE RENDERED PROPERLY
    propertiesList = { ...propertiesList, children: propertiesList?.content };
  } else if (component.component === "CustomText") {
    propertiesList = { ...propertiesList, topLabel: propertiesList?.topLabel };
  }
  return (
    <>
      {component.frameWork === "qdm" || component.frameWork === "looper" ? (
        //rendering qdm components
        layoutComponentsList(comp) ? (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.name}
            inLineStyles={component.inlineStyles}
            id={component.id}
          >
            {nestedComments}
          </Layer>
        ) : (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...component?.properties}
            key={index}
            id={component.id}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }`}
            data-id={component.id}
            data-name={component.name}
            inLineStyles={component.inlineStyles}
          />
        )
      ) : component.frameWork.startsWith("material") ||
        component.frameWork === "custom-material-ui-component" ||
        component.frameWork === "atp-master-layout" ||
        component.frameWork === "atp-login" ||
        component.frameWork === "atp-table" ||
        component.frameWork === "atp-general-master" ||
        component.frameWork === "atp-authentication" ||
        component.frameWork === "atp-authorization" ||
        component.frameWork === "report_viewer_atp" ||
        component.frameWork === "atp-stepper" ||
        component.frameWork === "functional-components-layout" ||
        component.frameWork === "functional-lib" ? (
        //rendering qdm components
        component?.isLayout ? (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.name}
            style={styleDeclaration(
              component.name,
              component.inlineStyles,
              customStyle
            )}
            id={component.id}
          >
            {nestedComments}
          </Layer>
        ) : component.frameWork === "functional-lib" ? (
          <div
            draggable={draggable}
            id={component.id}
            data-id={component.id}
            data-name={component.name}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onDragStart={(e) => onDragStart(e)}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            style={component.inlineStyles}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
          >
            <Layer {...propertiesList}>{nestedComments}</Layer>
          </div>
        ) : (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            key={index}
            id={component.id}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }`}
            data-id={component.id}
            data-name={component.name}
            style={component.inlineStyles}
          />
        )
      ) : component.frameWork === "html" ? (
        (component?.child && component?.child.length > 0) ||
        (component?.properties?.name ?? component?.properties?.children) ? (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            {...component.attributes}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.component}
            style={component.inlineStyles}
            id={component.id}
          >
            {htmlChildren}
          </Layer>
        ) : (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            {...component.attributes}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.component}
            style={component.inlineStyles}
            id={component.id}
          />
        )
      ) : (
        <Typography
          style={{ fontSize: "20px", margin: "20px", textAlign: "center" }}
          id={component.id}
          data-id={component.id}
          data-name={component.name}
          onClick={(e) => {
            setSelectedComponent(component);
            getComponentId(e, index, component, Parentindex);
          }}
          onMouseDown={(e) => {
            if (e.button === 2) {
              handleContextMenu(e, component);
            }
          }}
          className={`${
            component?.attributes?.class ? component.attributes.class : ""
          } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
        >
          Component type is not supported
        </Typography>
      )}
    </>
  );
}
export function ScreenLayout(props) {
  const auth = React.useContext(AuthContext);
  const dialog = React.useContext(DialogContext);
  const { projectTemplates } = auth.user;
  const classes = useStyles();
  const [duplicate, setDuplicate] = React.useState(false);
  const [state, setState] = React.useState(initialState);
  const [isTemplate, setIsTemplate] = React.useState(false);
  const [templateChanged, setTemplateChanged] = React.useState(false);
  const [selectedComponent, setSelectedComponent] = React.useState();
  const [clonedComponent, setClonedComponent] = React.useState();
  const [paste, setPaste] = React.useState(false);

  const handleDuplicate = () => {
    setDuplicate(true);
  };
  React.useEffect(() => {
    if (duplicate) {
      const clonedComponentResult = cloneComponent(selectedComponent);
      // if (clonedComponentResult?.templateName) {
      //   delete clonedComponentResult.templateName;
      // }
      const selectedScreenId = auth.user.selectedScreen;
      if (clonedComponentResult) {
        //this will place the duplicate component adjacently
        auth.user.AllScreenList.forEach((screen) => {
          if (screen.id === selectedScreenId) {
            screen.render.child.forEach(function iter(component, index, array) {
              if (component.id === selectedComponent.id) {
                array.splice(index + 1, 0, clonedComponentResult);
              } else {
                if (component?.child?.length > 0) {
                  component.child.forEach(iter);
                }
              }
            });
          }
        });
      }
      auth.setAuth({
        ...auth.user,
        screenEdited: auth.user.selectedScreen,
      });
      setDuplicate(false);
      handleClose();
    }
  }, [duplicate]);

  /**
   * getLayoutClassName Function definition
   */
  const getLayoutClassName = (type) => {
    switch (type) {
      case 1:
        return classes.webView;
      case 2:
        return classes.mobileView;
      case 3:
        return classes.tabView;
      default:
        return classes.webView;
    }
  };
  const handleCopy = (e) => {
    const clonedComponentResult = cloneComponent(selectedComponent);
    setClonedComponent(clonedComponentResult);
    handleClose();
  };
  const handlePaste = () => {
    setPaste(true);
  };
  React.useEffect(() => {
    if (clonedComponent?.id && paste) {
      const htmlBlockTags = [
        "div",
        "ol",
        "a",
        "li",
        "aside",
        "footer",
        "section",
        "article",
      ];
      //checkling the selected component to paste is layout or not
      const isLayoutComponent = layoutComponentsList(selectedComponent.name);
      //only paste inside a layout compoenent not normal component
      if (
        isLayoutComponent ||
        htmlBlockTags.includes(selectedComponent.component) ||
        selectedComponent?.isLayout
      ) {
        const selectedScreenId = auth.user.selectedScreen;
        auth.user.AllScreenList.forEach((screen) => {
          if (screen.id === selectedScreenId) {
            screen.render.child.forEach(function iter(component, index, array) {
              if (component.id === selectedComponent.id) {
                component?.child
                  ? component.child.push(clonedComponent)
                  : (component["child"] = [clonedComponent]);
              } else {
                if (component?.child?.length > 0) {
                  component.child.forEach(iter);
                }
              }
            });
          }
        });
        //replacing id's of the component for the consecutive pasting
        const newClonedComponent2 = cloneComponent(clonedComponent);
        setClonedComponent({ ...newClonedComponent2 });
        setPaste(false);
        auth.setAuth({
          ...auth.user,
          screenEdited: auth.user.selectedScreen,
        });
        handleClose();
      } else {
        setPaste(false);
        handleClose();
      }
    }
    if (!clonedComponent && paste) {
      setPaste(false);
    }
  }, [clonedComponent, paste]);
  React.useEffect(() => {
    if (clonedComponent) {
      let data = JSON.stringify(clonedComponent);
      localStorage.setItem("clonedComponent", data);
    }
  }, [clonedComponent]);
  const handleClose = () => {
    setState(initialState);
  };
  const handleContextMenu = (event, component) => {
    event.preventDefault();

    //show copy/paste menu only when a component selected
    if (selectedComponent && selectedComponent.id === component?.id) {
      //checking the selected component a template for modifying the copy/paste menu
      if (selectedComponent?.templateName && auth.user?.projectTemplate) {
        setIsTemplate(true);
        //checking for template changes
        const saveChanges = auth.user?.projectTemplates.filter(
          (templateGroup) => {
            return templateGroup.templates.forEach((template) => {
              if (template.name === selectedComponent.templateName) {
                const selectedComponentIdRemoved =
                  componentDeepIdRemover(selectedComponent);
                const templateComponentIdRemoved = componentDeepIdRemover(
                  template.json
                );
                const changedOrNot = isEqual(
                  selectedComponentIdRemoved,
                  templateComponentIdRemoved
                );
                return changedOrNot;
              }
            });
          }
        );
        if (saveChanges?.length === 0) {
          setTemplateChanged(true);
        } else {
          setTemplateChanged(false);
        }
        //checking if the template name is present or deleted
        const isTemplateGroupDeleted = projectTemplates.filter(
          (templateGroup) => {
            return templateGroup.templates.some((template) => {
              return template.name === selectedComponent.templateName;
            });
          }
        );
        if (isTemplateGroupDeleted?.length === 0) {
          setIsTemplate(false);
        }
      } else {
        setIsTemplate(false);
      }
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
  };

  const handleDeleteComponent = (id, type) => {
    const handleOnOkModal = () => {
      //this function delete the component & the related actions & events
      deleteComponent(id, auth, type);
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
      });
      idm_delete(id);
      props.onClose("right", false);
      handleClose();
    };
    const handClose = () => {
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => {},
        onCancel: () => {},
      });
      handleClose();
    };
    dialog.setDialog({
      ...dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete is layer?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };
  const handleMakeTemplate = () => {
    //for getting the unique id for the component
    const clonedComponentResult = cloneComponent(selectedComponent);
    // setting the options to open create template dialog
    const templateDialog = {
      open: true,
      name: "",
      type: "Create",
      componentId: selectedComponent.id,
      template: clonedComponentResult,
    };
    auth.setAuth({
      ...auth.user,
      templateDialog,
    });
    handleClose();
  };

  const handleTemplateSave = () => {
    //saves latest template changes
    auth.user.projectTemplates.forEach((templateGroup) => {
      templateGroup.templates.some((template) => {
        if (template.name === selectedComponent.templateName) {
          template.json = selectedComponent;
        }
      });
    });
    handleClose();
  };

  const handleMenuShortcuts = (e) => {
    if (props.uiType === "uiFlow") {
      return;
    }
    let key = e.keyCode;
    if (key === 86 && (e.ctrlKey || e.metaKey)) {
      handlePaste();
    } else if (key === 67 && (e.ctrlKey || e.metaKey)) {
      handleCopy();
    } else if (key === 68 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      handleDuplicate();
    } else if (key === 77 && (e.ctrlKey || e.metaKey)) {
      //checking if the template name is present
      if (selectedComponent?.templateName) {
        return;
      } else {
        handleMakeTemplate();
      }
    } else if (key === 46) {
      handleDeleteComponent(selectedComponent?.id, props.typeSection);
    }
  };

  React.useEffect(() => {
    if (selectedComponent) {
      const keyboardAccess = document.querySelector("#keyboardAccess");
      keyboardAccess.addEventListener("keydown", handleMenuShortcuts);
      return () => {
        keyboardAccess.removeEventListener("keydown", handleMenuShortcuts);
      };
    }
  }, [selectedComponent]);

  const MakeTemplateModal = ({ classes, auth, dialog, projectTemplates }) => {
    const alert = React.useContext(AlertContext);
    const templateDialog = auth.user.templateDialog;
    const [open, setOpen] = useState(templateDialog?.open ?? false);
    const [templateName, setTemplateName] = useState("");
    const [templateGroup, setTemplateGroup] = useState(null);
    const [validation, setValidation] = useState({
      helperText: "",
      error: false,
    });
    const [update, setUpdate] = useState(false);

    const filter = createFilterOptions();
    const templateGroupNames = projectTemplates ?? [];
    const selectedTemplateRef = useRef(null);
    const projectTemplateRef = useRef(projectTemplates);

    //checking if the template name already exists or not
    const duplicateNameCheck = (val) => {
      if (projectTemplates) {
        const result = projectTemplates.filter((templateGroup) => {
          return templateGroup.templates.some((template) => {
            return template.name.toLowerCase() === val.toLowerCase();
          });
        });
        return result;
      }
    };

    //handling dialog close
    const handleClose = () => {
      const updatedTemplateDialog = {
        open: false,
        name: "",
        type: "",
        template: {},
      };
      setTemplateName("");
      setTemplateGroup(null);
      setValidation({
        helperText: "",
        error: false,
      });
      selectedTemplateRef.current = null;
      setOpen(false);
      auth.setAuth({
        ...auth.user,
        templateDialog: updatedTemplateDialog,
        projectTemplates: projectTemplateRef.current,
      });
    };

    //template name handling
    const handleTemplateName = (e) => {
      let input = e.target.value;
      setTemplateName(input);
      if (templateDialog.type === "Edit") {
        //only throw error when template name found other than the current template name
        const result = duplicateNameCheck(input);
        if (
          result?.length > 0 &&
          input.toLowerCase().trim() !== templateDialog.name.toLowerCase()
        ) {
          setValidation({
            helperText: "A template already exists with this name",
            error: true,
          });
        } else {
          if (
            selectedTemplateRef.current.name.toLowerCase() !==
            input.toLowerCase().trim()
          ) {
            setUpdate(true);
          } else {
            setUpdate(false);
          }
          setValidation({
            helperText: "",
            error: false,
          });
        }
      } else {
        const result = duplicateNameCheck(input);
        if (result?.length > 0) {
          setValidation({
            helperText: "A template already exists with this name",
            error: true,
          });
        } else {
          setValidation({
            helperText: "",
            error: false,
          });
        }
      }
    };

    const handleTemplateCreate = () => {
      const templateGroupsCopy = cloneDeep(projectTemplates);

      // adding template name for the json component-useful for handling copy/paste menu
      const templatejson = {
        ...templateDialog.template,
        templateName,
      };
      const newTemplate = {
        name: templateName,
        type: "component",
        json: templatejson,
      };
      //if template group already exists
      if (templateGroup?.id) {
        templateGroupsCopy.forEach((tg) => {
          if (tg.id === templateGroup.id) {
            tg.templates.push(newTemplate);
          }
        });
      }
      //if template group newly created
      else {
        const newTemplateGroup = {
          name: templateGroup.name,
          id: makeid(),
          templates: [newTemplate],
        };
        templateGroupsCopy.push(newTemplateGroup);
      }
      projectTemplateRef.current = templateGroupsCopy;
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Template created successfully",
        vertical: AlertProps.vertical.bottom,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      //setting template name for currently selected component in screen layout
      const selectedScreenId = auth.user.selectedScreen;
      auth.user.AllScreenList.forEach((screen) => {
        if (screen.id === selectedScreenId) {
          screen.render.child.forEach(function iter(component, index, array) {
            if (component.id === templateDialog.componentId) {
              component.templateName = templateName;
            } else {
              if (component?.child?.length > 0) {
                component.child.forEach(iter);
              }
            }
          });
        }
      });
      handleClose();
    };

    const handleTemplateGroup = (event, newValue) => {
      if (newValue === null) return;
      if (newValue && newValue.inputValue) {
        // Create a new value from the user input
        setTemplateGroup({
          name: newValue.inputValue,
        });
        auth.user?.templateDialog?.type === "Edit" && setUpdate(true);
      } else {
        setTemplateGroup(newValue);
        auth.user?.templateDialog?.type === "Edit" && setUpdate(true);
      }
      if (
        auth.user?.templateDialog?.type === "Edit" &&
        newValue.id === selectedTemplateRef.current.groupId
      ) {
        setUpdate(false);
      }
    };

    //updating template
    const handleTemplateUpdate = () => {
      const templateGroupsCopy = cloneDeep(projectTemplates);
      //template name changed
      if (templateName !== selectedTemplateRef?.current?.name) {
        templateGroupsCopy.forEach((tg, index, array) => {
          if (tg.id === selectedTemplateRef?.current?.groupId) {
            tg.templates.length > 0 &&
              tg.templates.forEach((template, index, array) => {
                if (template.name === selectedTemplateRef?.current?.name) {
                  template.name = templateName;
                  template.json.templateName = templateName;
                }
              });
          }
        });
        //update all the template name in screenlist
        const selectedScreenId = auth.user.selectedScreen;
        auth.user.AllScreenList.forEach((screen) => {
          if (screen.id === selectedScreenId) {
            screen.render.child.forEach(function iter(component, index, array) {
              if (
                component?.templateName === selectedTemplateRef?.current?.name
              ) {
                component.templateName = templateName;
              } else {
                if (component?.child?.length > 0) {
                  component.child.forEach(iter);
                }
              }
            });
          }
        });
      }

      let templateToChange;
      //if template group changed
      if (
        !templateGroup?.id ||
        templateGroup.id !== selectedTemplateRef?.current?.groupId
      ) {
        //deleting the template from the current template group
        templateGroupsCopy.forEach((tg, index, array) => {
          if (tg.id === selectedTemplateRef?.current?.groupId) {
            tg.templates.length > 0 &&
              tg.templates.forEach((template, index, array) => {
                if (template.name === templateName) {
                  templateToChange = array[index];
                  array.splice(index, 1);
                }
              });
          }
        });
        // already created group
        //to add the template to the newly shifted template group-if a template group already created
        if (templateGroup?.id) {
          templateGroupsCopy.forEach((tg, index, array) => {
            if (tg.id === templateGroup.id) {
              tg.templates.push(templateToChange);
            }
          });
        }
        // new template group
        //to add the template to the newly shifted template group-if a template group not already created
        else {
          const newTemplateGroup = {
            name: templateGroup.name,
            id: makeid(),
            templates: [templateToChange],
          };
          templateGroupsCopy.push(newTemplateGroup);
        }
      }

      projectTemplateRef.current = templateGroupsCopy;
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Template updated successfully",
        vertical: AlertProps.vertical.bottom,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
      auth.setAuth({
        ...auth.user,
        projectTemplates: projectTemplateRef.current,
      });
      setUpdate(false);
      handleClose();
    };

    //detecting the template dialog changes to open the make/edit template dialog
    useEffect(() => {
      //if it is Edit dialog
      if (
        auth.user?.templateDialog?.name !== "" &&
        auth.user?.templateDialog?.type === "Edit"
      ) {
        const templateName = auth.user.templateDialog?.name;
        setTemplateName(templateName);
        const result = projectTemplates?.filter((templateGroup) => {
          return templateGroup.templates.some((template) => {
            return template.name === templateName;
          });
        });
        //setting the template value for editing
        setTemplateGroup(result[0]);
        selectedTemplateRef.current = {
          name: templateName,
          groupId: result[0]?.id,
        };
      }
      //opens the make/edit dialog
      if (auth.user?.templateDialog?.open) {
        setOpen(auth.user.templateDialog.open);
      }
    }, [auth.user.templateDialog]);

    useEffect(() => {
      projectTemplateRef.current = projectTemplates;
    }, [projectTemplates]);

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="make-template-dialog"
        open={open}
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogTitle
          id="make-template-dialog"
          style={{ padding: "8px 0px 32px" }}
        >
          {templateDialog?.type} Template
        </DialogTitle>
        <TextField
          value={templateName}
          label="Template Name"
          id="outlined-start-adornment"
          className={classes.templateName}
          onChange={handleTemplateName}
          helperText={validation.helperText}
          error={validation.error}
          size="small"
          variant="outlined"
          autoFocus
        />
        <Autocomplete
          value={templateGroup}
          onChange={(event, newValue) => {
            handleTemplateGroup(event, newValue);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            // Suggest the creation of a new value
            if (
              params.inputValue !== "" &&
              !options.some(
                (option) =>
                  option.name.toLowerCase() === params.inputValue.toLowerCase()
              )
            ) {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={templateGroupNames}
          getOptionLabel={(option) => {
            return option?.name;
          }}
          renderOption={(option) => option?.name}
          freeSolo
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Template Group"
                size="small"
                className={classes.templateName}
                variant="outlined"
              />
            );
          }}
        />
        <DialogActions className={classes.dialogActions}>
          <Button size="small" onClick={handleClose}>
            Close
          </Button>
          {templateName &&
            templateGroup &&
            templateDialog?.type === "Create" &&
            !validation.error && (
              <Button
                size="small"
                type="submit"
                onClick={handleTemplateCreate}
                variant="contained"
                style={{ marginLeft: "10px" }}
                color="primary"
                disableElevation
              >
                Create
              </Button>
            )}
          {templateName &&
            templateGroup &&
            update &&
            templateDialog?.type === "Edit" && (
              <Button
                size="small"
                type="submit"
                onClick={handleTemplateUpdate}
                variant="contained"
                color="primary"
                style={{ marginLeft: "10px" }}
                disableElevation
              >
                Update
              </Button>
            )}
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div key={props.index}>
      {props.screen?.type !== "decisionHandler" && (
        <div
          className={classes.screenHeader}
          id={"mainScreenHeader"}
          style={{
            //width: "auto",
            transition: "background-color 0.5s linear",
            //boxShadow: "0px 0px 16px 0px #0000001a",
            // border: "1px solid red",
            // paddingLeft: "5px",
            // paddingBottom: "15px",
          }}
        >
          {/* <div className={classes.titleDetails}> */}
          <Typography
            style={{
              fontWeight: "500",
              fontSize: "19px",
              textTransform: "capitalize",
              color: "#929090",
            }}
          >
            {props?.screen?.name}
          </Typography>
          {/* </div> */}
          {/* setting */}
          {props.uiType === "uiFlow" && (
            <div
              className={`${classes.screenSetting} fontIcon`}
              onClick={(e) =>
                props.getComponentId(e, "screensParent", props?.screen?.id)
              }
            >
              <SettingsApplicationsIcon />
            </div>
          )}
        </div>
      )}
      <div
        style={{
          height: "100%",
          cursor: "context-menu",
          outline: "1px solid grey",
        }}
        data-screen={props.screen?.name}
        id={props.screen?.pathId}
        onDragEnter={(e) => props.onDragEnter(e, props?.screen?.id)}
        onDragLeave={(e) => props.onDragLeave(e)}
        onDragEnd={(e) => props.onDragEnd(e)}
        onDragOver={(e) => props.onDragOver(e)}
        onDrop={(e) => props.onDrop(e, props?.screen?.id, props.typeSection)}
        
      >

        
        <div id="keyboardAccess" onContextMenu={handleContextMenu} tabIndex="0">
          <Grid container item>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <>
                {props?.screen?.render?.child?.length > 0 ? (
                  props?.screen?.render?.child?.map(
                    (component, Parentindex) => {
                      console.log("props?.screen?.render?.child",props?.screen?.render?.child)
                      return (
                        <div
                          style={{
                            boxSizing: "border-box",
                            padding: "0.2em",
                            width: "100% !important",
                          }}
                          id={Parentindex.toString()}
                          key={Parentindex.toString()}
                        >
                          <ComponentsRenderer
                            layoutType={props.layoutType} // mobile or web
                            key={component?.id} // id that is given for each and every component
                            component={component}
                            index={Parentindex}
                            Parentindex={Parentindex}
                            returnComponentId={props.getComponentId}
                            screenId={props?.screen.id} //id of the screen
                            screenName={props?.screen?.name} // name of the screen
                            screenIndex={props?.index} // screen index value
                            pathId={props?.screen.pathId}
                            draggable={props.draggable}
                            previewScreen={props?.previewScreen}
                            handleContextMenu={handleContextMenu}
                            setSelectedComponent={setSelectedComponent}
                          />
                        </div>
                      );
                    }
                  )
                ) : (
                  <div style={{ textAlign: "center", marginBottom: "10px" }}>
                    {/* <Typography color={"textSecondary"}>
                      {"Drop Layout Components..."}
                    </Typography> */}
                  </div>
                )}
              </>
            </Grid>
            <Menu
              open={state.mouseY !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                  ? { top: state.mouseY, left: state.mouseX }
                  : undefined
              }
            >
              <MenuItem onClick={handleDuplicate}>
                <Typography variant="body2">Duplicate</Typography>
              </MenuItem>
              <MenuItem onClick={handleCopy}>
                <Typography variant="body2">Copy</Typography>
              </MenuItem>
              <MenuItem
                disabled={clonedComponent?.id ? false : true}
                onClick={handlePaste}
              >
                <Typography variant="body2">Paste</Typography>
              </MenuItem>
              {!isTemplate && (
                <MenuItem onClick={handleMakeTemplate}>
                  <Typography variant="body2"> Make as Template</Typography>
                </MenuItem>
              )}
              <MenuItem
                onClick={() =>
                  handleDeleteComponent(
                    selectedComponent?.id,
                    props.typeSection
                  )
                }
              >
                <Typography variant="body2">Delete</Typography>
              </MenuItem>
              {isTemplate && (
                <>
                  <Divider light />
                  <div>
                    <Typography
                      style={{
                        padding: "6px 16px 6px 16px",
                        color: "gray",
                        fontSize: "12px",
                      }}
                    >
                      {/* Template: {selectedComponent?.templateName} */}
                    </Typography>
                    {templateChanged && (
                      <MenuItem dense="true" onClick={handleTemplateSave}>
                        <Typography variant="body2">Save Changes</Typography>
                      </MenuItem>
                    )}
                  </div>
                </>
              )}
            </Menu>
            {/*create template dialog */}
            <MakeTemplateModal
              auth={auth}
              projectTemplates={projectTemplates}
              classes={classes}
              dialog={dialog}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
}

ScreenLayout.defaultProps = {
  zoom: 0.7,
  previewScreen: true,
  layoutType: 2,
  draggable: false,
  onDragEnter: () => {},
  onDragLeave: () => {},
  onDragEnd: () => {},
  onDragOver: () => {},
  onDrop: () => {},
  getComponentId: () => {},
  deleteOldScreen: () => {},
  index: 0,
  selectedAllScreen: true,
  rightMenu: () => {},
};
