import { readDocument, readDocuments } from "./crud";
// import { metadata_id } from "./constants"
import { NetworkCall } from "../networkcall";
import config from "../config";
import { getComponents } from "../utils";
export const getAllUIComponents = async () => {
  let data = await getComponents();
  const getLayoutValue = localStorage.getItem("layoutValue");
  let getFunctionalComp = data.result.filter((e) =>
    e.frameWork.startsWith("functional-components-layout")
  );
  getFunctionalComp = getFunctionalComp.reduce(
    (acc, curr) =>
      (acc = [
        ...acc,
        {
          ...curr,
          defaultProps: {
            ...curr.defaultProps,
            ...{
              xl: getLayoutValue ? 12 / Number(getLayoutValue) : 12,
              lg: getLayoutValue ? 12 / Number(getLayoutValue) : 12,
              md: getLayoutValue ? 12 / Number(getLayoutValue) : 12,
              xs: 12,
              sm: getLayoutValue ? 12 / Number(getLayoutValue) : 12,
            },
          },
        },
      ]),
    []
  );
  return (
    [
      ...data.result.filter(
        (e) => !e.frameWork.startsWith("functional-components-layout")
      ),
      ...getFunctionalComp,
    ] ?? []
  );
};

export async function readUIBuilderDoc(metaDataId) {
  let uibuilderQuery = [
    {
      entity: "uibuilder",
      filter: {
        metadataid: metaDataId,
      },
    },
  ];

  let result = await readDocument(uibuilderQuery, {
    sort: "uibuilder.version desc",
  });
  return result?.uibuilder ?? {};
}
//Read projectvstools
export async function readProjectVsTools(metaDataId) {
  let projectVsToolsQuery = {
    db_name: process.env.REACT_APP_DBNAME,
    entity: "projectvstools",
    filter: {
      metadataid: localStorage.getItem("main_metadata_id"),
    },
    return_fields: "projectvstools",
  };

  let result = await NetworkCall(
    process.env.REACT_APP_READ_DOCUMENT,
    "POST",
    projectVsToolsQuery
  );
  sessionStorage.setItem("projectId", result?.data?.result[0]?.projectid);
  sessionStorage.setItem("clientId", result?.data?.result[0]?.clientid);
  return result?.projectvstools ?? {};
}
export const getScreenIndex = async (metaDataId, version) => {
  let screenQuery = [
    {
      entity: "screens",
      filter: {
        metadataid: metaDataId,
        version: version ?? 1.0,
        activestatus: true,
      },
    },
  ];
  const allScreens = await readDocuments(screenQuery, { limit: 100 });
  return allScreens.length + 1;
};

export const build = async () => {
  let dataList = {
    projectName: localStorage.getItem("projectName"),
    metadataId: localStorage.getItem("metaDataId"),
  };

  let data = JSON.stringify(dataList);
  let response = await NetworkCall(
    `${config.compiler_url}/clone`,
    "POST",
    data,
    {
      "Content-Type": "application/json",
    }
  );
  if (response?.data?.Code !== 200) {
    // resolve(
    //   response?.data?.error ??
    //     response?.data?.Errormsg ??
    //     "Something went wrong when creating documents"
    // );
  } else {
    let result = response?.data?.Result ?? response?.data?.result;
    // resolve(result);
  }
};

//Read project_templates
export async function readProjectTemplates(metaDataId, version) {
  let projectTemplatesQuery = [
    {
      entity: "project_templates",
      filter: {
        metadataid: metaDataId,
        version: version,
      },
    },
  ];
  let result = await readDocument(projectTemplatesQuery);
  return result?.project_templates ?? {};
}
