import React , {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, Grid } from "@material-ui/core";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { RequiredField } from "../../common";
import  { modules, formats, QuillToolbar } from "../../common";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles((theme) => ({

    texteditor:{
        width: "100%",
        margin:" 0 auto",
    },
    
  }));
  
const RichTextBox = (props) => {

    const classes = useStyles(props);
    let {
        inputVariant,
        duration = false,
        size,
        margin,
        format,
        disabled,

        required = false,
        topLabel = "Date Of Birth",
        style,
        ...other
    } = props;
const [comments, setComments] = useState("")

    // let modules = {
    //     toolbar: [
    //       [{ 'font': [] }],
    //       [{ 'size': ['small', false, 'large', 'huge'] }],
    //       ['bold', 'italic', 'underline'],
    //       [{'list': 'ordered'}, {'list': 'bullet'}],
    //       [{ 'align': [] }],
    //       [{ 'color': [] }, { 'background': [] }],
    //       ['clean']
    //     ]
    // };

    // let formats = [
    //     'font',
    //     'size',
    //     'bold', 'italic', 'underline',
    //     'list', 'bullet',
    //     'align',
    //     'color', 'background'
    //   ];

      const rteChange = (content, delta, source, editor) => {

setComments(editor.getHTML())
        console.log(editor.getHTML()); // rich text
		console.log(editor.getText()); // plain text
		console.log(editor.getLength()); // number of characters
      }


    return (
        <Grid item sm={duration ? 12 : 6} {...other}>
            <Grid
                item
                container
                style={{ display: "flex", justifyContent: "flex-start" }}
            >
                <Typography>
                    {topLabel} {required && <RequiredField color={"red"} />}
                </Typography>
                <div className={classes.texteditor}>
                <QuillToolbar />
                <ReactQuill 
                theme="snow"  
                modules={modules}
				formats={formats} 
                onChange={rteChange}
			    value={comments || ''}
                />
                </div>
            </Grid>
        </Grid>
    )
}


export default RichTextBox