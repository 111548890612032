import React from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Collapse,
  ListItem,
  ListItemText,
  Paper,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "../propertyspanel/styles.jsx";

import { deleteComponent } from "../../componentList/commonFunction";
import { AuthContext, DialogContext, AlertContext } from "../../../contexts";

// import {
//   languageCodes,
//   validationsRequiredComponents,
// } from "../propertypanel/constants";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { AlertProps } from "../../../utils";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
export function StylesPanel(props) {
  const auth = React.useContext(AuthContext);
  const dialog = React.useContext(DialogContext);
  const alert = React.useContext(AlertContext);
  const classes = useStyles();
  const [data, setData] = React.useState(auth.user.componentAttr);
  const [collapse, setCollapse] = React.useState(null);
  const [componentFromDb, setComponentFromDb] = React.useState();
  const [icondisformar, seticondisformar] = React.useState(1);
  const [icondisforpad, seticondisforpad] = React.useState(1);

  const [fourbox, setFourbox] = React.useState({
    margin: false,
    padding: false,
  });

  const handleCollapse = (index) => {
    var res = index === collapse ? null : index;
    setCollapse(res);
  };

  const handleDeleteComponent = (id) => {
    if (
      auth.user?.screens[0]?.isLockedBy === "" ||
      auth.user?.user?.name === auth.user?.screens[0]?.isLockedBy
    ) {
      const handleOnOkModal = () => {
        deleteComponent(id, auth);
        dialog.setDialog({
          ...dialog,
          open: false,
          tone: false,
        });
        props.onClose("right", false);
      };
      const handClose = () => {
        dialog.setDialog({
          ...dialog,
          open: false,
          tone: false,
          title: "Delete Alert",
          body: "Do you need to delete this screen?",
          positiveBtn: "Yes",
          negativeBtn: "No",
          onOk: () => {},
          onCancel: () => {},
        });
      };
      dialog.setDialog({
        ...dialog,
        open: true,
        tone: true,
        title: "Delete Alert",
        body: "Do you need to delete is layer?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: handleOnOkModal,
        onCancel: handClose,
      });
    } else {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };
  //for px and percentage
  const combineTypeValue = (e, data, id, key) => {
    debugger;
    if (e === `${key}Value` || e === `${key}Type`) {
      if (
        data[id][`${key}Value`] !== null &&
        data[id][`${key}Value`] !== undefined
      ) {
        if (!data[id][`${key}Type`]) {
          data[id][`${key}Type`] = "px";
        }
        if (data[id][`${key}Value`] === "auto") {
          data[id][`${key}`] = "auto";
        } else {
          data[id][`${key}`] = `${data[id][`${key}Value`]}${
            data[id][`${key}Type`]
          }`;
        }
      }
    }
  };

  ///handling property change
  const handleChange = (id, e, v, type, key) => {
    debugger;

    if (
      auth.user?.screens[0]?.isLockedBy === "" ||
      auth.user?.user?.name === auth.user?.screens[0]?.isLockedBy
    ) {
      if (Array.isArray(key)) {
        key.forEach((d) => {
          let data = auth.user.componentAttr;
          data[id][`${d}Type`] = v;
          combineTypeValue(`${d}Type`, data, id, d);
          if (type) {
            data[type] = `${d}Type`;
          }

          if (id === "properties" && e === "type" && data?.validations) {
            // if (
            //   validationsRequiredComponents.some((comp) => comp === data?.component)
            // ) {
            //   data["validations"] = {};
            // }
          }

          let screenId = auth.user.AllScreenList.findIndex(
            (s) => s.id === auth.user.selectedScreen
          );

          let allScreenList = auth.user.AllScreenList;

          let data1 = allScreenList[screenId].render.child;
          console.log("style panel", data1);
          getChildId(data.id, data1, data, type);
          auth.setAuth({
            ...auth.user,
            componentAttr: data,
            isEdited: false,
          });
        });
      } else {
        let data = auth.user.componentAttr;
        data[id][e] = v;
        combineTypeValue(e, data, id, key);
        if (type) {
          data[type] = e;
        }

        if (id === "properties" && e === "type" && data?.validations) {
          // if (
          //   validationsRequiredComponents.some((comp) => comp === data?.component)
          // ) {
          //   data["validations"] = {};
          // }
        }

        let screenId = auth.user.AllScreenList.findIndex(
          (s) => s.id === auth.user.selectedScreen
        );

        let allScreenList = auth.user.AllScreenList;

        let data1 = allScreenList[screenId].render.child;
        console.log("style panel", data1);
        getChildId(data.id, data1, data, type);
        auth.setAuth({
          ...auth.user,
          componentAttr: data,
          isEdited: false,
        });
      }
      localStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
      sessionStorage.setItem(
        "previewScreen",
        JSON.stringify(auth.user.screens)
      );
    } else {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
  };

  const getChildId = (id, data1, data, type, key) => {
    debugger;
    data1.forEach((item) => {
      if (item.id === id) {
        item.properties = {
          ...data.properties,
        };
        item.inlineStyles = {
          ...data.inlineStyles,
        };
        if (type === "languageLabel") {
          item["isTranslate"] = true;
          if (item?.translateKeys) {
            item.translateKeys = [...item.translateKeys, key];
          } else {
            item.translateKeys = [key];
          }
        } else if (type === "isIdmEnabled") {
          item["isIdmEnabled"] = true;
        }
      } else {
        if (item.child) {
          getChildId(id, item?.child, data, type, key);
        }
      }
    });
  };

  function styleReturner(styleProperty) {
    switch (styleProperty) {
      case "display":
        return (
          <>
            <Grid
              item
              xs={12}
              className={
                collapse === 6 ? classes.listHeaderActive : classes.listHeader
              }
            >
              <ListItem button onClick={() => handleCollapse(6)}>
                <ListItemText
                  classes={{ primary: classes.styleList }}
                  primary="Smart Positioning"
                />
                {collapse === 6 ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                )}
              </ListItem>
            </Grid>
            <Collapse in={collapse === 6 ?? false} style={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={12} style={{ padding: "3px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={{ title: data?.inlineStyles?.display ?? "" }}
                    options={[
                      { title: "flex", year: 6 },
                      { title: "inline", year: 2 },
                      { title: "block", year: 3 },
                      { title: "inline-flex", year: 5 },
                      { title: "none", year: 1 },
                    ]}
                    onChange={(e, v) =>
                      handleChange("inlineStyles", "display", v?.title ?? "")
                    }
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option) => option.title}
                    style={{ width: "100%" }}
                    className={classes.inputField}
                    renderInput={(params) => (
                      <TextField
                        className={classes.textfieldcss}
                        margin={"dense"}
                        {...params}
                        label={"Display"}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                {data?.inlineStyles?.display === "flex" ? (
                  <>
                    <Grid item xs={12} style={{ padding: "3px" }}>
                      <Autocomplete
                        id="combo-box-demo"
                        value={{
                          title: data?.inlineStyles?.justifyContent ?? "",
                        }}
                        options={[
                          { title: "flex-start", year: 1 },
                          { title: "center", year: 2 },
                          { title: "flex-end", year: 3 },
                          { title: "space-between", year: 4 },
                          { title: "space-around", year: 5 },
                          { title: "space-evenly", year: 6 },
                          { title: "normal", year: 7 },
                        ]}
                        onChange={(e, v) =>
                          handleChange(
                            "inlineStyles",
                            "justifyContent",
                            v?.title ?? ""
                          )
                        }
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option) => option.title}
                        style={{ width: "100%" }}
                        className={classes.inputField}
                        renderInput={(params) => (
                          <TextField
                            className={classes.textfieldcss}
                            margin={"dense"}
                            {...params}
                            label={"Justify Content"}
                            variant="outlined"
                          />
                        )}
                      ></Autocomplete>
                    </Grid>
                    <Grid item xs={12} style={{ padding: "3px" }}>
                      <Autocomplete
                        id="combo-box-demo"
                        value={{
                          title: data?.inlineStyles?.alignItems ?? "",
                        }}
                        options={[
                          { title: "flex-start", year: 1 },
                          { title: "center", year: 2 },
                          { title: "flex-end", year: 3 },
                          { title: "stretch", year: 4 },
                          { title: "baseline", year: 5 },
                          { title: "normal", year: 6 },
                        ]}
                        onChange={(e, v) =>
                          handleChange(
                            "inlineStyles",
                            "alignItems",
                            v?.title ?? ""
                          )
                        }
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option) => option.title}
                        style={{ width: "100%" }}
                        className={classes.inputField}
                        renderInput={(params) => (
                          <TextField
                            className={classes.textfieldcss}
                            margin={"dense"}
                            {...params}
                            label={"Align Items"}
                            variant="outlined"
                          />
                        )}
                      ></Autocomplete>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Collapse>
          </>
        );
      case "position":
        return (
          <>
            <Grid
              item
              xs={12}
              className={
                collapse === 7 ? classes.listHeaderActive : classes.listHeader
              }
            >
              <ListItem button onClick={() => handleCollapse(7)}>
                <ListItemText
                  classes={{ primary: classes.styleList }}
                  primary="Position style"
                />
                {collapse === 7 ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                )}
              </ListItem>
            </Grid>
            <Collapse in={collapse === 7 ?? false} style={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={12} style={{ padding: "3px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={{
                      title: data?.inlineStyles?.position ?? "",
                    }}
                    options={[
                      { title: "static", year: 6 },
                      { title: "relative", year: 2 },
                      { title: "fixed", year: 3 },
                      { title: "absolute", year: 5 },
                      { title: "sticky", year: 1 },
                    ]}
                    onChange={(e, v) =>
                      handleChange("inlineStyles", "position", v?.title ?? "")
                    }
                    getOptionLabel={(option) => option.title}
                    //getOptionSelected={(option) => option.title}
                    PaperComponent={({ children }) => (
                      <Paper
                        style={{
                          backgroundColor: "white",
                          fontSize: "9px",
                          fontWeight: "350",
                          color: "#727273",
                        }}
                      >
                        <span style={{ padding: "0px" }}>{children}</span>
                      </Paper>
                    )}
                    style={{ width: "100%" }}
                    className={classes.inputField}
                    renderInput={(params) => (
                      <TextField
                        className={classes.textfieldcss}
                        margin={"dense"}
                        {...params}
                        label={"Position"}
                        variant="outlined"
                      />
                    )}
                  ></Autocomplete>
                </Grid>

                {data?.inlineStyles?.position === "absolute" ? (
                  <>
                    <Grid item xs={6} style={{ padding: "3px" }}>
                      <TextField
                        value={data?.inlineStyles?.top ?? ""}
                        onChange={(e) =>
                          handleChange("inlineStyles", "top", e.target.value)
                        }
                        type="text"
                        fullWidth
                        label="top"
                        className={classes.inputField}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={6} style={{ padding: "3px" }}>
                      <TextField
                        value={data?.inlineStyles?.left ?? ""}
                        onChange={(e) =>
                          handleChange("inlineStyles", "left", e.target.value)
                        }
                        type="text"
                        fullWidth
                        label="left"
                        className={classes.inputField}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={6} style={{ padding: "3px" }}>
                      <TextField
                        value={data?.inlineStyles?.bottom ?? ""}
                        onChange={(e) =>
                          handleChange("inlineStyles", "bottom", e.target.value)
                        }
                        type="text"
                        fullWidth
                        label="bottom"
                        className={classes.inputField}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={6} style={{ padding: "3px" }}>
                      <TextField
                        value={data?.inlineStyles?.right ?? ""}
                        onChange={(e) =>
                          handleChange("inlineStyles", "right", e.target.value)
                        }
                        type="text"
                        fullWidth
                        label="right"
                        className={classes.inputField}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                  </>
                ) : null}

                {data?.inlineStyles?.position === "fixed" ? (
                  <>
                    <Grid item xs={6} style={{ padding: "3px" }}>
                      <TextField
                        value={data?.inlineStyles?.top ?? ""}
                        onChange={(e) =>
                          handleChange("inlineStyles", "top", e.target.value)
                        }
                        type="text"
                        fullWidth
                        label="top"
                        className={classes.inputField}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={6} style={{ padding: "3px" }}>
                      <TextField
                        value={data?.inlineStyles?.left ?? ""}
                        onChange={(e) =>
                          handleChange("inlineStyles", "left", e.target.value)
                        }
                        type="text"
                        fullWidth
                        label="left"
                        className={classes.inputField}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={6} style={{ padding: "3px" }}>
                      <TextField
                        value={data?.inlineStyles?.bottom ?? ""}
                        onChange={(e) =>
                          handleChange("inlineStyles", "bottom", e.target.value)
                        }
                        type="text"
                        fullWidth
                        label="bottom"
                        className={classes.inputField}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={6} style={{ padding: "3px" }}>
                      <TextField
                        value={data?.inlineStyles?.right ?? ""}
                        onChange={(e) =>
                          handleChange("inlineStyles", "right", e.target.value)
                        }
                        type="text"
                        fullWidth
                        label="right"
                        className={classes.inputField}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Collapse>
          </>
        );
      case "size":
        return (
          <>
            {" "}
            <Grid
              item
              xs={12}
              className={
                collapse === 1 ? classes.listHeaderActive : classes.listHeader
              }
            >
              <ListItem button onClick={() => handleCollapse(1)}>
                <ListItemText
                  classes={{ primary: classes.styleList }}
                  primary="Size"
                />
                {collapse === 1 ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                )}
              </ListItem>
            </Grid>
            <Collapse in={collapse === 1 ?? false}>
              <Grid
                container
                justifyContent="space-around"
                style={{ padding: "0px 7px 0px 0px" }}
              >
                <Grid item xs={5}>
                  <Grid container alignItems="center">
                    <Grid item xs={8}>
                      <TextField
                        value={data?.inlineStyles?.widthValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "widthValue",
                            e.target.value,
                            undefined,
                            "width"
                          )
                        }
                        type="text"
                        fullWidth
                        label="width"
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{
                        // endAdornment: <InputAdornment position="end">
                        //   <Typography style={{fontSize:"14px", color:"#948B80"}}>
                        //     {data?.inlineStyles?.widthType ?? "px"}
                        //   </Typography>
                        //   </InputAdornment>,
                        // }}
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        value={data?.inlineStyles?.widthType ?? "px"}
                        className={classes.inputsforauto}
                        size="small"
                        fullWidth={false}
                        disableClearable
                        classes={{ endAdornment: classes.endAdornment }}
                        onChange={(event, newValue) =>
                          handleChange(
                            "inlineStyles",
                            "widthType",
                            newValue,
                            undefined,
                            "width"
                          )
                        }
                        id="controllable-states-demo"
                        options={["px", "%"]}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className={classes.inputTypeDropDown}
                            margin="dense"
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <Grid container alignItems="center">
                    <Grid item xs={8}>
                      <TextField
                        value={data?.inlineStyles?.heightValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "heightValue",
                            e.target.value,
                            undefined,
                            "height"
                          )
                        }
                        type="text"
                        fullWidth
                        label="Height"
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        size="small"
                      ></TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        value={data?.inlineStyles?.heightType ?? "px"}
                        size="small"
                        fullWidth={false}
                        disableClearable
                        className={classes.inputsforauto}
                        classes={{ endAdornment: classes.endAdornment }}
                        onChange={(event, newValue) =>
                          handleChange(
                            "inlineStyles",
                            "heightType",
                            newValue,
                            undefined,
                            "height"
                          )
                        }
                        id="controllable-states-demo"
                        options={["px", "%"]}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className={classes.inputTypeDropDown}
                            margin="dense"
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* </Grid> */}
            </Collapse>
          </>
        );
      case "margin":
        return (
          <>
            <Grid
              item
              xs={12}
              className={
                collapse === 2 ? classes.listHeaderActive : classes.listHeader
              }
            >
              <ListItem button onClick={() => handleCollapse(2)}>
                <ListItemText
                  classes={{ primary: classes.styleList }}
                  primary="Margin"
                />
                {collapse === 2 ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                )}
              </ListItem>
            </Grid>
            <Collapse in={collapse === 2 ?? false}>
              <Grid
                container
                style={{ padding: "2px 10px" }}
                alignItems="center"
              >
                <Grid item xs={6}>
                  <TextField
                    value={data?.inlineStyles?.marginValue ?? ""}
                    onChange={(e) =>
                      handleChange(
                        "inlineStyles",
                        "marginValue",
                        e.target.value,
                        undefined,
                        "margin"
                      )
                    }
                    type="text"
                    fullWidth
                    label="margin"
                    className={classes.inputs}
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    value={data?.inlineStyles?.marginType ?? "px"}
                    size="small"
                    fullWidth={false}
                    disableClearable
                    className={classes.inputsforauto}
                    classes={{ endAdornment: classes.endAdornment }}
                    onChange={(event, newValue) =>
                      handleChange(
                        "inlineStyles",
                        "marginType",
                        newValue,
                        undefined,
                        "margin"
                      )
                    }
                    id="controllable-states-demo"
                    options={["px", "%"]}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        className={classes.inputTypeDropDown}
                        margin="dense"
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setFourbox({ ...fourbox, margin: !fourbox.margin })
                    }
                    disabled={
                      (data?.inlineStyles?.marginTopValue ||
                        data?.inlineStyles?.marginBottomValue ||
                        data?.inlineStyles?.marginRightValue ||
                        data?.inlineStyles?.marginLeftValue) &&
                      true
                    }
                  >
                    <FullscreenIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              {(fourbox.margin ||
                data?.inlineStyles?.marginTopValue ||
                data?.inlineStyles?.marginBottomValue ||
                data?.inlineStyles?.marginRightValue ||
                data?.inlineStyles?.marginLeftValue) && (
                <Grid
                  container
                  item
                  style={{ marginLeft: "4px", paddingRight: "10px" }}
                  justifyContent="center"
                >
                  <Grid container item xs={12}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      xs={1}
                      style={{ margin: "1px" }}
                    >
                      {icondisformar === 3 && (
                        <ArrowDropDownIcon fontSize="medium" />
                      )}
                      {icondisformar === 1 && (
                        <ArrowDropUpIcon fontSize="medium" />
                      )}
                      {icondisformar === 4 && (
                        <ArrowLeftIcon fontSize="medium" />
                      )}
                      {icondisformar === 2 && (
                        <ArrowRightIcon fontSize="medium" />
                      )}
                    </Grid>
                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <TextField
                        value={data?.inlineStyles?.marginTopValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "marginTopValue",
                            e.target.value,
                            undefined,
                            "marginTop"
                          )
                        }
                        type="text"
                        fullWidth
                        // label="margin Top"
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                        onClick={() => seticondisformar(1)}
                      ></TextField>
                    </Grid>

                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <TextField
                        value={data?.inlineStyles?.marginRightValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "marginRightValue",
                            e.target.value,
                            undefined,
                            "marginRight"
                          )
                        }
                        type="text"
                        fullWidth
                        // label="margin right"
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                        onClick={() => seticondisformar(2)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <TextField
                        value={data?.inlineStyles?.marginBottomValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "marginBottomValue",
                            e.target.value,
                            undefined,
                            "marginBottom"
                          )
                        }
                        type="text"
                        fullWidth
                        // label="margin bottom"
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                        onClick={() => seticondisformar(3)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <TextField
                        value={data?.inlineStyles?.marginLeftValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "marginLeftValue",
                            e.target.value,
                            undefined,
                            "marginLeft"
                          )
                        }
                        type="text"
                        fullWidth
                        // label="margin left"
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                        onClick={() => seticondisformar(4)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <Autocomplete
                        value={data?.inlineStyles?.marginTopType ?? "px"}
                        className={classes.inputsforauto}
                        size="small"
                        fullWidth={false}
                        disableClearable
                        classes={{ endAdornment: classes.endAdornment }}
                        onChange={(event, newValue) =>
                          handleChange(
                            "inlineStyles",
                            [
                              "marginBottomType",
                              "marginRightType",
                              "marginLeftType",
                              "marginTopType",
                            ],
                            newValue,
                            undefined,
                            [
                              "marginBottom",
                              "marginRight",
                              "marginLeft",
                              "marginTop",
                            ]
                          )
                        }
                        id="controllable-states-demo"
                        options={["px", "%"]}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className={classes.inputTypeDropDown}
                            margin="dense"
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Collapse>
          </>
        );
      case "padding":
        return (
          <>
            <Grid
              item
              xs={12}
              className={
                collapse === 3 ? classes.listHeaderActive : classes.listHeader
              }
            >
              <ListItem button onClick={() => handleCollapse(3)}>
                <ListItemText
                  classes={{ primary: classes.styleList }}
                  primary="Padding"
                />
                {collapse === 3 ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                )}
              </ListItem>
            </Grid>
            <Collapse in={collapse === 3 ?? false}>
              <Grid
                container
                style={{ padding: "2px 10px" }}
                alignItems="center"
              >
                <Grid item xs={6}>
                  <TextField
                    value={data?.inlineStyles?.paddingValue ?? ""}
                    onChange={(e) =>
                      handleChange(
                        "inlineStyles",
                        "paddingValue",
                        e.target.value,
                        undefined,
                        "padding"
                      )
                    }
                    type="text"
                    fullWidth
                    label="padding"
                    className={classes.inputs}
                    margin="dense"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    value={data?.inlineStyles?.paddingType ?? "px"}
                    size="small"
                    fullWidth={false}
                    disableClearable
                    classes={{ endAdornment: classes.endAdornment }}
                    onChange={(event, newValue) =>
                      handleChange(
                        "inlineStyles",
                        "paddingType",
                        newValue,
                        undefined,
                        "padding"
                      )
                    }
                    id="controllable-states-demo"
                    options={["px", "%"]}
                    className={classes.inputsforauto}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        className={classes.inputTypeDropDown}
                        margin="dense"
                        size="small"
                        {...params}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setFourbox({ ...fourbox, padding: !fourbox.padding })
                    }
                    disabled={
                      (data?.inlineStyles?.paddingTopValue ||
                        data?.inlineStyles?.paddingBottomValue ||
                        data?.inlineStyles?.paddingRightValue ||
                        data?.inlineStyles?.paddingLeftValue) &&
                      true
                    }
                  >
                    <FullscreenIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>
              {(fourbox.padding ||
                data?.inlineStyles?.paddingTopValue ||
                data?.inlineStyles?.paddingBottomValue ||
                data?.inlineStyles?.paddingRightValue ||
                data?.inlineStyles?.paddingLeftValue) && (
                <Grid
                  container
                  item
                  style={{ paddingRight: "0px 10px" }}
                  alignItems="center"
                >
                  <Grid container item xs={12} style={{ margin: "1px" }}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      xs={1}
                    >
                      {icondisforpad === 3 && (
                        <ArrowDropDownIcon fontSize="medium" />
                      )}
                      {icondisforpad === 1 && (
                        <ArrowDropUpIcon fontSize="medium" />
                      )}
                      {icondisforpad === 4 && (
                        <ArrowLeftIcon fontSize="medium" />
                      )}
                      {icondisforpad === 2 && (
                        <ArrowRightIcon fontSize="medium" />
                      )}
                    </Grid>
                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <TextField
                        value={data?.inlineStyles?.paddingTopValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "paddingTopValue",
                            e.target.value,
                            undefined,
                            "paddingTop"
                          )
                        }
                        type="text"
                        fullWidth
                        // label="margin Top"
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                        onClick={() => seticondisforpad(1)}
                      ></TextField>
                    </Grid>

                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <TextField
                        value={data?.inlineStyles?.paddingRightValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "paddingRightValue",
                            e.target.value,
                            undefined,
                            "paddingRight"
                          )
                        }
                        type="text"
                        fullWidth
                        // label="margin right"
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                        onClick={() => seticondisforpad(2)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <TextField
                        value={data?.inlineStyles?.paddingBottomValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "paddingBottomValue",
                            e.target.value,
                            undefined,
                            "paddingBottom"
                          )
                        }
                        type="text"
                        fullWidth
                        // label="margin bottom"
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                        onClick={() => seticondisforpad(3)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <TextField
                        value={data?.inlineStyles?.paddingLeftValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "paddingLeftValue",
                            e.target.value,
                            undefined,
                            "paddingLeft"
                          )
                        }
                        type="text"
                        fullWidth
                        // label="margin left"
                        className={classes.inputs}
                        margin="dense"
                        variant="outlined"
                        onClick={() => seticondisforpad(4)}
                      ></TextField>
                    </Grid>
                    <Grid item xs={2} style={{ margin: "1px" }}>
                      <Autocomplete
                        value={data?.inlineStyles?.paddingTopType ?? "px"}
                        size="small"
                        fullWidth={false}
                        disableClearable
                        classes={{ endAdornment: classes.endAdornment }}
                        onChange={(event, newValue) =>
                          handleChange(
                            "inlineStyles",
                            [
                              "paddingBottomType",
                              "paddingRightType",
                              "paddingLeftType",
                              "paddingTopType",
                            ],
                            newValue,
                            undefined,
                            [
                              "paddingBottom",
                              "paddingRight",
                              "paddingLeft",
                              "paddingTop",
                            ]
                          )
                        }
                        id="controllable-states-demo"
                        className={classes.inputsforauto}
                        options={["px", "%"]}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className={classes.inputTypeDropDown}
                            margin="dense"
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Collapse>
          </>
        );
      case "typography":
        return (
          <>
            <Grid
              item
              xs={12}
              className={
                collapse === 4 ? classes.listHeaderActive : classes.listHeader
              }
            >
              <ListItem button onClick={() => handleCollapse(4)}>
                <ListItemText
                  classes={{ primary: classes.styleList }}
                  primary="Typography"
                />
                {collapse === 4 ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                )}
              </ListItem>
            </Grid>
            <Collapse in={collapse === 4 ?? false}>
              <Grid container>
                <Grid item xs={12} style={{ padding: "3px" }}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin={"dense"}
                    className={classes.inputField}
                  >
                    <InputLabel
                      style={{ fontSize: 11 }}
                      htmlFor="outlined-adornment-password"
                    >
                      Color
                    </InputLabel>
                    <OutlinedInput
                      label={"Color"}
                      id="outlined-adornment-password"
                      value={data?.inlineStyles?.color ?? ""}
                      onChange={(e) =>
                        handleChange("inlineStyles", "color", e.target.value)
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <input
                            type="color"
                            value={data?.inlineStyles?.color ?? ""}
                            onChange={(e) =>
                              handleChange(
                                "inlineStyles",
                                "color",
                                e.target.value
                              )
                            }
                          />
                        </InputAdornment>
                      }
                      labelWidth={data?.inlineStyles?.fontSize?.labelWidth ?? 0}
                    />
                  </FormControl>
                </Grid>
                <Grid container justifyContent="space-around">
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          value={data?.inlineStyles?.fontSizeValue ?? ""}
                          onChange={(e) =>
                            handleChange(
                              "inlineStyles",
                              "fontSizeValue",
                              e.target.value,
                              undefined,
                              "fontSize"
                            )
                          }
                          type="text"
                          fullWidth
                          label="font size"
                          className={classes.inputField}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          value={data?.inlineStyles?.fontSizeType ?? "px"}
                          size="small"
                          fullWidth={false}
                          disableClearable
                          classes={{ endAdornment: classes.endAdornment }}
                          onChange={(event, newValue) =>
                            handleChange(
                              "inlineStyles",
                              "fontSizeType",
                              newValue,
                              undefined,
                              "fontSize"
                            )
                          }
                          id="controllable-states-demo"
                          options={["px", "%"]}
                          className={classes.inputsforauto}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              className={classes.inputTypeDropDown}
                              margin="dense"
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justifyContent="space-around">
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <TextField
                          value={data?.inlineStyles?.lineHeightValue ?? ""}
                          onChange={(e) =>
                            handleChange(
                              "inlineStyles",
                              "lineHeightValue",
                              e.target.value,
                              undefined,
                              "lineHeight"
                            )
                          }
                          type="text"
                          fullWidth
                          label="line height"
                          className={classes.inputField}
                          margin="dense"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          value={data?.inlineStyles?.lineHeightType ?? "px"}
                          size="small"
                          fullWidth={false}
                          disableClearable
                          classes={{ endAdornment: classes.endAdornment }}
                          onChange={(event, newValue) =>
                            handleChange(
                              "inlineStyles",
                              "lineHeightType",
                              newValue,
                              undefined,
                              "lineHeight"
                            )
                          }
                          id="controllable-states-demo"
                          options={["px", "%"]}
                          className={classes.inputsforauto}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              className={classes.inputTypeDropDown}
                              margin="dense"
                              size="small"
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} style={{ padding: "3px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={{ title: data?.inlineStyles?.textAlign ?? "" }}
                    options={[
                      { title: "right", year: 0 },
                      { title: "left", year: 1 },
                      { title: "center", year: 2 },
                      { title: "start", year: 3 },
                      { title: "end", year: 3 },
                      { title: "justify", year: 3 },
                    ]}
                    onChange={(e, v) =>
                      handleChange("inlineStyles", "textAlign", v?.title ?? "")
                    }
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option) => option.title}
                    style={{ width: "100%" }}
                    className={classes.inputField}
                    renderInput={(params) => (
                      <TextField
                        margin={"dense"}
                        {...params}
                        label={"Text Align"}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={6} style={{ padding: "3px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={{
                      title: data?.inlineStyles?.fontWeight ?? "",
                    }}
                    options={[
                      { title: "400", year: 0 },
                      { title: "500", year: 1 },
                      { title: "600", year: 2 },
                      { title: "800", year: 3 },
                    ]}
                    onChange={(e, v) =>
                      handleChange("inlineStyles", "fontWeight", v?.title ?? "")
                    }
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option) => option.title}
                    style={{ width: "100%" }}
                    className={classes.inputField}
                    renderInput={(params) => (
                      <TextField
                        margin={"dense"}
                        {...params}
                        label={"Font Weight"}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>

                {/* <Grid item xs={12} style={{ padding: "3px" }}>
                  <Grid container>
                    <Grid item xs={8}>
                      <TextField
                        value={data?.inlineStyles?.letterSpacingValue ?? ""}
                        onChange={(e) =>
                          handleChange(
                            "inlineStyles",
                            "letterSpacingValue",
                            e.target.value,
                            undefined,
                            "letterSpacing"
                          )
                        }
                        type="text"
                        fullWidth
                        label="Letter spacing"
                        className={classes.inputWithDrop}
                        margin="dense"
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        value={data?.inlineStyles?.letterSpacingType ?? "px"}
                        size="small"
                        fullWidth={false}
                        disableClearable
                        classes={{ endAdornment: classes.endAdornment }}
                        onChange={(event, newValue) =>
                          handleChange(
                            "inlineStyles",
                            "letterSpacingType",
                            newValue,
                            undefined,
                            "letterSpacing"
                          )
                        }
                        id="controllable-states-demo"
                        options={["px", "%"]}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className={classes.inputTypeDropDown}
                            margin="dense"
                            size="small"
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
                <Grid item xs={6} style={{ padding: "3px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={{
                      title: data?.inlineStyles?.textTransform ?? "",
                    }}
                    options={[
                      { title: "none", year: 0 },
                      { title: "lowercase", year: 1 },
                      { title: "uppercase", year: 2 },
                      { title: "capitalize", year: 3 },
                      { title: "end", year: 3 },
                      { title: "justify", year: 3 },
                    ]}
                    onChange={(e, v) =>
                      handleChange(
                        "inlineStyles",
                        "textTransform",
                        v?.title ?? ""
                      )
                    }
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option) => option.title}
                    style={{ width: "100%" }}
                    className={classes.inputField}
                    renderInput={(params) => (
                      <TextField
                        margin={"dense"}
                        {...params}
                        label={"Text Transform"}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={6} style={{ padding: "3px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={{
                      title: data?.inlineStyles?.whiteSpace ?? "",
                    }}
                    options={[
                      { title: "nowrap", year: 0 },
                      { title: "normal", year: 1 },
                      { title: "pre", year: 2 },
                    ]}
                    onChange={(e, v) =>
                      handleChange("inlineStyles", "whiteSpace", v?.title ?? "")
                    }
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option) => option.title}
                    style={{ width: "100%" }}
                    className={classes.inputField}
                    renderInput={(params) => (
                      <TextField
                        margin={"dense"}
                        {...params}
                        label={"White Spacing"}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>
            </Collapse>
          </>
        );
      case "background":
        return (
          <>
            <Grid
              item
              xs={12}
              className={
                collapse === 5 ? classes.listHeaderActive : classes.listHeader
              }
            >
              <ListItem button onClick={() => handleCollapse(5)}>
                <ListItemText
                  classes={{ primary: classes.styleList }}
                  primary="Background"
                />
                {collapse === 5 ? (
                  <ExpandLess className={classes.expandIcon} />
                ) : (
                  <ExpandMore className={classes.expandIcon} />
                )}
              </ListItem>
            </Grid>
            <Collapse in={collapse === 5 ?? false}>
              <Grid container>
                <Grid item xs={12} style={{ padding: "3px" }}>
                  <FormControl
                    variant="outlined"
                    margin={"dense"}
                    className={classes.inputField}
                    fullWidth
                  >
                    <InputLabel
                      style={{ fontSize: 11 }}
                      htmlFor="outlined-adornment-password"
                    >
                      Background Color
                    </InputLabel>
                    <OutlinedInput
                      label={"Background Color"}
                      id="outlined-adornment-password"
                      value={data?.inlineStyles?.backgroundColor ?? ""}
                      onChange={(e) =>
                        handleChange(
                          "inlineStyles",
                          "backgroundColor",
                          e.target.value
                        )
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <input
                            type="color"
                            value={data?.inlineStyles?.backgroundColor ?? ""}
                            onChange={(e) =>
                              handleChange(
                                "inlineStyles",
                                "backgroundColor",
                                e.target.value
                              )
                            }
                          />
                        </InputAdornment>
                      }
                      labelWidth={data?.inlineStyles?.fontSize?.labelWidth ?? 0}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ padding: "3px 10px", backgroundColor: "#E2E2E2" }}
                >
                  <Typography
                    style={{
                      fontSize: 10,
                      lineHeight: "normal",
                      fontWeight: 400,
                      margin: "2px 0px 2px 8px",
                    }}
                    className={classes.styleList}
                  >
                    Border
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ padding: "3px" }}>
                  <FormControl
                    variant="outlined"
                    margin={"dense"}
                    className={classes.inputField}
                    fullWidth
                  >
                    <InputLabel
                      style={{ fontSize: 11 }}
                      htmlFor="outlined-adornment-password"
                    >
                      Color
                    </InputLabel>
                    <OutlinedInput
                      label={"Border Color"}
                      id="outlined-adornment-password"
                      value={data?.inlineStyles?.borderColor ?? ""}
                      onChange={(e) =>
                        handleChange(
                          "inlineStyles",
                          "borderColor",
                          e.target.value
                        )
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <input
                            type="color"
                            value={data?.inlineStyles?.borderColor ?? ""}
                            onChange={(e) =>
                              handleChange(
                                "inlineStyles",
                                "borderColor",
                                e.target.value
                              )
                            }
                          />
                        </InputAdornment>
                      }
                      labelWidth={data?.inlineStyles?.fontSize?.labelWidth ?? 0}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ padding: "3px" }}>
                  <TextField
                    value={data?.inlineStyles?.borderWidth ?? ""}
                    onChange={(e) =>
                      handleChange(
                        "inlineStyles",
                        "borderWidth",
                        e.target.value
                      )
                    }
                    type="text"
                    fullWidth
                    label="Size"
                    className={classes.inputField}
                    margin="dense"
                    variant="outlined"
                  ></TextField>
                </Grid>
                <Grid item xs={6} style={{ padding: "3px" }}>
                  <Autocomplete
                    id="combo-box-demo"
                    value={{
                      title: data?.inlineStyles?.borderStyle ?? "",
                    }}
                    options={[
                      { title: "solid", year: 1 },
                      { title: "dashed", year: 2 },
                      { title: "dotted", year: 3 },
                    ]}
                    onChange={(e, v) =>
                      handleChange(
                        "inlineStyles",
                        "borderStyle",
                        v?.title ?? ""
                      )
                    }
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option) => option.title}
                    style={{ width: "100%" }}
                    className={classes.inputField}
                    renderInput={(params) => (
                      <TextField
                        margin={"dense"}
                        {...params}
                        label={"Style"}
                        variant="outlined"
                        style={{ fontSize: 11 }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid item xs={6} style={{ padding: "3px" }}>
                  <TextField
                    value={data?.inlineStyles?.borderRadius ?? ""}
                    onChange={(e) =>
                      handleChange(
                        "inlineStyles",
                        "borderRadius",
                        e.target.value
                      )
                    }
                    type="text"
                    fullWidth
                    label="Radius"
                    className={classes.inputField}
                    margin="dense"
                    variant="outlined"
                  ></TextField>
                </Grid>
              </Grid>
            </Collapse>
          </>
        );
      default:
        return false;
    }
  }

  React.useEffect(() => {
    setData(auth?.user?.componentAttr);
    // setCollapse(null);
    let UIComponent = auth.user?.UIcomponents.filter(
      (comp) => comp.componentName === auth.user?.componentAttr?.component
    );
    let result = UIComponent.length > 0 ? UIComponent[0] : {};
    setComponentFromDb({ ...result });
  }, [auth?.user?.componentAttr]);

  return (
    <div className={classes.root}>
      {auth.user?.componentAttr && (
        <Grid container item className={classes.container}>
          <Grid item xs={12} className={classes.lists}>
            <Grid>
              <Typography className={classes.propertyList} variant="subtitle2">
                {auth.user?.componentAttr?.name ?? ""}
              </Typography>
            </Grid>
            <Grid>
              <DeleteIcon
                className={classes.delete}
                onClick={() =>
                  // props.deleteTreeListComponent(data.screenId, data.id)
                  handleDeleteComponent(data.id)
                }
                color={"action"}
                fontSize={"small"}
                style={{ fontSize: "16px", marginRight: 12 }}
              />
            </Grid>
          </Grid>
          <div style={{ padding: "5px" }}>
            {componentFromDb &&
              componentFromDb?.supportedStyles &&
              componentFromDb?.supportedStyles?.map((style) => {
                return styleReturner(style);
              })}
            {componentFromDb &&
              (!componentFromDb?.supportedStyles ||
                componentFromDb?.supportedStyles?.length === 0) && (
                <Typography
                  style={{
                    fontSize: 14,
                    width: "90%",
                    cursor: "default",
                    margin: "24px auto",
                  }}
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  No CSS Style property supported for this component
                </Typography>
              )}
          </div>
        </Grid>
      )}
    </div>
  );
}
