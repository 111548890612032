import React from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import { RequiredField } from "../../common";

const FullName = (props) => {
  let {
    prefix = false,
    suffix = false,
    required = false,
    labelAlignment = "left",
    topLabel = "label",
    value,
    index,
    className,
    onChange = () => null,
    ...other
  } = props;
  const handleChange = (val) => {
    onChange(val);
  };
  return (
    <div className={`${className} fullnamecontainer`} {...other}>
      {prefix && (
        <div className="fullnameinputbox fullnamefirstlast">
          <Typography
            style={{
              textAlign: labelAlignment,
              width: "100%",
            }}
          >
            {topLabel} {required && <RequiredField color={"red"} />}
          </Typography>
          <TextField
            value={value}
            {...other}
            fullWidth
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      )}
      <div className="fullnameinputbox">
        <Typography
          style={{
            textAlign: labelAlignment,
            width: "100%",
          }}
        >
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
        <TextField
          value={value}
          {...other}
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div className="fullnameinputbox">
        <Typography
          style={{
            textAlign: labelAlignment,
            width: "100%",
          }}
        >
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
        <TextField
          value={value}
          {...other}
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div className="fullnameinputbox">
        <Typography
          style={{
            textAlign: labelAlignment,
            width: "100%",
          }}
        >
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
        <TextField
          value={value}
          {...other}
          fullWidth
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      {suffix && (
        <div className="fullnameinputbox fullnamefirstlast">
          <Typography
            style={{
              textAlign: labelAlignment,
              width: "100%",
            }}
          >
            {topLabel} {required && <RequiredField color={"red"} />}
          </Typography>
          <TextField
            value={value}
            {...other}
            fullWidth
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      )}
    </div>
  );
};
export default FullName;
