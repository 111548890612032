import Grid from "@material-ui/core/Grid";
import React,{useState} from "react";
import { TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { RequiredField } from "../..";
import MuiPhoneNumber from 'material-ui-phone-number';
import { ImportExport } from "@material-ui/icons";
import { country } from "../../../utils/constants";
import PhoneInput,{getCountries,getCountryCallingCode,isValidPhoneNumber} from 'react-phone-number-input'
import  'react-phone-number-input/style.css'
import '../styles/styles.css';
import {AlertContext} from '../../../contexts'
 import {AlertProps} from '../../../utils/helperFunctions'


const Phnumber = (props) => {
  let { required = false, topLabel = "Phone_Number", ...other } = props;
console.log("count",country)
  const autocomp = [{ type: "+91" }, { type: "+112" }];
  //  let captital= props.Countrycode.charAt(0).toUpperCase() + props.Countrycode.slice(1);
//   const captital = props.CountryName?.split(" ");
// for (var i = 0; i < captital?.length; i++) {
//   captital[i] = captital[i]?.charAt(0).toUpperCase() + captital[i]?.slice(1);

// }

// const captital2 = captital?.join(" ");
// console.log(captital2);
  
//   console.log(other,"other",captital2)
// const merge3 = arrays.flat(1); //The depth level specifying how deep a nested array structure should be flattened. Defaults to 1.
// console.log("conso",merge3);

const [value, setValue] = useState(0);
const [alertcountry, setAlertcountry] = useState(false);
const alert = React.useContext(AlertContext);

let countryiies =country.find(coun=>coun?.name===props?.CountryName)
let lower1=countryiies?.code
  // let  lower1 =lower?.toLowerCase()
console.log(countryiies,"countryiies",lower1)
 
// let code ='in'
// if(value){
//   alert.setSnack({
                
//         open: true,
//         severity: AlertProps.severity.error,
//         msg: "isValidPhoneNumber success",
//       }) 

// }
// const [phone, setPhone] = useState(0);

console.log("phoneinput",alertcountry)

  // if(alertcountry){
  //   alert.setSnack({
                
  //     open: true,
  //     severity: AlertProps.severity.error,
  //     msg: "isValidPhoneNumber success",
  //   }) 
  // }else{
  //   alert.setSnack({
                
  //     open: true,
  //     severity: AlertProps.severity.info,
  //     msg: "enter the number and select country code",
  //   }) 
  // }
 
  return (
    // <Grid
    //   container
    //   style={{
    //     display: "flex",
    //     justifyContent: "flex-start",
    //     alignItems: "center",
    //   }}
    //   item
    //   onChange={handleOnChange}
    //   {...other}
    // >
    //  <MuiPhoneNumber defaultCountry={'in'} onChange={handleOnChange}/>
  
    // </Grid>
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      // onChange={handleOnChange}
      item
      {...other}
    >
      <Grid
        container
        style={{ display: "flex", justifyContent: "flex-start" }}
        // spacing={8}
        // lg={12}
      >
        <Grid item container>
          <Typography>
            {topLabel} {required && <RequiredField color={"red"} />}
          </Typography>
        </Grid>

        <Grid item container lg={12}>
         
          <PhoneInput
              international
              defaultCountry={lower1??'IN'} 
              value={value??" "}
              onChange={value=>setValue(value)}
             
              />
       {/* {value && isValidPhoneNumber(value) ?setAlertcountry(true): setAlertcountry(false)}   */}
  
          {/* <Autocomplete
              size="small"
              options={propertiesOptions?.options ?? []}
              style={{ width: "40px" }}
              value={properties[propertyKey] ?? ""}
              getOptionLabel={(option) => option.type ?? ""}
              // onChange={(e, value) => {
              //   handleChange(propertyKey, value, index);
              // }}
              renderInput={(params) => <TextField {...params} label="CountryName"   variant="outlined"/> } 

              // style={{ width: "200px",}}
              // value={properties[propertyKey] ?? ""}
              // onChange={(e, value) => {
              //   handleChange(propertyKey, value, index);
              // }}
              // label={propertyKey}
              // options={propertiesOptions?.options ?? []}
              // optionViewKey="componentpath"
              // renderInput={(params) => <TextField {...params} label="CountryName"   variant="outlined"/> } 
            /> */}
          {/* <TextField size="small" variant="outlined" fullWidth /> */}
          {/* </Grid> */}
        </Grid>

       
      </Grid>
    </Grid>
  );
};

export default Phnumber;
