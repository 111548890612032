import React from "react";
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import { NotifyCard } from "./notifyCard";
import Config from "../../../config";
import { EmptyScreen } from "../../emptyScreen";
import { LoadingScreen } from "../../loadingScreen";

function ListNotification(props) {
  const { countnotify } = props;
  // console.log("LNOT",data)
  const [notifyList, setNotify] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    notify();
  }, []);

  const ListMarKasRead = async (data) => {
    await data.map(async (l) => {
      if (l.Notification_count === 0) {
        await markasread(l);
      }
    });
    //   notify()
  };

  const notify = async () => {
    // debugger;
    // setNotify([])
    let myHeaders = new Headers();
    myHeaders.append("content-type", "application/json");
    fetch(`${Config.api_url}/api/read_documents`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        db_name: Config.dbname,
        entity: process.env.REACT_APP_notify_ENTITY,
        filter: `Notification.Notification_count==0 && ${process.env.REACT_APP_notify_ENTITY}.activestatus==true`,
        return_fields:
          "keep(Notification,'id','_id','Notification','Notification_type','Notification_count','Patient_id','Mealorderid','createddate')",
        sort: "Notification.createddate desc",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let Res = [...result.result];
        //   result.result.forEach((e)=>{
        //     Res.push({
        //       image :'' ,
        //       message : e.Notification,
        //       // detailPage : "",
        //       receivedTime:'12h ago',
        //       Notification_count:e.Notification_count,
        //       _id:e._id
        //     })
        //     setNotificationMsg(Res)
        // console.log(Res);
        //   })
        // console.log(Res);
        setNotify(Res);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const markasread = async (l) => {
    // debugger;
    let myHeaders = new Headers();
    myHeaders.append("content-type", "application/json");
    let _key = l?._id?.split("/")[1];
    fetch(`${Config.api_url}/api/upsert_document`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify([
        {
          db_name: Config.dbname,
          entity: process.env.REACT_APP_notify_ENTITY,
          is_metadata: true,
          metadataId: "8fd7c4c8-706a-4edc-ab27-534cb7e9ad8a",
          metadata_dbname: "ATP_Platform_DEV",
          filter: {
            _key: _key,
          },
          doc: {
            Notification_count: 1,
          },
        },
      ]),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.Code === 201) {
          notify();
          countnotify();
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div id={"divS"} style={{ height: "100%" }}>
      {/* <Grid container id={"gridP"}> */}
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px",
            height: "52px",
          }}
        >
          <Typography>{"Notification"}</Typography>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => ListMarKasRead(notifyList)}
          >
            Mark all as read
          </Button>
        </div>

        <Divider />
      </Grid>
      <Box style={{ height: "calc(100% - 55px)", overflowY: "auto" }}>
        {loading ? (
          <LoadingScreen message={"Loading..."} />
        ) : notifyList?.length > 0 ? (
          <Grid container>
            {notifyList?.map((l, index) => (
              <NotifyCard
                title={l?.Notification}
                // notifyCard={true}
                type={l?.Notification_type}
                index={index}
                hideicon={false}
                numtag={true}
                data={l}
                markasread={markasread}
              />
            ))}
          </Grid>
        ) : (
          <EmptyScreen message={"No Notification"} />
        )}
      </Box>
    </div>
  );
}

export default ListNotification;
