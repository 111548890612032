import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Tab,
  Box,
  Tabs,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { blue, blueGrey as gray } from "@material-ui/core/colors";
import { WebAppPanel } from "./tab";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import * as Icons from "./icons";
import { docorateName } from "../../utils/helperFunctions";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { useQuery } from "react-query";
import { CustomText } from "../mainFrom/func";
import { AuthContext, AlertContext } from "../../contexts";
import { makeid } from "./commonFunction";
import { AlertProps } from "../../utils/helperFunctions";
import SectionComp from "../section";
import { getComponents } from "../../utils";
const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      // width: "150px",
      "& .MuiDivider-root ": {
        height: "0px",
      },
    },
    tabButton: {
      // width: "2px",
      textTransform: "capitalize",
      fontSize: "8px !important",
      minWidth: "10px !important",

      //border: "1px solid #ebebeb ",
      borderRadius: "0px ",
      backgroundColor: "#F6F6F8 ",
    },
    selectedTab: {
      //  color: "blue",

      borderRadius: "0px !important",
      padding: "0px 0px",
      minWidth: "50px",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },

    selecteTab: {
      //padding: "4px 12px",
      //border: "0px",
      border: "1px solid #007AFF",
      borderRadius: "5px  !important",
      minWidth: "60px",
      height: "25px",

      color: theme.palette.primary.main,
      //color: "white",
    },
    catagerie: {
      textTransform: "capitalize",
      padding: "3px 10px",
      marginBottom: "4px",
      borderBottom: "1.2px solid #e7e7e7",
      color: "#5b5b5c",
    },
    listItemText: {
      fontSize: "11.4px",
    },
    dragArea: {
      width: "75px",
      cursor: "grab",
      textAlign: "center",
      wordBreak: "break-words",
      padding: "0px 0px 5px 0px",
      minHeight: "80px",
      borderRadius: "8px",
      display: "grid",
      placeItems: "center",
      backgroundColor: blue[700],
      boxShadow: `0px 0px 10px ${gray[50]}`,
      "&:hover": {
        backgroundColor: blue[50],
        boxShadow: `0px 0px 20px ${gray[200]}`,
        "& .dragAreaText": {
          color: blue[900],
        },
        "& .iconBg": {
          backgroundColor: blue[700],
        },
        "& .dragAreaIcon": {
          color: "white",
        },
      },
      "& .iconBg": {
        borderRadius: "6px 6px 0px 0px",
        backgroundColor: blue[50],
        width: "100%",
        height: "100%",
        padding: "14px 0px",
        display: "grid",
        placeItems: "center",
      },
      "& .dragAreaText": {
        color: "white",
        padding: "0px 1px 0px 1px",
      },
      "& .dragAreaIcon": {
        color: blue[700],
        fontSize: "28px",
      },
    },
    collapseView: {
      // maxHeight: 'calc(100vh - 286px)',
      // overflowX: "hidden",
      // overflowY: "auto",
      color: "gray",
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexWrap: "wrap",
      },
    },
    inputInput: {
      height: "0.9em",
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)}px) !important`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
    searchIcon: {
      padding: "0px 6px !important",
      position: "absolute !important",
      pointerEvents: "none !important",
      display: "flex!important",
      alignItems: "center !important",
      justifyContent: "center!important",
      marginTop: "10px !important",
    },

    primaryElementText: {
      fontSize: "50px",
    },
    search: {
      borderRadius: "4px !important",
      borderBottom: "1.3px solid rgba(0, 0, 0, 0.12) !important",
      border: "1.3px solid rgba(0, 0, 0, 0.12) !important",
      marginLeft: "12px",
      marginRight: "20px",

      //backgroundColor: fade("#fff", 0.15),
      "&:hover": {
        // backgroundColor: fade("#fff", 0.25),
      },

      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    primaryAndTemplate: {
      fontSize: "8px",
    },
    components: {
      fontSize: "10px",
      color: "#6E6E6E",
    },
    tabfont: {
      fontFamily: "Roboto Helvetica Arial sans-serif",
      fontWeight: 400,
    },
    tapcss: {
      fontSize: 11,
      textTransform: "none",
      // borderTopLeftRadius: 6,
      // borderTopRightRadius: 6,
      minWidth: 50,
      backgroundColor: "#fff",
      "&.MuiTab-root": {
        textTransform: "capitalize",
        padding: 0,
        margin: 0,
      },
    },
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

export function ComponentsList({ setShowSection }) {
  const { data } = useQuery("components", getComponents, {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
  });
  const getComponent = (val) => {
    const components = {};
    val.forEach((component) => {
      if (!components[component?.componentType]) {
        components[component?.componentType] = [];
      }
      components[component?.componentType].push(component);
    });
    return components;
  };
  const auth = React.useContext(AuthContext);
  const alert = React.useContext(AlertContext);
  const classes = useStyles();
  const [open, setOpen] = useState({});
  const [openDialog, setOpenDialog] = React.useState(false);
  const groupedComponents = data ? getComponent(auth.user.UIcomponents) : {};

  const [sectionName, setSectionName] = React.useState("");

  const handleClick = (val) => {
    setOpen({
      [val]: open[val] ? !open[val] : true,
    });
  };

  const onDragStart = (evt, type, obj) => {
    let element = evt.currentTarget;
    element.classList.add("vivifySmall");
    element.classList.add("pulsate");
    element.classList.add("dragAreaBg");
    evt.dataTransfer.setData("components", evt.currentTarget.id);
    evt.dataTransfer.setData("componentId", obj.componentId);
    evt.dataTransfer.effectAllowed = "move";
  };

  const onDragEnd = (evt, type) => {
    let element = evt.currentTarget;
    element.classList.remove("dragAreaBg");
    element.classList.remove("vivifySmall");
    element.classList.remove("pulsate");
  };
  const getgrouperComponentsList = (
    list,
    open,
    type,
    group,
    openAll,
    setOpenAll
  ) => {
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        style={{ width: "100%", padding: "0px" }}
      >
        {/* Component Category List Mapping */}

        <ListItem
          button
          onClick={() => {
            setOpenAll(false);
            handleClick(group);
          }}
          dense
          className={classes.catagerie}
        >
          <ListItemText
            classes={{ primary: classes.listItemText }}
            style={{ fontSize: 6 }}
            primary={group === "undefined" ? "Others" : group}
          />
          {(openAll || open[group]) ?? false ? (
            <KeyboardArrowUpIcon
              style={{ fontSize: "14px" }}
              fontSize={"default"}
              color={"#000"}
            />
          ) : (
            <KeyboardArrowDownIcon
              style={{ fontSize: "14px" }}
              color={"#000"}
              fontSize={"default"}
            />
          )}
        </ListItem>

        {/* Component Category List Mapping End*/}

        {/* Component List */}
        <Collapse
          in={(openAll || open[group]) ?? false}
          timeout="auto"
          unmountOnExit
          className={`${classes.collapseView} scroll-bar`}
        >
          {list.map((component, index) => {
            let Icon = Icons[component.componentName] ?? Icons["Template"];
            return (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  draggable={true}
                  key={index}
                  onDragStart={(e) => onDragStart(e, "components", component)}
                  onDragEnd={(e) => onDragEnd(e, "components")}
                  id={component.componentId}
                  style={{
                    cursor: "grab",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    margin: "5px",
                    border: "1.4px dashed #cbd5e1",
                    padding: "1px 4px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    {Icon && (
                      <Icon style={{ color: "#64748b", width: "15px" }} />
                    )}
                    <Typography
                      variant={"body1"}
                      color={"inherit"}
                      style={{
                        fontSize: "10px",
                        color: "#0f172a",
                        width: "100%",
                        paddingTop: "4px",
                        // wordBreak: "break-all",
                        marginLeft: "10px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {docorateName(component?.componentName)}
                    </Typography>
                  </div>
                  <DragIndicatorIcon
                    color={"action"}
                    style={{
                      fontSize: "14px",
                      marginTop: "5px",
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Collapse>

        {/* Component List End */}
      </List>
    );
  };

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreate = () => {
    if (auth.user.section.every((comp) => comp.componentName !== sectionName)) {
      const createdSectionJson = {
        componentName: sectionName,
        render: {
          child: [],
        },
        id: makeid(),
        componentType: "section",
        supported: ["web"],
        componentId: "functional-components-layout-7",
        frameWork: "functional-components-layout",
      };
      auth.user.section.push(createdSectionJson);
      auth.setAuth({
        ...auth.user,
      });
      setOpenDialog(false);
      setSectionName("");
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Section Name Alredy Exist",
      });
    }
  };
  return (
    <div className={classes.root}>
      {/* Menu Title */}
      {/* Menu Title End */}
      {/* Component Type Tab */}
      {/* <div>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "35px", borderBottom: "2px solid #ebebeb" }}
        >
          <Typography className={classes.components}>COMPONENTS</Typography>
        </Grid>
      </div> */}
      {/* Component Type Tab END */}{" "}
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        indicatorColor="primary"
        // className={`${classes.scroller} scroll-bar`}
        textColor="primary"
        variant="fullWidth"
        TabIndicatorProps={{
          style: { background: "#808080" },
        }}
        className={classes.tabfont}
      >
        <Tab
          label={
            <h6
              style={{
                fontSize: "10px",
                textTransform: "capitalize !important",
                fontWeight: 300,
                margin: "0",
                color: "#9f9f9f",
              }}
            >
              Components
            </h6>
          }
          {...a11yProps(0)}
          style={{
            minWidth: 50,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: "white",
          }}
          className={classes.tapcss}
        />
        <Tab
          label={
            <h6
              style={{
                fontSize: "10px",
                textTransform: "capitalize !important",
                fontWeight: 300,
                margin: "0",
                color: "#9f9f9f",
              }}
            >
              Sections
            </h6>
          }
          {...a11yProps(1)}
          style={{
            minWidth: 50,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: "white",
          }}
          className={classes.tapcss}
        />
      </Tabs>
      <div>
        <TabPanel value={value} index={0}>
          {
            <>
              <Grid container item style={{ marginTop: "12px" }}>
                <Grid item xs={12}>
                  <WebAppPanel
                    groupedComponents={groupedComponents}
                    getgrouperComponentsList={getgrouperComponentsList}
                    open={open}
                    handleTabChange={handleTabChange}
                    value={value}
                    index={0}
                  />
                </Grid>
              </Grid>
            </>
          }
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SectionComp
            groupedComponents={getComponent(auth.user.section)}
            setShowSection={setShowSection}
            setOpenDialog={setOpenDialog}
          />
          {/* <WebAppPanel
            groupedComponents={getComponent(auth.user.section)}
            getgrouperComponentsList={getgrouperComponentsList}
            open={open}
            handleTabChange={handleTabChange}
            value={value}
            index={1}
          /> */}
        </TabPanel>
      </div>
      <Dialog open={openDialog} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          Create Section
        </DialogTitle>
        <DialogContent>
          <CustomText
            value={sectionName}
            required
            topLabel="Section Name"
            onChange={(e) => setSectionName(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenDialog(false);
              setShowSection(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleCreate()}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
