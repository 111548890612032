import { Grid, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
// import { variantSelector, typeSelector } from "./propertyJson";
import DeleteIcon from "@material-ui/icons/Delete";
import { MuiInput } from "../../common/inputs";
import { deleteComponent } from "../../componentList/commonFunction";
import { AlertContext, AuthContext, DialogContext } from "../../../contexts";
import useStyles from "./styles";
// import * as AllPackages from "functional-component-library";
// import "react-transliterate/dist/index.css";
import { AlertProps, getProjectPages, idm_delete } from "../../../utils";
import { PropertyRenderer } from "./propertyRenderer";

export function PropertiesPanel(props) {
  const validationOptions = [
    "isRequired",
    "minLength",
    "maxLength",
    "helperText",
    "regex",
    "min",
    "max",
  ];

  const auth = useContext(AuthContext);
  const dialog = useContext(DialogContext);
  const alert = useContext(AlertContext);

  const classes = useStyles();
  const [data, setData] = useState(auth.user.componentAttr);
  const [componentFromDb, setComponentFromDb] = useState();
  const [checked, setChecked] = useState({});
  const [validationValue, setValidationValue] = useState([]);

  // function for if validation needed
  const handleCheck = (event) => {
    if (
      auth.user?.screens?.[0]?.readMode === true &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
    setChecked({
      [auth.user?.componentAttr?.id]: event.target.checked,
    });
  };

  const handleDeleteComponent = (id) => {
    if (
      auth.user?.screens?.[0]?.readMode === true &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }

    const handleOnOkModal = () => {
      deleteComponent(id, auth);
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
      });
      // props.onClose("right", false);
      idm_delete(id);
    };
    const handClose = () => {
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => {},
        onCancel: () => {},
      });
    };
    dialog.setDialog({
      ...dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete is layer?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };

  ///handling property change
  const handleChange = (key, val) => {
    console.log(key, val);
    let dataCopy = JSON.parse(JSON.stringify(data));

    //Checking the screen is in edit mode
    if (
      auth.user?.screens?.[0]?.readMode === true &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "The screen is in read mode",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }
    if (
      auth.user?.screens?.[0]?.readMode === false &&
      auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy
    ) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
        tone: true,
      });
    }

    dataCopy["properties"][key] = val;

    //Badge Component Zero content handling
    if (dataCopy?.component === "Badge") {
      if (
        dataCopy?.properties?.invisible === false &&
        dataCopy?.properties?.badgeContent === 0 &&
        dataCopy?.properties?.showZero === false
      ) {
        const { invisible, ...remaining } = dataCopy["properties"];
        dataCopy["properties"] = remaining;
      }
    }

    if (key === "navItems") {
      let screenId = auth.user?.screens[0].id;
      let componentType =
        props.showSection === "section" ? "section" : "AllScreenList";
      auth.user[componentType].forEach((screen, index, array) => {
        if (data["properties"]["navbarType"] === "list") {
          if (val.filter((nav) => nav.pageId === screen.id).length > 0) {
            array[index] = { ...screen, masterLayoutId: screenId };
          } else {
            array[index] = { ...screen, masterLayoutId: "" };
          }
        } else {
          if (
            val.filter((parentNav) =>
              parentNav.navItems.some((nav) => nav.pageId === screen.id)
            ).length > 0
          ) {
            array[index] = { ...screen, masterLayoutId: screenId };
          } else {
            array[index] = { ...screen, masterLayoutId: "" };
          }
        }
      });
    }

    // if (e === "childPageSelect") {
    //   let screenId = auth.user?.screens[0].id;
    //   auth.user.AllScreenList.forEach((screen, index, array) => {
    //     if (v.filter((val) => val.screenId === screen.id).length > 0) {
    //       array[index] = { ...screen, masterLayoutId: screenId };
    //     } else {
    //       array[index] = { ...screen, masterLayoutId: "" };
    //     }
    //   });
    // }

    setData({
      ...dataCopy,
    });
    let componentType =
      props.showSection === "section" ? "section" : "AllScreenList";
    //updating the property to the auth context
    if (componentType === "section") {
      auth.user.section.forEach((screen) => {
        screen.render.child.forEach(function iter(comp, index, array) {
          if (comp.id === dataCopy.id) {
            comp["isTranslate"] = true;
            comp.properties = {
              ...dataCopy.properties,
            };
          } else {
            if (comp?.child?.length > 0) {
              comp.child.forEach(iter);
            }
          }
        });
      });
    } else {
      auth.user.AllScreenList.forEach((screen) => {
        if (screen.id === auth.user.selectedScreen) {
          screen.render.child.forEach(function iter(comp, index, array) {
            if (comp.id === dataCopy.id) {
              comp["isTranslate"] = true;
              comp.properties = {
                ...dataCopy.properties,
              };
            } else {
              if (comp?.child?.length > 0) {
                comp.child.forEach(iter);
              }
            }
          });
        }
      });
    }
    auth.setAuth({
      ...auth.user,
      componentAttr: dataCopy,
    });
  };

  // const dynamicPropertiesComponent = {
  //   MockUI: MockUIPropertiesRenderer,
  //   CustomCode: CustomCodePropertiesRenderer,
  // };

  const propertyPropsProvider = (component, propertyKey) => {
    debugger;
    let props = JSON.parse(JSON.stringify(component?.props[propertyKey]));
    switch (props?.options) {
      case "getPaths":
        let pagesData = getProjectPages(auth);
        let paths = pagesData.reduce((prev, curr) => {
          return [...prev, curr.page];
        }, []);
        //this is when the property is selected with select component , basically string
        if (!Array.isArray(data?.properties[propertyKey])) {
          props["options"] = paths;
          return props;
        }
        //this is when the property is selected with multiSelect component , basically array of strings
        paths = (paths ?? []).filter((path) => {
          if (data?.properties[propertyKey]?.some((v) => v === path)) {
            return false;
          } else {
            return true;
          }
        });
        props["options"] = paths;
        return props;
      default:
        return props;
    }
  };

  useEffect(() => {
    setData(auth?.user?.componentAttr);
    if (
      auth?.user?.componentAttr &&
      Object.keys(auth?.user?.componentAttr)?.length > 0 &&
      Object.keys(auth?.user?.componentAttr?.validations)?.length > 0
    ) {
      setChecked({
        [auth?.user?.componentAttr?.id]: true,
      });
      let validationKeys = Object.keys(
        auth.user?.componentAttr?.validations
      ).map((val) => {
        if (val === "patternType" || val === "pattern") {
          return "regex";
        } else if (val === "title") {
          return "helperText";
        } else if (val === "required") {
          return "isRequired";
        }
        return val;
      });
      validationKeys = [...new Set(validationKeys)];
      setValidationValue(validationKeys);
    } else {
      setChecked({
        [auth.user?.componentAttr]: true,
      });
      setValidationValue([]);
    }

    let UIComponent = auth.user?.UIcomponents.filter(
      (comp) => comp.componentName === auth.user?.componentAttr?.component
    );
    let result = UIComponent.length > 0 ? UIComponent[0] : {};

    setComponentFromDb({ ...result });
  }, [auth?.user?.componentAttr, auth?.user?.UIcomponents]);

  return (
    <div className={classes.root}>
      {auth.user?.componentAttr && (
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.lists}>
            <Grid>
              <Typography
                style={{ fontSize: 12.7, color: "#737070" }}
                className="text"
                variant="subtitle2"
              >
                {auth.user?.componentAttr?.name ?? ""}
              </Typography>
            </Grid>
            <Grid>
              <DeleteIcon
                className={classes.delete}
                onClick={() => handleDeleteComponent(data.id)}
                color={"action"}
                fontSize={"small"}
                style={{ fontSize: "16px", marginRight: 12 }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "grid",
              gap: "16px 0px",
              width: "100%",
              padding: "12px",
            }}
          >
            {/* aliasName*/}
            <MuiInput
              className={classes.textfieldcss}
              value={data?.properties?.aliasName ?? ""}
              onChange={(e) => handleChange("aliasName", e.target.value)}
              label="Alias name"
              // fontSize="14px"
            />
            {/* id*/}
            <MuiInput
              className={classes.textfieldcss}
              value={data?.properties?.id ?? data.id}
              onChange={(e) => handleChange("id", e.target.value)}
              label="Id"
              // fontSize="14px"
            />
            {/* Comment*/}
            <MuiInput
              className={classes.textfieldcss}
              value={data?.properties?.comment ?? ""}
              onChange={(e) => handleChange("comment", e.target.value)}
              label="Comment"
              // fontSize="14px"
            />
            {/* Material UI property Renderer start */}
            {auth.user?.componentAttr &&
              (auth.user?.componentAttr?.frameWork?.startsWith("material") ||
                auth.user?.componentAttr?.frameWork ===
                  "custom-material-ui-component" ||
                auth.user?.componentAttr?.frameWork === "atp-master-layout" ||
                auth.user?.componentAttr?.frameWork === "atp-login" ||
                auth.user?.componentAttr?.frameWork === "atp-table" ||
                auth.user?.componentAttr?.frameWork === "atp-general-master" ||
                auth.user?.componentAttr?.frameWork === "atp-authentication" ||
                auth.user?.componentAttr?.frameWork === "report_viewer_atp" ||
                auth.user?.componentAttr?.frameWork === "atp-stepper" ||
                auth.user?.componentAttr?.frameWork === "atp-authentication" ||
                auth.user?.componentAttr?.frameWork === "custom" ||
                auth.user?.componentAttr?.frameWork ===
                  "functional-components-layout") &&
              Object.keys(componentFromDb?.props ?? {}).length > 0 &&
              Object.keys(componentFromDb?.props ?? {}).map(
                (propertyKey, index) => {
                  let propertiesOptions = propertyPropsProvider(
                    componentFromDb,
                    propertyKey
                  );
                  return (
                    <PropertyRenderer
                      showSection={props.showSection}
                      data={data}
                      properties={data?.properties ?? {}}
                      propertyKey={propertyKey}
                      component={auth.user?.componentAttr?.component}
                      propertiesOptions={propertiesOptions}
                      handleChange={handleChange}
                    />
                  );
                }
              )}
            {/* Material UI property Renderer end */}

            {/* Validation Property */}
            {/* <FormControlLabel
                  label={
                    <Typography style={{ fontSize: "14px" }}>
                      Validation required
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={
                        checked[`${auth.user?.componentAttr?.id}`] ?? false
                      }
                      onChange={handleCheck}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      size="small"
                    />
                  }
                ></FormControlLabel>
                {checked[auth.user?.componentAttr?.id] && (
                  <Autocomplete
                    value={validationValue}
                    multiple
                    id="size-small-outlined-multi"
                    size="small"
                    options={validationOptions}
                    getOptionLabel={(option) => option}
                    onChange={(event, newValue) => {
                      setValidationValue(newValue);
                    }}
                    className={classes.inputField}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Validations"
                        margin="dense"
                        style={{ fontSize: "14px" }}
                        placeholder="Select validation"
                      />
                    )}
                  />
                )}
                {validationValue.length > 0 && (
                  <Validations
                    validationValue={validationValue}
                    handleCollapse={handleCollapse}
                    collapse={collapse}
                    key={auth.user?.componentAttr?.id}
                  />
                )} */}
          </div>
        </Grid>
      )}
    </div>
  );
}
