import  React,{ChangeEvent} from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Grid } from '@material-ui/core';
import { RequiredField } from "../..";
// import {DefaultDropDown} from "../../../utils/constants"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function Dropdown (props) {

  
  // console.log("dropdown",props)
  let { required = false, topLabel = "DropDowm", ...other } = props;
  
  const [personName, setPersonName] = React.useState([]);
  console.log("other",props)
// console.log(DefaultDropDown?.map(name=>name),"leng")
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      
      <Grid
      container
      // disabled={disabled}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      item
      {...other}
    > <Grid
    container
    style={{ display: "flex", justifyContent: "flex-start" }}
    // spacing={8}
    // lg={12}
  > 
     <Grid item container lg={12}>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">{topLabel} {required && <RequiredField color={"red"} />}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          disabled={props?.disabled}
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {props?.stepsHeader?.map((name) => (
            

            <MenuItem key={name?.title} value={name?.title}>
              {name?.title!==""&&
              <>
              <Checkbox checked={personName.indexOf(name?.title) > -1} />
              <ListItemText primary={name?.title} />
              </>
              }
            </MenuItem>
           
          ))}
        </Select>
      </FormControl>
      </Grid>
      </Grid>
      </Grid>

    </div>
  );
}
