import { useContext } from "react";
import {
  getProjectComponents,
  getscreenComponent,
} from "../../componentList/commonFunction";
import {
  IconSelect,
  ImageUpload,
  MessageCatalog,
  MuiInput,
  MuiMultiSelect,
  MuiSelect,
  PageSelect,
  
} from "../../common/inputs";
import { ArrayOfObject } from "./arrayOfObject";
import { Customedate } from "./customeDate";
import { MuiAutocomplete } from "../../../components/common/inputs/autocomplete";
import { MuiTextArea } from "../../../components/common/inputs/textArea";
import { AlertContext, AuthContext } from "../../../contexts";
import { AlertProps, getProjectPages } from "../../../utils";
import AddOptions from "./addOptions";
import {TextField} from "@material-ui/core"
import Autocomplete from '@mui/material/Autocomplete';
import useStyles from "./styles";

const propertyProducer = (props, alert, auth,classes) => {

  const {
    data,
    properties,
    propertyKey,
    component,
    propertiesOptions,
    handleChange,
    index,
  } = props;
  // let classes= useStyles()
  console.log(propertiesOptions?.options,"mine",props)
  const handleGetSection = (arr, filter = []) => {
debugger
   
    arr.user.section.forEach((e) => {
      if (e?.componentType) {
        
        filter.push({ title: e?.componentName });
      }
    });
    return filter;
  };
  var country = propertiesOptions?.options?.filter((el)=>
    {
      return el.name ==="India" &&
             el.code === "IN" 
    }
    );
  console.log(country,"",props)
  
  switch (propertiesOptions.componentToRender) {
    case "text":
    case "number":
      return (
        <MuiInput
          value={properties[propertyKey] ?? ""}
          onChange={(e) => {
            const value =
              propertiesOptions.componentToRender === "number"
                ? !isNaN(Number(e.target?.value))
                  ? Number(e.target?.value)
                  : null
                : e.target.value;
            let pattern;
            if (component === "Box") {
              pattern = /^[a-z]{1,}$/;
              const result = pattern.test(value ?? "");
              if (!result && value)
                return alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.warning,
                  msg: "Not a valid input",
                  vertical: AlertProps.vertical.top,
                  horizontal: AlertProps.horizontal.right,
                  tone: true,
                });
            }
            handleChange(propertyKey, value, index);
          }}
          label={propertyKey}
        />
      );
    case "messageCatalog":
      return (
        <MessageCatalog
          value={properties[propertyKey]}
          label={propertyKey}
          onChange={(value) => {
            handleChange(propertyKey, value, index);
            data["isTranslate"] = true;
          }}
          data={data}
        />
      );
    case "select":
      return (
        <MuiSelect
          value={properties[propertyKey] ?? ""}
          onChange={(e) => {
            handleChange(propertyKey, e.target.value, index);
          }}
          label={propertyKey}
          options={propertiesOptions.options}
        />
      );
    case "section":
      return (
        <MuiAutocomplete
          value={properties[propertyKey] ?? ""}
          onChange={(e, value) => {
            handleChange(propertyKey, value, index);
          }}
          label={propertyKey}
          options={handleGetSection(auth) ?? []}
          optionViewKey="title"
        />
      );
    case "icons":
      return (
        <IconSelect
          value={properties[propertyKey]?.iconName ?? ""}
          onChange={(value) => {
            handleChange(propertyKey, value, index);
          }}
          label={propertyKey}
        />
      );
    case "imageUpload":
      return (
        <ImageUpload
          onChange={(url) => {
            handleChange(propertyKey, url);
          }}
          value={properties[propertyKey] ?? ""}
        />
      );
    case "multiSelect":
      return (
        <MuiMultiSelect
          value={properties[propertyKey] ?? ""}
          onChange={(value) => {
            
            handleChange(propertyKey, value, index);
          }}
          creatable={propertiesOptions?.creatable ?? true}
          label={propertyKey}
          options={propertiesOptions?.options ?? []}
        />
      );
    case "component":
      let components = getProjectComponents(auth);
      return (
        <MuiAutocomplete
          value={properties[propertyKey] ?? ""}
          onChange={(e, value) => {
            handleChange(propertyKey, value, index);
          }}
          label={propertyKey}
          options={components ?? []}
          optionViewKey="componentName"
        />
      );
    case "screen":
      let screens = getscreenComponent(auth);
      
      return (
        <MuiAutocomplete
          value={properties[propertyKey] ?? ""}
          onChange={(e, value) => {
            handleChange(propertyKey, value, index);
          }}
          label={propertyKey}
          options={screens ?? []}
          optionViewKey="componentpath"
        />
      );
    case "pageSelect":
      let pages = getProjectPages(auth);
      //to get filtered Options
      if (properties["navbarType"] === "list") {
        pages = (pages ?? []).filter((page) => {
          if (
            !properties[propertyKey]?.some((v) => v?.pageId === page?.pageId)
          ) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        pages = (pages ?? []).filter((page) => {
          if (
            properties[propertyKey]?.filter((parentNav) => {
              return (
                parentNav?.navItems?.length > 0 &&
                parentNav?.navItems?.some((nav) => nav?.pageId === page?.pageId)
              );
            }).length > 0
          ) {
            return false;
          } else {
            return true;
          }
        });
      }
      return (
        <PageSelect
          pages={pages}
          navItems={properties[propertyKey] ?? []}
          getNavItems={(navItemsData) => {
            handleChange(propertyKey, navItemsData, index);
          }}
          nested={properties["navbarType"] === "list" ? false : true}
        />
      );
    case "themeGetter":
      return (
        <MuiTextArea
          minRows={12}
          maxRows={32}
          value={properties[propertyKey] ?? ""}
          onChange={(e) => {
            handleChange(propertyKey, e.target.value, index);
          }}
          placeholder={
            "Insert a valid material ui theme JSON - https://bareynol.github.io/mui-theme-creator/"
          }
        />
      );
      case "textarea":
        return (
          <Autocomplete     
          // style={{ width: "200px",}}
          value={properties[propertyKey] ?? ""}
          onChange={(e, value) => {
            handleChange(propertyKey, value, index);
          }}
          className={classes.autocompelete}
          size="small"
          label={propertyKey}
          options={propertiesOptions?.options ?? []}
          optionViewKey="componentpath"
          renderInput={(params) => <TextField {...params} label="CountryName"   variant="outlined"/> } 
        />

        // <Autocomplete      
        // disablePortal      
        // id="combo-box-demo"     
        //  options={propertiesOptions?.options ?? []}      
        // //  sx={{ width: 300 }}      
        //  renderInput={(params) => <TextField {...params} /> } label="CountryName"   />
        );
    case "ArrayOfObject":
      return (
        <ArrayOfObject
          handleChange={handleChange}
          toRender={propertiesOptions?.value?.[0]}
          name={propertyKey}
          values={properties[propertyKey] ?? []}
          data={data}
        />
      );
    case "AddOptions":
      return (
        <div>
          <AddOptions
            name={propertyKey}
            handleChange={(name, val) => handleChange(name, val)}
          />
        </div>
      );
    case "MinAge":
      if (properties?.agelimit) {
        return (
          <div>
            <TextField
              id="outlined-name"
              label="Min Age"
              variant="outlined"
              value={properties[propertyKey] ?? ""}
              type="number"
              onChange={(e) => {
                handleChange(propertyKey, e.target.value, index);
              }}

            />
          </div>
        );
      }
      else{
        return <></>
      }

    case "MaxAge":
       
      if (properties?.agelimit) {
        return (
          <div>
            <TextField
              id="outlined-name"
              label="Max Age"
              variant="outlined"
              type="number"
              value={properties[propertyKey] ?? ""}
              onChange={(e) => {
                handleChange(propertyKey, e.target.value, index);
              }}

            />
          </div>
        );
        
      }
      else{
        return <></>
      }

      case "customeDate":
        
        return (

          <div>
            <Customedate
              properties={properties}
              handleChange={handleChange}
              index={index}
              propertyKey={propertyKey}
              propertiesOptions={propertiesOptions}
            />
          </div>
        );
      
      

    default:
      break;
  }
};

export const PropertyRenderer = (props) => {
  const alert = useContext(AlertContext);
  const auth = useContext(AuthContext);
  let classes=useStyles()
  return <>{propertyProducer(props, alert, auth,classes)}</>;
};
