import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CommonHeader } from "./index";
import Box from "@material-ui/core/Box";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "20px",
    display: "flex",
    minHeight: "76vh",
    borderRadius: "10px",
    "& .Mui-selected": {
      backgroundColor: theme.sideBar.background.active,
      color: theme.sideBar.text.active,
    },
    "& .App1-MuiTab-root": {
      padding: "10px 20px",
      textTransform: "none",
      textAlign: "start",
      alignItems: "center",
      "& .App1-MuiTab-wrapper": {
        alignItems: "initial",
      },
    },
    "& .App1-MuiTabs-flexContainer": {
      // marginTop: 20,
      marginBottom: 20,
    },
    "& .App1-MuiTabs-indicator": {
      display: "none",
    },
  },
  tabs: {
    //borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.table,
    borderRadius: "0px 0px 10px 10px",
    border: `1px solid #E0E0E0`,
    //width: "20%",
  },
  tabpanel: {
    marginLeft: 20,
    backgroundColor: theme.palette.background.table,
    border: `1px solid #E0E0E0`,
    width: "80%",
    borderRadius: "10px",
  },
  onlypanel: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid #E0E0E0`,
    width: "100%",
    borderRadius: "10px",
  },
  btn: {
    color: theme.palette.button.text,
    padding: "6px 16px",
    fontSize: "12px",
    fontFamily: "poppinsemibold",
    textTransform: "none",
    backgroundColor: theme.palette.button.background,
    justifyContent: "flex-start",
    borderBottom: "1px solid #E0E0E0",
    borderTop: "1px solid #E0E0E0",
    borderRadius: 0,
    "&.App1-MuiButton-root:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  emptyAlign: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25%",
    color: "#6F6F6F",
  },
  emptyRight: {
    display: "flex",
    justifyContent: "center",
    color: "#6F6F6F",
    width: "100%",
    background: "#fff",
    margin: "0px 20px",
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    paddingTop: "16%",
    backgroundColor: theme.palette.background.table,
  },
  box: {
    backgroundColor: theme.palette.background.table,
    borderRadius: 8,
  },
}));
function TabPanel(props) {
  const { children, value, parent_id, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      //id={`${parent_id}_age_vertical_div`}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          className={classes.box}
          id={`${parent_id}_age_vertical_Box`}
          color="pri"
          p={3}
        >
          <Grid>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical_tab_${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function VerticalList(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const {
    TabList = [],
    isLeftList,
    addFunc = () => null,
    changeTab = () => null,
    parent_id,
    addNew,
    in_styles = {},
    editListItem = () => null,
    editList = false,
    showSearch,
    searchText,
    handleListSearch = () => null,
  } = props;
  const handleChange = (event, newValue) => {
    changeTab(newValue);
    setValue(newValue);
  };

  useEffect(() => {
    if (props.addNew) {
      setValue(1);
    }
  }, [props.addNew]);

  const handleForm = () => {
    props.openForm(true);
  };

  return (
    <div id={`${parent_id}_age_vertical_div`} className={classes.root}>
      {isLeftList && (
        <span style={{ display: "flex", flexDirection: "column" }}>
          {showSearch && (
            <div
              style={{
                padding: 10,
                borderTop: "1px solid #E0E0E0",
                borderLeft: "1px solid #E0E0E0",
                borderRigt: "1px solid #E0E0E0",
                borderBottom: 0,
                background: "white",
                borderRadius: "10px 10px 0 0",
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search"
                fullWidth
                style={{ height: "50px" }}
                value={searchText}
                onChange={(e) => {
                  handleListSearch(e.target.value);
                }}
                InputProps={{
                  style: {
                    fontFamily: "poppin",
                    fontSize: "12px",
                    // background: "#f7f7f7",
                  },
                  // startAdornment: (
                  //   <InputAdornment position="start">
                  //     <img src={searchicon} alt="Icon" />
                  //   </InputAdornment>
                  // ),
                }}
                InputLabelProps={{
                  style: {
                    fontSize: 12,
                  },
                }}
              />
            </div>
          )}

          <Tabs
            id={`${parent_id}-age-vertical-tabs`}
            orientation="vertical"
            variant={"scrollable"}
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
            style={{ ...in_styles }}
          >
            {addNew ? (
              <Button
                id={`${parent_id}-Add-new-button`}
                className={classes.btn}
                onClick={handleForm}
              >
                + Add new
              </Button>
            ) : null}
            {props.emptyData ? (
              <div
                id={`${parent_id}-Nothing-Listed-div`}
                className={classes.emptyAlign}
                style={{ margin: "50%" }}
              >
                Nothing Listed
              </div>
            ) : (
              TabList.map((val, i) => (
                <Tab
                  id={`${parent_id}-${i}-age-vertical-tab`}
                  // label={val.name+i}
                  label={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant={"inherit"}>{val.name}</Typography>
                      {editList && (addNew ? i + 1 : i) === value ? (
                        <EditIcon
                          fontSize={"small"}
                          onClick={() => editListItem(val)}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  }
                  {...a11yProps(i)}
                />
              ))
            )}
          </Tabs>
        </span>
      )}
      {props.emptyData ? (
        <div
          id={`${parent_id}-Nothing-Listed-div`}
          className={classes.emptyRight}
        >
          Nothing Selected
        </div>
      ) : (
        TabList.map((val, i) => (
          <TabPanel
            id={`${parent_id}-${i}-age-vertical-TabPanel`}
            className={isLeftList ? classes.tabpanel : classes.onlypanel}
            value={value}
            index={addNew ? i + 1 : i}
          >
            {
              <CommonHeader
                parent_id={"vertical_commonheader_" + i}
                title={val.name}
                btnFun={() => addFunc(i)}
                index={addNew ? i : value}
                BtnName={val?.hiddenButton ? null : "+ Add New"}
              />
            }
            {val.component ? val.component : ""}
          </TabPanel>
        ))
      )}
    </div>
  );
}
export default VerticalList;
