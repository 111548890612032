import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid, Typography } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const Singlechoice = (props) => {
  let { question = "Enter your question", stepsHeader, ...other } = props;
  console.log(props);

  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const getField = (stepIndex) => {
    return stepsHeader?.map((v, i) => {
      return (
        <Grid item key={i} {...v} value={stepIndex}>
          <RadioGroup onChange={handleChange} value={value}>
            <FormControlLabel
              value={v?.title?.length === 0 ? `label-${i}` : v?.title}
              control={<Radio />}
              label={v?.title?.length === 0 ? `label-${i}` : v?.title}
            />
          </RadioGroup>
        </Grid>
      );
    });
  };

  function singleselectThis(id) {
    var single = document.getElementsByName("single");

    Array.prototype.forEach.call(single, function (el) {
      el.checked = false;
    });
    id.checked = true;
  }
  return (
    <Grid item {...other}>
      <Grid item container xs={12}>
        <Typography>{question}</Typography>
      </Grid>
      <Grid
        item
        container
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Grid>{getField()}</Grid>
      </Grid>
    </Grid>
  );
};

export default Singlechoice;
