import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import useStyles from "../../mainFrom/propertyspanel/styles.jsx";

export const MuiAutocomplete = (props) => {
  debugger;
  const classes = useStyles();
  const { label, value, options, onChange, optionViewKey } = props;
  return (
    <Autocomplete
      id="autocomplete"
      size="small"
      options={options}
      value={value}
      onChange={onChange}
      getOptionLabel={(option) => option[optionViewKey]}
      getOptionSelected={(option) => option[optionViewKey]}
      className={classes.autocompelete}
      renderInput={(params) => (
        <TextField
          margin={"dense"}
          {...params}
          label={label}
          variant="outlined"
        />
      )}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
};

MuiAutocomplete.defaultProps = {
  onChange: () => {},
  label: "Multi Select",
  options: [],
  value: "",
  optionViewKey: "title",
};
