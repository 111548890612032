import React from "react";
import { Divider, Typography } from "@material-ui/core";
import { MuiInput, MuiMultiSelect } from "./index";
import { ImageUpload } from "./imageUpload";
import { makeid } from "../../componentList/commonFunction";

export const NavItemsSelector = ({ navItems, pages, handleChange, child }) => {
  return (
    <div style={{ display: "grid", gap: "16px 0px" }}>
      <MuiMultiSelect
        onChange={(pages) => {
          handleChange("navitems", pages, null, child);
        }}
        value={navItems}
        creatable={false}
        label={"Navitem select"}
        optionViewKey="name"
        options={pages}
      />
      <div>
        {navItems.map((nav) => {
          return (
            <div style={{ display: "grid", gapY: "6px" }}>
              <MuiInput
                value={nav?.name ?? ""}
                onChange={(e) => {
                  handleChange("name", e.target.value, nav.pageId, child);
                }}
                label={"Navitem Name"}
              />
              <MuiInput
                value={nav?.page ?? ""}
                onChange={(e) => {
                  handleChange("page", e.target.value, nav.pageId, child);
                }}
                label={"Navitem path"}
              />
              <MuiInput
                value={nav?.size ?? ""}
                onChange={(e) => {
                  handleChange(
                    "size",
                    parseInt(e.target.value),
                    nav.pageId,
                    child
                  );
                }}
                type="number"
                label={"Navitem size"}
              />
              <ImageUpload
                onChange={(url) => {
                  handleChange("icon", url, nav.pageId, child);
                }}
                value={nav.icon ?? ""}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const PageSelect = (props) => {
  const { navItems, getNavItems, pages, nested } = props;

  const handleNavSelect = (key, val, pageId, index) => {
    //handling nested navitems
    if (nested) {
      if (key === "navitems") {
        if (index !== undefined) {
          navItems.forEach((navItem, navItemIndex) => {
            if (navItemIndex === index) {
              navItem["navItems"] = val;
            }
          });
          getNavItems(navItems);
        } else {
          getNavItems(val);
        }
      } else {
        if (index !== undefined) {
          navItems.forEach((navItem, navItemIndex) => {
            if (navItemIndex === index) {
              navItem.navItems.forEach((navItem) => {
                if (navItem?.pageId === pageId) {
                  navItem[key] = val;
                }
              });
            }
          });
        } else {
          navItems.forEach((navItem, navItemIndex) => {
            if (navItemIndex === pageId) {
              navItem[key] = val;
            }
          });
        }
        getNavItems(navItems);
      }
    }
    //handling navitems
    else {
      if (key === "navitems") {
        getNavItems(val);
      } else {
        navItems.forEach((navItem) => {
          if (navItem.pageId === pageId) {
            navItem[key] = val;
          }
        });
        getNavItems(navItems);
      }
    }
  };
  return (
    <div>
      <Typography variant="body2" style={{ margin: "12px 0px" }}>
        Navitems Selector
      </Typography>
      {nested ? (
        <div style={{ display: "grid", gap: "16px 0px" }}>
          <MuiMultiSelect
            onChange={(parentItems) => {
              let result = parentItems.reduce((prev, curr) => {
                if (
                  navItems.some(
                    (navItem) =>
                      navItem.parentName.toLowerCase() === curr.toLowerCase()
                  )
                ) {
                  let oldObj = navItems.find(
                    (navItem) =>
                      navItem.parentName.toLowerCase() === curr.toLowerCase()
                  );
                  return [...prev, oldObj];
                } else {
                  let obj = {
                    parentName: curr,
                    parentSize: 16,
                    parentIcon: "",
                    navItems: [],
                    parentId: makeid(),
                  };
                  return [...prev, obj];
                }
              }, []);
              handleNavSelect("navitems", result);
            }}
            value={navItems.reduce((prev, curr) => {
              return [...prev, curr.parentName];
            }, [])}
            creatable={true}
            label={"Navitem select"}
          />
          <div>
            {navItems.map((nav, index) => {
              return (
                <div style={{ display: "grid", gapY: "6px" }}>
                  <MuiInput
                    value={nav?.parentName ?? ""}
                    onChange={(e) => {
                      handleNavSelect("parentName", e.target.value, index);
                    }}
                    label={"Parent Name"}
                  />
                  <MuiInput
                    value={nav?.parentSize ?? ""}
                    onChange={(e) => {
                      handleNavSelect(
                        "parentSize",
                        parseInt(e.target.value),
                        index
                      );
                    }}
                    type="number"
                    label={"Parent size"}
                  />
                  <ImageUpload
                    onChange={(url) => {
                      handleNavSelect("parentIcon", url, index);
                    }}
                    value={nav.parentIcon ?? ""}
                  />
                  <div style={{ padding: "0px 0px 0px 12px" }}>
                    <NavItemsSelector
                      navItems={nav?.navItems}
                      pages={pages}
                      handleChange={handleNavSelect}
                      child={index}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <NavItemsSelector
          navItems={navItems}
          pages={pages}
          handleChange={handleNavSelect}
        />
      )}
      <Divider variant="fullWidth" />
    </div>
  );
};

PageSelect.defaultProps = {
  onChange: () => {},
  getNavItems: () => {},
  pages: [],
  navItems: [],
};
