import React, { useMemo } from "react";
import { makeStyles, Grid, Typography, IconButton } from "@material-ui/core";

import { Upload } from "./fileUpload";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { ToBase64 } from "../../../../utils";
import { RequiredField } from "../../../common";
import { AlertContext } from "../../../../contexts";

import { AlertProps } from "../../../../utils";
import GetAppIcon from "@material-ui/icons/GetApp";

var b64toBlob = require("b64-to-blob");
var FileSaver = require("file-saver");

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    flexGrow: 1,
    position: "relative",
    minHeight: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    margin: 0,
  },
  root: {
    padding: theme?.spacing(3),
    paddingTop: theme?.spacing(2),
  },
  footer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  content: {
    display: "inline-flex",
    width: "100%",
  },
  cardContainer: {
    backgroundColor: theme?.palette?.background?.paper,
    marginTop: theme?.spacing(2),
    boxShadow: "0px 10px 15px #00000014",
    border: "2px solid #E2E2E2",
    borderRadius: "8px",
  },
  Container: {
    // backgroundColor: theme.palette.background.paper,
    marginTop: theme?.spacing(0.5),
    marginBottom: theme?.spacing(0.5),
    // boxShadow: "0px 10px 15px #00000014",
    border: "1px solid #E2E2E2",
    borderRadius: "8px",
    padding: "2px",
  },
  circularLoader: {
    paddingRight: "8px",
    position: "relative",
    "& .MuiCircularProgress-root": {
      position: "relative",
    },
  },
  loadCenter: {
    position: "absolute",
    left: 14,
  },
  uploadText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));

const FileUpload = (props) => {
  console.log("fileuploadprops", props)
  const classes = useStyles(props);
  const Humanreadablefilesize = useMemo(() => {

    return returnFileSize(props?.MaximumFileSizeInKB)
  }, [props?.MaximumFileSizeInKB])
  const alert = React.useContext(AlertContext);

  const [uploaddocc, setuploaddocc] = React.useState(
    props?.value ? props?.value : []
  );

  function returnFileSize(number) {
    if (number < 1024) {
      return number + "bytes";
    } else if (number >= 1024 && number < 1048576) {
      return (number / 1024).toFixed(1) + "KB";
    } else if (number >= 1048576) {
      return (number / 1048576).toFixed(1) + "MB";
    }
  };

  function bytessintokbconverter(number) {
    return Number(number) / 1024
  }

  const FileSizeSetLimit = (orginalfilesize) => {
    debugger
    let filesizeinkb = bytessintokbconverter(orginalfilesize)
    if (filesizeinkb <= props?.MaximumFileSizeInKB) {
      return true;
    } else {
      return false;
    }
  };

  const handleUpload = async (e) => {
    debugger
    e.preventDefault();
    if (props?.MaximumFileSizeInKB) {
      try {
        let file = e?.target?.files[0];
        let size = returnFileSize(file?.size);
        let files = new FormData();
        files.append("files", file);
        let base = await ToBase64(file);
        const data = {
          name: file?.name,
          load: true,
          file: base,
          size: size,
        };
        let isfilesmall = FileSizeSetLimit(file.size)
        let isvalidfile = validFileType(file)
        // size limit check and valid file check
        if (isfilesmall && isvalidfile) {
          // for single
          if (!props?.ismultiple && uploaddocc.length >= 1) {
            if (alert?.open)
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Only Single File can be Uploaded !",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });

            else
              window.alert("Only Single File can be Uploaded !",)
          } else if (!props?.ismultiple && uploaddocc.length === 0) {
            setuploaddocc([...uploaddocc, ...[data]]);
          }
          //for multiple
          else if (props?.ismultiple) {
            setuploaddocc([...uploaddocc, ...[data]]);
          }
        } else {

          if (!isfilesmall && !isvalidfile) {
            if (alert?.open)
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: `Only ${props?.FileType?.toString()} File Type Accepted and File Size should be less than ${props?.MaximumFileSizeInKB} KB !`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            else
              window.alert(`Only ${props?.FileType?.toString().toLowerCase()}  File Type Accepted ! and File Size should be less than ${props?.MaximumFileSizeInKB} KB !`)
          }
          else if (!isfilesmall) {
            if (alert?.open)
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: `File Size should be less than ${props?.MaximumFileSizeInKB} KB!`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            else
              window.alert(`File Size should be less than ${props?.MaximumFileSizeInKB} KB!`)
          }
          else if (!isvalidfile) {
            if (alert?.open)
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: `Only ${props?.FileType?.toString()}  File Type Accepted !`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            else
              window.alert(`Only ${props?.FileType?.toString()}  File Type Accepted !`)

          }
        }

      }
      catch (err) {

      }
    }
    else {
      if (alert?.open)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: `Please enter the maximum file size in KB`,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      else
        window.alert(`Please enter the maximum file size in KB`)
    }


  };
  const handleDelete = (e) => {
    var uploaddoccdelete = [];
    uploaddoccdelete = [...uploaddocc];
    uploaddoccdelete.splice(0, 1);
    //delete uploaddocc[0];
    // uploaddocc.splice(i, 1);

    setuploaddocc([...uploaddoccdelete]);

    // props.onChange([...uploaddoccdelete]);

    // uploaddocc.splice(0,1);
    // setuploaddocc([...uploaddocc]);
    // props.onChange([...uploaddocc]);
  };
  const getLabel = (props) => {
    //debugger
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props?.topLabel}{" "}
        {props?.required && (
          <Typography
            variant="caption"
            style={{ color: "red", marginLeft: "2px" }}
          >
            *
          </Typography>
        )}
      </Typography>
    );
  };

  const fileTypes = [
    "JPEG",
    "GIF",
    "PNG",
    "PDF",
    "HTML",
    "CSV",
    "SVG",
  ];

  const validFileType = (file) => {
    // debugger
    let findingfiletype = file?.name?.split(".")
    let filetypes = Array.isArray(props?.FileType) ? props?.FileType : [props?.FileType];
    console.log(filetypes?.includes(findingfiletype?.[findingfiletype?.length - 1]?.toUpperCase()))
    return filetypes?.includes(findingfiletype?.[findingfiletype?.length - 1]?.toUpperCase());
  };

  const Download = async (data) => {
    var contentType = data.file.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    var b64Data = data.file.split(",")[1];

    var blob = await b64toBlob(b64Data, contentType);

    var saveBlob = await new Blob([blob], { type: contentType });
    await FileSaver.saveAs(saveBlob, data.name);
  };

  return (
    <Grid  {...props}>
      <div className={classes.rootContainer}>
        <div className={classes.root} noValidate>
          <Typography
            style={{
              textAlign: props?.labelAlignment,
              width: "100%",
            }}
          >
            {props?.topLabel} {props?.required && <RequiredField color={"red"} />}
          </Typography>

          {!props?.isReadonly && (
            <Upload
              acceptType={props?.question_options}
              onChange={handleUpload}
              single={props?.single}
              id={props?.id}
            />
          )}

          {uploaddocc?.length > 0 && !props?.isReadonly && (
            <>
              <div>
                <Typography variant="body1">Uploaded Files</Typography>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  height: props?.single ? "62.5px" : "125px",
                  border: "1px solid #00000014",
                }}
              >
                {uploaddocc?.map((item, key) => {
                  return (
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.Container}
                    >
                      <Grid item xs={2}>
                        <DescriptionOutlinedIcon
                          style={{ marginLeft: "3px" }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={props?.isReadonly ? 8 : 6}
                        className={classes.uploadText}
                      >
                        <Typography variant="subtitle2" color="textPrimary">
                          {item?.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <IconButton
                          id={`${"downloadBtn" + key}`}
                          onClick={() => Download(item)}
                        >
                          <GetAppIcon style={{ color: "green" }} />
                        </IconButton>
                      </Grid>

                      <Grid item xs={2}>
                        {!props?.isReadonly && (
                          <IconButton
                            id={`${"delBtn" + key}`}
                            onClick={() => handleDelete()}
                            aria-label="settings"
                            disabled={props?.isReadonly}
                          >
                            <DeleteOutlineIcon
                              style={{ color: "red", marginRight: "3px" }}
                            />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            </>
          )}

          {props?.errorValidation && props?.errorValidation?.error && (
            <div>
              <Typography variant="caption" className={classes.errorText}>
                {props?.errorValidation?.errorMessage}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default FileUpload;
