import { Button, Divider, TextField, Typography } from "@material-ui/core";
import React from "react";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { RequiredField } from "../common";
import { v4 as uuidv4 } from "uuid";
import { makeid } from "../componentList/commonFunction";
import { Autocomplete } from "@material-ui/lab";
const FormType = ["Assessment", "Report"];
const layoutList = ["1", "2", "3", "4"];
export const AddForm = ({
  editData = [],
  dataIndex = -1,
  setCardData = () => null,
  drawerClose = () => null,
}) => {
  const [formData, setFormData] = React.useState({
    form: editData.length !== 0 ? editData[0].form : "",
    // type: editData.length !== 0 ? editData[0].type : "",
    ...(editData.length !== 0 && {
      formMetadataId: editData[0].formMetadataId,
    }),
    layout: editData.length !== 0 ? editData[0].layout : "",
  });
  const Alert = React.useContext(AlertContext);
  const handleChange = (name, val) => {
    setFormData({ ...formData, [name]: val });
  };
  const validate = () => {
    let formDatas = { ...formData };
    let valid = false;
    valid = Object.values(formDatas).every((v) => v.length !== 0);
    return valid;
  };
  const addFormData = () => {
    if (validate()) {
      let formMetadataId =
        editData.length === 0 ? uuidv4() : formData.formMetadataId;
      const myHeader = new Headers();
      myHeader.append("content-type", "application/json");
      fetch(process.env.REACT_APP_UPSERT_DOCUMENT, {
        method: "post",
        headers: myHeader,
        body: JSON.stringify([
          {
            db_name: process.env.REACT_APP_DBNAME,
            entity: "form_details",
            is_metadata: false,
            ...(editData.length !== 0 && {
              filter: {
                formMetadataId: formData.formMetadataId,
              },
            }),
            doc: {
              ...formData,
              formMetadataId,
              metadataid: localStorage.getItem("main_metadata_id"),
            },
          },
        ]),
      });
      if (editData.length === 0) {
        fetch(process.env.REACT_APP_UPSERT_DOCUMENT, {
          method: "post",
          headers: myHeader,
          body: JSON.stringify([
            {
              db_name: process.env.REACT_APP_DBNAME,
              entity: "screen",
              is_metadata: false,
              doc: {
                id: makeid(),
                type: "Screen",
                render: {
                  component: "div",
                  component_type: "qdm",
                  child: [],
                },
                version: 1,
                metadataid: formMetadataId,
              },
            },
          ]),
        });
      }
      Alert.setSnack({
        ...Alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Form Added Successfully",
      });
      setCardData({ ...formData, formMetadataId }, dataIndex);
      drawerClose();
    } else {
      Alert.setSnack({
        ...Alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required Fields Are Empty",
      });
    }
  };
  return (
    <>
      <div style={{ padding: "1em 1.2em" }}>
        <Typography variant="body1">
          {editData.length !== 0 ? "Update" : "Add"} Form
        </Typography>
      </div>
      <Divider />
      <div style={{ padding: "1em 1.2em" }}>
        <div>
          <Typography variant="overline">
            Form Name <RequiredField color={"red"} />
          </Typography>
          <TextField
            value={formData.form}
            onChange={(e) => handleChange("form", e.target.value)}
            color="primary"
            variant="outlined"
            size="small"
            placeholder="Form Name"
            fullWidth
          />
        </div>
        <div>
          {/* <Typography variant="overline">
            Type <RequiredField color={"red"} />
          </Typography> */}

          {/* <Autocomplete
            value={formData.type}
            onChange={(e, value) => handleChange("type", value)}
            color="primary"
            fullWidth
            size="small"
            options={FormType}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          /> */}
        </div>
        <div>
          <Typography variant="overline">
            Layout <RequiredField color={"red"} />
          </Typography>
          <Autocomplete
            onChange={(e, value) => handleChange("layout", value)}
            color="primary"
            fullWidth
            size="small"
            options={layoutList}
            value={formData.layout}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "0.5em 1.2em",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => addFormData()}
        >
          {editData.length !== 0 ? "Update" : "Add"}
        </Button>
      </div>
    </>
  );
};
