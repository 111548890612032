import { Grid, TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { RequiredField } from "../..";

const Address = (props) => {
    let {
        variant = "standard",
        disabled,
        required = false,
        topLabel = "Address",
        style,
        ...other
    } = props;


    return (
        <Grid item {...other} style={style} >
            <Grid item container style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography>{topLabel} {required && <RequiredField color={"red"} />}</Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item container>
                    <TextField
                        label="Address"
                        size="small"
                        variant={variant}
                        fullWidth
                    />
                </Grid>
                <Grid item container>
                    <TextField
                        label="Area"
                        size="small"
                        variant={variant}
                        fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="City"
                        size="small"
                        variant={variant}
                        fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="State"
                        size="small"
                        variant={variant}
                        fullWidth />
                </Grid>
                <Grid item container>
                    <TextField
                        label="Country"
                        size="small"
                        variant={variant}
                        fullWidth
                    />
                </Grid>
                <Grid item container>
                    <TextField
                        label="Zip code"
                        size="small"
                        variant={variant}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Address;
