import React from "react";
import {
  IconButton,
  Drawer,
  Typography,
  AppBar,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import WebIcon from "@material-ui/icons/Web";
import { DynamicComponentsList, eleAtrribute } from "./jsonData";
import UiBuilderPanel from "./uibuilderpanel";
import SaveIcon from "@material-ui/icons/Save";
import { NetworkCall } from "../../networkcall";
import { v4 as uuidv4 } from "uuid";
import {
  getPropertiesItems,
  layoutComponentsList,
  findItemNested,
  makeid,
  cloneComponent,
} from "./commonFunction";
import { ComponentsList } from "../componentList/componentsList";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import CssBaseline from "@material-ui/core/CssBaseline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AlertProps } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext, AlertContext } from "../../contexts";
import { ScreenLayout } from "./screenLayout";
const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
  },
  appBar: {
    backgroundColor: theme.palette.primary,
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    transform: "scale(0.6)",
    flexGrow: 1,
    display: "grid",
    gridTemplateColumns: "auto",
    gap: "1em",
    padding: theme.spacing(1),
    backgroundColor: "white",

    // backgroundImage:
    //   "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PHBhdHRlcm4gaWQ9ImdyaWQiIHdpZHRoPSI0MCIgaGVpZ2h0PSI0MCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHBhdGggZD0iTSAwIDEwIEwgNDAgMTAgTSAxMCAwIEwgMTAgNDAgTSAwIDIwIEwgNDAgMjAgTSAyMCAwIEwgMjAgNDAgTSAwIDMwIEwgNDAgMzAgTSAzMCAwIEwgMzAgNDAiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2QwZDBkMCIgb3BhY2l0eT0iMC4yIiBzdHJva2Utd2lkdGg9IjEiLz48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZDBkMGQwIiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JpZCkiLz48L3N2Zz4=)",
  },
  title: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

const MainForm = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({ rightMenu: false });
  const auth = React.useContext(AuthContext);
  const [authData, setAuthData] = React.useState(auth);
  const [showSection, setShowSection] = React.useState(false);

  React.useLayoutEffect(() => {
    setAuthData(auth);
  }, [auth]);
  const alert = React.useContext(AlertContext);
  const isHTMLBlock = (evt, screen_id) => {
    const htmlBlockTags = [
      "div",
      "ol",
      "a",
      "li",
      "aside",
      "footer",
      "section",
      "article",
    ];
    let type = evt.target?.closest(".fielddrag")?.getAttribute("data-name");
    //getting the target element id to check the component type is html
    let typeId = evt.target?.closest(".fielddrag")?.getAttribute("data-id");
    //getting the target element object
    let componentData;
    authData.user.AllScreenList?.forEach((screen) => {
      if (screen.id === screen_id) {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.id === typeId) {
            componentData = component;
          } else {
            if (component?.child?.length > 0) {
              component.child.forEach(iter);
            }
          }
        });
      }
    });
    localStorage.setItem(
      "previewScreen",
      JSON.stringify(authData.user.screens)
    );
    const isBlock = htmlBlockTags.includes(type);
    return {
      htmlComponent: componentData ?? {},
      isHtmlTagBlock: isBlock,
    };
  };
  const onClose = (type, val) => {
    if (type === "left") {
      setState({ ...state, leftMenu: val, rightMenu: true });
    } else if (type === "right") {
      if (document.querySelector(".selectedComponent") && !val) {
        document
          .querySelector(".selectedComponent")
          .classList.remove("selectedComponent");
      }
      setState({ ...state, rightMenu: true });
    }
  };

  const onDragEnter = (evt, screen_id) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    if (
      evt.dataTransfer.types[0] === "movetocomponents" ||
      evt.dataTransfer.types[0] === "components" ||
      evt.dataTransfer.types[0] === "componentid" ||
      evt.dataTransfer.types[0] === "template" ||
      evt.dataTransfer.types[0] === "projecttemplate"
    ) {
      evt.dataTransfer.dropEffect = "move";
      // Add the focus backgroundColor class
      let pushTarget = evt.target.closest(".fielddrag")
        ? evt.target.closest(".fielddrag").getAttribute("data-id")
          ? evt.target.closest(".fielddrag").getAttribute("data-id")
          : "new_row"
        : "new_row";
      if (pushTarget === "new_row") {
        element.classList.add("dragged-over-builder");
      } else {
        let type = evt.target.closest(".fielddrag").getAttribute("data-name");
        const isHTMLBlockData = isHTMLBlock(evt, screen_id);
        const componentList = authData?.user?.UIcomponents;
        const closetComponentName = evt.target
          .closest(".fielddrag")
          .getAttribute("data-name");
        let materialBlockComp = componentList.filter(
          (comp) => comp.componentName === closetComponentName
        )[0]?.isLayout;

        if (
          layoutComponentsList(type) ||
          isHTMLBlockData.isHtmlTagBlock ||
          materialBlockComp
        ) {
          let result = checkDropIsNotItsChild(
            localStorage.drag_item_id,
            evt.target.closest(".fielddrag").getAttribute("data-id")
          );

          if (!result) {
            evt.target
              .closest(".fielddrag")
              .classList.add("mystyle", "fielddragCss");
          }
          // return false;
        }
      }
    }
  };
  const updateNewChildComponent = (
    object,
    child_id,
    screen_id,
    compType = "AllScreenList"
  ) => {
    authData.user[compType].filter((screen) => {
      if (screen.id === screen_id) {
        updateChildJson(screen.render.child, child_id, object);
        return true;
      } else {
        return false;
      }
    });
    // this.setState({ ...this.state, isEdited: true });
    authData.setAuth({
      ...authData.user,
      screenEdited: screen_id,
    });
    localStorage.setItem(
      "previewScreen",
      JSON.stringify(authData.user.screens)
    );
  };

  const updateChildJson = (screen, child_id, object) => {
    if (screen) {
      screen.filter((child, index) => {
        if (child.id === child_id) {
          if (child.child) {
            child.child.push(object);
            if (authData.user.componentAttr.id === child_id) {
            }
          } else {
            child["child"] = [object];
          }

          return true;
        } else {
          updateChildJson(child.child, child_id, object);
          return true;
        }
      });
      // return true
    } else {
      return false;
    }
    localStorage.setItem("previewScreen", JSON.stringify(screen));
    sessionStorage.setItem("previewScreen", JSON.stringify(screen));
  };
  //   Add New Component function
  const addNewComponent = (object, screen_id, comptype = "AllScreenList") => {
    authData.user[comptype].filter((screen) => {
      if (screen.id === screen_id) {
        return screen.render.child.push(object);
      } else {
        return false;
      }
    });
    authData.setAuth({
      ...authData.user,
      AllScreenList: authData.user.AllScreenList,
      screenEdited: screen_id,
    });
    // this.setState({ ...this.state, isEdited: true });
    localStorage.setItem(
      "previewScreen",
      JSON.stringify(authData.user.screens)
    );
    sessionStorage.setItem(
      "previewScreen",
      JSON.stringify(authData.user.screens)
    );
  };
  const deletearr = (arr, key) => {
    const listKey = "id",
      nextLoop = "child";
    arr.map((val, i) => {
      if (val[listKey] === key) {
        arr.splice(i, 1);
      }
      if (nextLoop && val[nextLoop]) {
        deletearr(val[nextLoop], key, listKey, nextLoop);
      }
      return false;
    });
    return arr;
  };
  const checkDropIsNotItsChild = (dragId, dropId) => {
    let dragComponent;

    authData.user.AllScreenList.forEach((screen) => {
      screen.render.child.forEach(function iter(comp, index, array) {
        if (comp.id === dragId) {
          dragComponent = comp;
        } else {
          if (comp?.child?.length > 0) {
            comp.child.forEach(iter);
          }
        }
      });
    });

    if (!dragComponent?.child) {
      return false;
    }
    if (dragId === dropId) {
      return true;
    }

    let dropComponent;

    dragComponent.child.forEach(function iter(comp, index, array) {
      if (comp.id === dropId) {
        dropComponent = comp;
      } else {
        if (comp?.child?.length > 0) {
          comp.child.forEach(iter);
        }
      }
    });
    localStorage.setItem(
      "previewScreen",
      JSON.stringify(authData.user.screens)
    );
    if (dropComponent) {
      return true;
    } else {
      return false;
    }
  };
  // Move or inter change the component JSON  function_2
  const moveChildJson = (screen, child_id, move_item_id, object, screenAll) => {
    if (screen) {
      screen.filter((child, index) => {
        if (child.id === child_id) {
          if (child.child) {
            deletearr(screenAll, move_item_id);
            object.id = move_item_id;
            child.child.push(object);
          } else {
            deletearr(screenAll, move_item_id);
            object.id = move_item_id;
            child["child"] = [object];
          }
          return true;
        } else {
          moveChildJson(child.child, child_id, move_item_id, object, screenAll);
          return true;
        }
      });
      // return true
    } else {
      return false;
    }
    localStorage.setItem(
      "previewScreen",
      JSON.stringify(authData.user.screens)
    );
  };
  const onDrop = (evt, screen_id, comptype) => {
    let drag_item_id = evt.dataTransfer.getData("componentId");
    let move_item_id = evt.dataTransfer.getData("moveToComponents");
    // let template_id = evt.dataTransfer.getData("template");
    let project_template_id = evt.dataTransfer.getData("projectTemplate");

    let pushTarget = evt.target.closest(".fielddrag")
      ? evt.target.closest(".fielddrag").getAttribute("data-id")
        ? evt.target.closest(".fielddrag").getAttribute("data-id")
        : "new_row"
      : "new_row";

    let componentObject;

    if (drag_item_id) {
      let componentConfigData = authData?.user?.UIcomponents.filter(
        (item) => item.componentId === drag_item_id
      )[0];
      let componentId = makeid();
      componentObject = {
        type: "component",
        component: componentConfigData?.componentName,
        component_type: DynamicComponentsList.some(
          (component) => component.name === drag_item_id
        )
          ? "dynamic"
          : "qdm",
        isLayout: componentConfigData?.isLayout ?? false,
        name: componentConfigData?.componentName,
        id: componentId,
        attr: null,
        componentId: componentConfigData.componentId,
        frameWork: componentConfigData.frameWork,
        properties:
          { id: componentId, ...componentConfigData?.defaultProps } ?? {},
      };
    }
    // add new template
    const isHTMLBlockData = isHTMLBlock(evt, screen_id);

    //handling project template dropping
    if (evt.dataTransfer.types[0] === "projecttemplate") {
      let template_name = project_template_id;
      let componentData;
      authData.user.projectTemplates.filter((templateGroup) => {
        return templateGroup.templates.forEach((template) => {
          if (template.name === template_name) {
            componentData = template;
            return true;
          }
        });
      });
      const clonedComponent = cloneComponent(componentData.json);
      let getJson = {
        ...componentData,
        json: clonedComponent,
      };
      if (pushTarget === "new_row") {
        addNewComponent(getJson.json, screen_id, comptype);
        evt.currentTarget.classList.remove("dragged-over-builder");
        evt.currentTarget.classList.remove("mystyle");
        evt.preventDefault();
        return false;
      } else {
        evt.target.closest(".fielddrag").classList.remove("mystyle");
        let type = evt.target.closest(".fielddrag").getAttribute("data-name");
        const componentList = authData?.user?.UIcomponents;
        let materialBlockComp = componentList.filter(
          (comp) => comp.componentName === type
        )[0]?.isLayout;
        if (
          (layoutComponentsList(type) ||
            materialBlockComp ||
            isHTMLBlockData.isHtmlTagBlock) &&
          getJson.type === "component"
        ) {
          updateNewChildComponent(
            getJson.json,
            pushTarget,
            screen_id,
            comptype
          );
        } else {
          alert("Template drop new screen layout only");
        }
        evt.target.closest(".fielddrag").classList.remove("mystyle");
        // return false;
      }
      evt.currentTarget.classList.remove("dragged-over");
      evt.preventDefault();
    }
    const moveChildComponent = (move_item_id, pushTarget, screen_id) => {
      // Main parent child update function
      if (pushTarget === "new_row") {
        let typeofComp =
          comptype === "section"
            ? authData.user.section
            : authData.user.AllScreenList;
        typeofComp.filter((screen) => {
          if (screen.id === screen_id || comptype === "section") {
            let object = findItemNested(
              screen.render.child,
              move_item_id,
              "child"
            );
            deletearr(screen.render.child, move_item_id);
            addNewComponent(object, screen_id, comptype);
            return true;
          } else {
            return false;
          }
        });
        return false;
      }

      //   Nested child update function
      if (move_item_id !== pushTarget) {
        authData.user.AllScreenList.filter((screen) => {
          if (screen.id === screen_id) {
            let object = findItemNested(
              screen.render.child,
              move_item_id,
              "child"
            );
            moveChildJson(
              screen.render.child,
              pushTarget,
              move_item_id,
              object,
              screen.render.child
            );
            return true;
          } else {
            return false;
          }
        });
        // this.setState({ ...this.state, isEdited: true});
        authData.setAuth({
          ...authData.user,
          screenEdited: screen_id,
        });
      }
      localStorage.setItem(
        "previewScreen",
        JSON.stringify(authData.user.screens)
      );
    };
    // move to component
    if (evt.dataTransfer.types[0] === "movetocomponents") {
      if (move_item_id && move_item_id?.length > 0) {
        let result = checkDropIsNotItsChild(move_item_id, pushTarget);
        if (result) return false;
        moveChildComponent(move_item_id, pushTarget, screen_id);
        if (pushTarget !== "new_row") {
          evt.target.closest(".fielddrag")?.classList.remove("mystyle");
          evt.target?.classList.remove("vivifySmall");
          evt.target?.classList.remove("pulsate");
          document.getElementById(move_item_id)?.classList.remove("hide");
        } else {
          evt.currentTarget?.classList.remove("dragged-over-builder");
          evt.currentTarget?.classList.remove("mystyle");
        }
        evt.preventDefault();
        evt.currentTarget?.classList.remove("dragged-over");
        return false;
      }
    }

    // if(!drag_item_id || drag_item_id?.length <= 0){
    //   return false
    // }

    if (
      evt.dataTransfer.types[0] === "components" ||
      evt.dataTransfer.types[0] === "componentid"
    ) {
      if (pushTarget === "new_row") {
        addNewComponent(componentObject, screen_id, comptype);
      } else {
        let type = evt.target.closest(".fielddrag").getAttribute("data-name");
        const componentList = authData?.user?.UIcomponents;
        let materialBlockComp = componentList.filter(
          (comp) => comp.componentName === type
        )[0]?.isLayout;

        if (
          layoutComponentsList(type) ||
          materialBlockComp ||
          isHTMLBlockData.isHtmlTagBlock
        ) {
          updateNewChildComponent(
            componentObject,
            pushTarget,
            screen_id,
            comptype
          );
          evt.target.closest(".fielddrag").classList.remove("mystyle");
        } else {
          alert("Not able to drop this component to child ");
          return false;
        }
      }
    }
    evt.currentTarget.classList.remove("dragged-over-builder");
    evt.currentTarget.classList.remove("mystyle");
    evt.preventDefault();
  };

  const onDragLeave = (evt) => {
    /**
     * this is triggered when we drag the component is dragged over the screen and not dropped this
     */
    evt.preventDefault();
    let element = evt.currentTarget;
    let pushTarget = evt.target.closest(".fielddrag")
      ? evt.target.closest(".fielddrag").getAttribute("data-id")
        ? evt.target.closest(".fielddrag").getAttribute("data-id")
        : "new_row"
      : "new_row";

    // Remove the focus backgroundColor class
    if (pushTarget === "new_row") {
      // evt.target.closest(".fielddrag").classList.add("mystyle")
      element.classList.remove("dragged-over-builder");
    } else {
      evt.target.closest(".fielddrag").classList.remove("mystyle");
    }
  };

  const onDragEnd = (evt) => {
    evt.currentTarget.classList.remove("dragged-over-builder");
    if (document.getElementById(evt?.target?.id)?.classList) {
      document.getElementById(evt.target.id).classList.remove("hide");
    }
  };

  const onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
    evt.dataTransfer.effectAllowed = "copyMove";
  };

  const getComponentId = async (index, component, screenId) => {
    let data2 = getPropertiesItems(component, screenId, eleAtrribute);

    authData.setAuth({
      ...authData.user,
      componentAttr: { ...data2 },
      selectedScreen: screenId,
      // codePanel: codePanel,
    });
    // localStorage.setItem("previewScreen", JSON.stringify(authData.user.screens));
    // props.onClose("right", true);
  };

  const IDM_Get_Data = (val) => {
    let finalComponent = [];
    const childLoop = (val) => {
      val.forEach((e) => {
        if (
          (e?.render && e?.render?.child.length !== 0) ||
          (e?.child && e?.child?.length !== 0)
        ) {
          if (e?.render?.child) {
            childLoop(e?.render?.child);
          } else if (e?.child) {
            childLoop(e?.child);
          }
        }
        let { child, component, id, ...rest } = e;
        if (component && id) {
          finalComponent = [...finalComponent, { component, id }];
        }
      });
    };
    childLoop(val);
    console.log(val, finalComponent);
    return finalComponent;
  };

  const IDM_Construction = (data) => {
    return {
      rep_id: uuidv4().toString(),
      rep_type: props.formData.form,
      rep_name: data.component,
      parent_rep: localStorage.getItem("parentRepId"),
      route_url: "",
      is_active: true,
      unique_id: data.id,
      projectid: "",
      clientid: "",
      // metadataid: localStorage.getItem("metadata_id"),
      metadataid: "ffdfb801-3383-4749-b368-d5f8d2e60083",
      tenantid: "1e4c8e40-a7f8-46de-9ac7-cedde81aa046",
    };
  };

  const IDM_insert = async (idmCheck) => {
    let idm_Component_List = IDM_Get_Data(authData.user.screens);
    let filter_idm_Component_List = [];
    idm_Component_List.forEach((e) =>
      idmCheck.forEach((v) => {
        if (e.id === v.unique_id) {
          filter_idm_Component_List = [...filter_idm_Component_List, e.id];
        }
      })
    );
    console.log(filter_idm_Component_List, idm_Component_List);
    idm_Component_List.forEach(async (e) => {
      if (!filter_idm_Component_List.includes(e.id)) {
        const myHeader = new Headers();
        myHeader.append("content-type", "application/json");
        let Response = await fetch(process.env.REACT_APP_UPSERT_DOCUMENT, {
          method: "post",
          headers: myHeader,
          body: JSON.stringify([
            {
              // db_name: process.env.REACT_APP_DBNAME,
              db_name: "ipmo",
              entity: "IDM_Repository",
              is_metadata: false,
              filter: { unique_id: e.id },
              doc: { ...IDM_Construction(e) },
            },
          ]),
        });
        let Result = await Response.json();
        if (Result.Code === 201) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Updated Successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
            tone: true,
          });
        }
      }
    });
  };

  const SaveFunc = async () => {
    const myHeader = new Headers();
    myHeader.append("content-type", "application/json");
    let Response = await fetch(process.env.REACT_APP_UPSERT_DOCUMENT, {
      method: "post",
      headers: myHeader,
      body: JSON.stringify([
        {
          db_name: process.env.REACT_APP_DBNAME,
          entity: "screen",
          is_metadata: false,
          filter: { _key: authData.user.screens[0]._key },
          doc: { ...authData.user.screens[0] },
        },
      ]),
    });
    let Result = await Response.json();
    if (Result.Code === 201) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.success,
        msg: "Updated Successfully",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
        tone: true,
      });
    }
  };
  const Get_IDM_Details = async (val) => {
    let result = await NetworkCall(
      process.env.REACT_APP_READ_DOCUMENT,
      "POST",
      {
        // db_name: process.env.REACT_APP_DBNAME,
        db_name: "ipmo",
        entity: "IDM_Repository",
        filter: `IDM_Repository.metadataid=='ffdfb801-3383-4749-b368-d5f8d2e60083' ${
          val === "read"
            ? `AND IDM_Repository.rep_name==${props.formData.form}`
            : ""
        } AND IDM_Repository.activestatus == true ${
          val === "existing"
            ? "AND IDM_Repository.parent_rep != ''"
            : "AND IDM_Repository.parent_rep == ''"
        }`,
        // filter: `IDM_Repository.metadataid=='${localStorage.getItem(
        //   "metadata_id"
        // )}' AND IDM_Repository.activestatus == true ${
        //   val === "existing"
        //     ? "AND IDM_Repository.parent_rep != ''"
        //     : "AND IDM_Repository.parent_rep == ''"
        // }`,
        return_fields: "IDM_Repository",
      }
    );

    return result.data.result;
  };
  const First_IDM_Insert = async () => {
    localStorage.setItem("parentRepId", uuidv4().toString());
    await NetworkCall(process.env.REACT_APP_UPSERT_DOCUMENT, "post", [
      {
        // db_name: process.env.REACT_APP_DBNAME,
        db_name: "ipmo",
        entity: "IDM_Repository",
        is_metadata: false,
        doc: {
          rep_id: localStorage.getItem("parentRepId"),
          rep_type: props.formData.form,
          rep_name: props.formData.form,
          parent_rep: "",
          module: "IPMO",
          projectid: "projects/476434031",
          route_url: "",
          is_active: true,
          unique_id: "",
          clientid: "",
          metadataid: "ffdfb801-3383-4749-b368-d5f8d2e60083",
          tenantid: "1e4c8e40-a7f8-46de-9ac7-cedde81aa046",
        },
      },
    ]);
  };
  const saveScreen = async () => {
    let IDM_Read_Data = await Get_IDM_Details("read");
    let IDM_Exixting_Data = await Get_IDM_Details("existing");
    if (IDM_Read_Data.length === 0) {
      First_IDM_Insert();
    } else {
      localStorage.setItem("parentRepId", IDM_Read_Data[0].rep_id);
    }
    IDM_insert(IDM_Exixting_Data);
    //! USED SETTIMEOUT FUNCTION WITH 100 ms DELAY FOR STATE TO GET UPDATED THEN ONLY THE UPDATED SCREENS WILL BE ADDED TO DB
    //! OR ELSE OLD SCREEN DATA WILL BE PUSHED TO DB AGAIN AND AGAIN
    setTimeout(() => SaveFunc(), 100);
  };
  return (
    <>
      <div
        className={classes.root}
        style={{ display: "grid", gridTemplateColumns: "auto 1fr 220px" }}
      >
        <CssBaseline />
        <AppBar color="primary" position="fixed" className={classes.appBar}>
          <Toolbar>
            <Tooltip title="Back">
              <IconButton
                color="inherit"
                edge="start"
                className={classes.menuButton}
                onClick={() => props.setShowForm(false)}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className={classes.title}>
              {props.formData.form}
            </Typography>
            <Tooltip title="screen">
              <IconButton
                aria-label="display more actions"
                edge="end"
                color="inherit"
                onClick={() => setShowSection(false)}
              >
                <WebIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="save">
              <IconButton
                aria-label="display more actions"
                edge="end"
                color="inherit"
                onClick={() => saveScreen()}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="settings">
              <IconButton
                aria-label="display more actions"
                edge="end"
                color="inherit"
                // onClick={() => propertries()}
              >
                <SettingsOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <ComponentsList setShowSection={setShowSection} />
          <div className={classes.drawerContainer}></div>
        </Drawer>
        <main className={`${classes.content} addHeight`} id="main_builder">
          <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
            <div>
              <div
                style={{
                  fontWeight: "normal",
                  height: "100%",
                  display: "grid",
                }}
              >
                {!showSection
                  ? authData?.user?.screens?.map((screen, index) => {
                      return (
                        <ScreenLayout
                          deleteOldScreen={props.deleteOldScreen}
                          selectedAllScreen={
                            authData?.user?.screens?.length > 1 ? true : false
                          }
                          key={screen?.id}
                          previewScreen={false}
                          screen={screen}
                          index={index}
                          zoom={props.zoom}
                          // layoutType={layoutType}
                          draggable={
                            authData?.user?.screens?.length > 1 ? false : true
                          }
                          onDragEnter={onDragEnter}
                          onDragLeave={onDragLeave}
                          onDragEnd={onDragEnd}
                          onDragOver={onDragOver}
                          onDrop={onDrop}
                          rightMenu={state.rightMenu}
                          getComponentId={getComponentId}
                          onClose={onClose}
                        />
                      );
                    })
                  : authData?.user?.section?.map(
                      (screen, index) =>
                        screen.id === authData?.user?.selectedSection && (
                          <ScreenLayout
                            deleteOldScreen={props.deleteOldScreen}
                            selectedAllScreen={
                              authData?.user?.projectTemplates?.length > 1
                                ? true
                                : false
                            }
                            key={screen?.id}
                            previewScreen={false}
                            screen={screen}
                            index={index}
                            zoom={props.zoom}
                            // layoutType={layoutType}
                            draggable={
                              authData?.user?.projectTemplates?.length > 1
                                ? false
                                : true
                            }
                            onDragEnter={onDragEnter}
                            onDragLeave={onDragLeave}
                            onDragEnd={onDragEnd}
                            onDragOver={onDragOver}
                            typeSection={"section"}
                            onDrop={onDrop}
                            rightMenu={state.rightMenu}
                            getComponentId={getComponentId}
                            onClose={onClose}
                          />
                        )
                    )}
              </div>
            </div>
          </div>
        </main>
        {Object.keys(authData?.user?.componentAttr).length !== 0 && (
          <div style={{ width: "100%" }}>
            <Toolbar />
            <UiBuilderPanel
              showSection={showSection ? "section" : "screens"}
              showproperties={
                authData?.user?.componentAttr &&
                Object.keys(authData?.user?.componentAttr).length !== 0
                  ? true
                  : false
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MainForm;
