import React , { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { RequiredField } from "../../common";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { KeyboardDateTimePicker } from '@material-ui/pickers'

const Datepicker = (props) => {
  let {
    inputVariant,
    duration = false,
    size,
    margin,
    format,
    disabled,
    required = false,
    topLabel = "Date picker",
    style,
    isTimeField,
    minDate,
    maxDate,
    minDatevalue,
    maxDatevalue,
    ...other
  } = props;

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [maximumDate , setmaximumDate] = React.useState("")
  const [minimumDate ,  setminimumDate] = React.useState("")

  
  useEffect(() => {
    

    if (minDate == "Custome") {
      
      setminimumDate(minDatevalue)
    }

    else if (minDate == "Current") {
      

      setminimumDate(new Date())
    }

    else if (minDate == "None") {
      
      setminimumDate(undefined)
    }

    if (maxDate == "Custome") {
      
      setmaximumDate(maxDatevalue)
    }

    else if (maxDate == "Current") {
      
      setmaximumDate(new Date())
    }
    else if (maxDate == "None") {
      
      setmaximumDate(undefined)
    }

  }, [minDate, maxDate]);

  

  const handleDateChange = (date) => {
    debugger
    setSelectedDate(date);
  };
  return (
    <Grid item sm={duration ? 12 : 6} {...other}>
      <Grid
        item
        container
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Typography>
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {isTimeField ?
              <KeyboardDateTimePicker
                label={duration ? "From" : ""}
                disabled={disabled}
                margin={margin}
                inputVariant={inputVariant}
                size={size}
                format={`${format} hh:mm a`}
                //format={format}
                value={selectedDate}
                minDate={minimumDate ? minimumDate : undefined}
                maxDate={maximumDate ? maximumDate : undefined}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              :
              <KeyboardDatePicker
                label={duration ? "From" : ""}
                disabled={disabled}
                margin={margin}
                inputVariant={inputVariant}
                size={size}
                format={format}
                value={selectedDate}
                minDate={minimumDate ? minimumDate : undefined}
                maxDate={maximumDate ? maximumDate : undefined}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />

            }


          </MuiPickersUtilsProvider>
        </Grid>
        {duration && (
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {isTimeField ?

                <KeyboardDateTimePicker
                  label={duration ? "To" : ""}
                  disabled={disabled}
                  margin={margin}
                  inputVariant={inputVariant}
                  size={size}
                  format={`${format} hh:mm a`}
                  fullWidth
                  value={selectedDate}
                  minDate={minimumDate ? minimumDate : undefined}
                  maxDate={maximumDate ? maximumDate : undefined}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                /> :

                <KeyboardDatePicker
                  label={duration ? "To" : ""}
                  disabled={disabled}
                  margin={margin}
                  inputVariant={inputVariant}
                  size={size}
                  format={format}
                  fullWidth
                  value={selectedDate}
                  minDate={minimumDate ? minimumDate : undefined}
                maxDate={maximumDate ? maximumDate : undefined}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />

              }
            </MuiPickersUtilsProvider>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Datepicker;
