import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { RequiredField } from "../../common";
const CustomText = (props) => {
  let {
    required = false,
    labelAlignment = "left",
    topLabel = "label",
    children,
    value,
    index,
    style,
    className,
    onChange = () => null,
    Options,
    ...other
  } = props;
  const handleChange = (val) => {
    onChange(val);
  };
  return (
    <Grid className={className} item {...other}>
      <Typography
        style={{
          textAlign: labelAlignment,
          width: "100%",
        }}
      >
        {topLabel} {required && <RequiredField color={"red"} />}
      </Typography>
      <TextField
        value={value}
        {...other}
        fullWidth
        onChange={(e) => handleChange(e.target.value)}
      />
    </Grid>
  );
};

export default CustomText;
