import React from "react";
import isEqual from "react-fast-compare";
import {
  createDocument,
  getAllUIComponents,
  readDocuments,
  readProjectTemplates,
  readProjectVsTools,
  readUIBuilderDoc,
  updateDocument,
} from "./api";
import config from "./config";
import { AuthContext } from "./contexts";
import { localStorageKeys } from "./utils";
class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionRef: [],
      section: [
        // {
        //   type: "component",
        //   component: "SectionRender",
        //   component_type: "qdm",
        //   isLayout: true,
        //   section: true,
        //   name: "SectionRender",
        //   id: "JtAti",
        //   componentId: "functional-components-layout-comp-5",
        //   frameWork: "functional-components-layout",
        //   properties: {
        //     aliasName: "newSample",
        //     id: "q2ITL",
        //     container: true,
        //     direction: "row",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     type: "text",
        //     required: false,
        //     topLabel: "second",
        //     labelAlignment: "left",
        //     xl: 6,
        //     lg: 6,
        //     md: 6,
        //     xs: 12,
        //     sm: 6,
        //   },
        //   child: [
        //     {
        //       type: "component",
        //       component: "CustomText",
        //       component_type: "qdm",
        //       isLayout: true,
        //       name: "CustomText",
        //       id: "p7P2j",
        //       componentId: "functional-components-layout-comp-1",
        //       frameWork: "functional-components-layout",
        //       properties: {
        //         id: "pzP9F",
        //         container: true,
        //         direction: "row",
        //         justifyContent: "center",
        //         alignItems: "center",
        //         type: "text",
        //         required: false,
        //         topLabel: "second",
        //         labelAlignment: "left",
        //         xl: 6,
        //         lg: 6,
        //         md: 6,
        //         xs: 12,
        //         sm: 6,
        //       },
        //     },
        //     {
        //       type: "component",
        //       component: "CustomText",
        //       component_type: "qdm",
        //       isLayout: true,
        //       name: "CustomText",
        //       id: "unTzT",
        //       componentId: "functional-components-layout-comp-1",
        //       frameWork: "functional-components-layout",
        //       properties: {
        //         id: "qSq6X",
        //         container: true,
        //         direction: "row",
        //         justifyContent: "center",
        //         alignItems: "center",
        //         type: "text",
        //         required: false,
        //         topLabel: "second",
        //         labelAlignment: "left",
        //         xl: 6,
        //         lg: 6,
        //         md: 6,
        //         xs: 12,
        //         sm: 6,
        //       },
        //     },
        //     {
        //       type: "component",
        //       component: "CustomText",
        //       component_type: "qdm",
        //       isLayout: true,
        //       name: "CustomText",
        //       id: "lBQOl",
        //       componentId: "functional-components-layout-comp-1",
        //       frameWork: "functional-components-layout",
        //       properties: {
        //         id: "El0w8",
        //         container: true,
        //         direction: "row",
        //         justifyContent: "center",
        //         alignItems: "center",
        //         type: "text",
        //         required: false,
        //         topLabel: "second",
        //         labelAlignment: "left",
        //         xl: 6,
        //         lg: 6,
        //         md: 6,
        //         xs: 12,
        //         sm: 6,
        //       },
        //     },
        //   ],
        // },
      ],
      user: {},
      loading: true,
      isRefreshed: false,
      UIcomponents: [],
      screens: [],
      selectedScreen: null,
      componentLink: true,
      screenLink: true,
      componentId: null,
      componentDecisionId: null,
      componentAttr: {},
      projectTemplates: [],
      customComponents: [],
      templateDialog: {},
      flowGroup: [],
      // isEdited: false,
      undoRedoData: [],
      activeIndex: 0,
      activeGroup: null,
      undoRedo: false,
      AllScreenList: [],
      AllScreenListRef: [],
      storageManagement: {
        sessionStorage: [],
        localStorageKeys: [],
        cookies: [],
      },
      version: 1,
      permissionData: {},
      screensHistory: {},
      projectTemplatesCreated: false,
      customComponentsCreated: false,
      flowGroupCreated: false,
      storageManagementCreated: false,
      screensActiveIndex: {},
    };
  }
  async componentDidMount() {
    this.refreshAPI();
  }
  _setAuthState = (props) => {
    let screensHistory = this.state.screensHistory;
    let screensActiveIndex =
      props?.screensActiveIndex ?? this.state.screensActiveIndex;
    let activeIndex;

    activeIndex = props.screensActiveIndex[props?.screenEdited];
    let currentScreen = JSON.parse(
      JSON.stringify(screensHistory[props?.screenEdited] ?? [])
    );
    let [currentScreenData] = props.AllScreenList.filter(
      (eachScreen) => eachScreen.id === props?.screenEdited
    );
    currentScreen.push(currentScreenData);
    screensHistory = {
      ...screensHistory,
      [props?.screenEdited]: JSON.parse(JSON.stringify(currentScreen)),
    };
    screensActiveIndex = {
      ...screensActiveIndex,
      [props?.screenEdited]: activeIndex + 1,
    };

    this.setState({
      ...props,
      screensHistory: screensHistory,
      screensActiveIndex: screensActiveIndex,
      screenEdited: null,
    });
  };
  refreshAPI = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get("metadata_id");
    localStorage.setItem("main_metadata_id", product);
    let metadataid = localStorage.getItem("formId");
    readProjectVsTools();
    let userInfo = {};

    if (metadataid) {
      // Read UI Builder data

      //If no document is created for this UIBuilder create one
      let AllScreenList = [],
        customComponents = [],
        projectTemplates = [],
        flowGroup = [],
        storageManagement = {
          sessionStorage: [],
          localStorageKeys: [],
          cookies: [],
        };
      //get  screens data
      let screenQuery = [
        {
          entity: "screen",
          filter: {
            metadataid: metadataid,
            // version: uibuilderData?.version ?? 1,
          },
        },
      ];
      const allScreens = await readDocuments(screenQuery, { limit: 100 });

      AllScreenList = [...allScreens];
      let UIcomponents = await getAllUIComponents();
      function getInitialScreen() {
        let result = AllScreenList.find((screen) => screen.type === "Screen");
        return result ? [result] : [];
      }

      //update the state
      this.setState({
        user: {
          ...userInfo,
          name: userInfo?.given_name,
        },
        isRefreshed: true,
        screensMetaData: [],
        loading: false,
        // qpasQueries: qpasQueries,
        UIcomponents: UIcomponents.sort((a, b) =>
          a.componentName.localeCompare(b.componentName)
        ),
        version: 0,
        undoRedoData: [JSON.parse(JSON.stringify(AllScreenList))],
        activeIndex: 0,
        AllScreenList: AllScreenList,
        AllScreenListRef: JSON.parse(
          JSON.stringify(
            AllScreenList.sort(function (a, b) {
              return a.index - b.index;
            })
          )
        ),
        componentAttr: {},
        screens: getInitialScreen(),
        selectedScreen:
          getInitialScreen().length > 0 ? getInitialScreen()[0].id : null,
        projectTemplates: projectTemplates,
        projectTemplatesRef: JSON.parse(JSON.stringify(projectTemplates)),
        customComponents: customComponents,
        customComponentsRef: JSON.parse(JSON.stringify(customComponents)),
        flowGroup: flowGroup ?? [],
        flowGroupRef: JSON.parse(JSON.stringify(flowGroup)),
        storageManagement: storageManagement,
        storageManagementRef: JSON.parse(JSON.stringify(storageManagement)),
        screensHistory: JSON.parse(JSON.stringify(AllScreenList)).reduce(
          (allScreens, currScreen) => {
            return {
              ...allScreens,
              [currScreen.id]: [{ ...currScreen }],
            };
          },
          {}
        ),
        screensActiveIndex: JSON.parse(JSON.stringify(AllScreenList)).reduce(
          (allScreens, currScreen) => {
            return {
              ...allScreens,
              [currScreen.id]: 0,
            };
          },
          {}
        ),
      });
      // localStorage.setItem("previewScreen", JSON.stringify(getInitialScreen()));
    } else {
      // window.location.replace(config.qdm_admin_url);
    }
  };

  // auto save metaJSON Data's
  _update = async (uiflow) => {
    let metaDataId = localStorage.metaDataId;
    //find what have changed
    let changes = [
      {
        type: "screens",
        ops: [
          {
            type: "updated",
            payload: [],
          },
          {
            type: "deleted",
            payload: [],
          },
        ],
      },
      {
        type: "projectTemplates",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
      {
        type: "customComponents",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
      {
        type: "flowGroup",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
      {
        type: "storageManagement",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
    ];

    //screen - deleted, updated
    let allScreenListRef = this.state.AllScreenListRef;
    //deleted screens
    allScreenListRef.forEach(({ id: id1 }) => {
      if (!this.state.AllScreenList.some(({ id: id2 }) => id2 === id1)) {
        changes.forEach((eachType) => {
          if (eachType.type === "screens") {
            eachType.ops.forEach((op) => {
              if (op.type === "deleted") {
                op.payload.push(id1);
              }
            });
          }
        });
      }
    });
    this.state.AllScreenList.forEach((screen) => {
      //created & updated screens
      let foundScreen = allScreenListRef.find(
        (eachScreen) => eachScreen.id === screen.id
      );
      let isEqualOrNot = isEqual(foundScreen, screen);
      if (!isEqualOrNot) {
        changes.forEach((eachType) => {
          if (eachType.type === "screens") {
            eachType.ops.forEach((op) => {
              if (op.type === "updated") {
                op.payload.push(screen);
              }
            });
          }
        });
      }
    });
    //projectTemplates is not equal- create or update it
    let projectTemplatesRef = this.state.projectTemplatesRef;
    let pTStatus = isEqual(
      this.state.projectTemplates ?? [],
      projectTemplatesRef ?? []
    );

    if (!pTStatus) {
      if (this.state.projectTemplatesCreated) {
        changes.forEach((eachType) => {
          if (eachType.type === "projectTemplates") {
            eachType.ops.forEach((op) => {
              if (op.type === "updated") {
                op.payload = true;
              }
            });
          }
        });
      } else {
        changes.forEach((eachType) => {
          if (eachType.type === "projectTemplates") {
            eachType.ops.forEach((op) => {
              if (op.type === "created") {
                op.payload = true;
              }
            });
          }
        });
      }
    }

    // the network call
    let allPromises = [];
    changes.forEach((eachType) => {
      switch (eachType.type) {
        case "projectTemplates":
          eachType.ops.forEach(async (op) => {
            if (op.type === "created" && op.payload) {
              let projectTemplatesSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                templateGroups: this.state.projectTemplates,
                version: this.state.version,
              };

              let pTCreateRequest = createDocument([
                {
                  entity: "project_templates",
                  body: projectTemplatesSchema,
                },
              ]);
              this.setState({
                projectTemplatesCreated: true,
              });
              allPromises.push(pTCreateRequest);
            } else if (op.type === "updated" && op.payload) {
              let projectTemplatesSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                templateGroups: this.state.projectTemplates,
                version: this.state.version,
              };
              let pTUpdateRequest = updateDocument([
                {
                  entity: "project_templates",
                  body: projectTemplatesSchema,
                  filter: {
                    metadataId: metaDataId,
                    version: this.state.version,
                  },
                },
              ]);
              allPromises.push(pTUpdateRequest);
            }
          });
          break;

        default:
          break;
      }
    });

    Promise.all(allPromises)
      .then(async (result) => {
        //increase version & update refvalues
        this.setState({
          AllScreenListRef: JSON.parse(
            JSON.stringify(this.state.AllScreenList)
          ),
          projectTemplatesRef: JSON.parse(
            JSON.stringify(this.state.projectTemplates)
          ),
        });
      })
      .catch((err) => {
        console.error("Failed to do the operations");
        console.error(err);
      });
  };

  render() {
    return (
      <>
        {
          <AuthContext.Provider
            value={{
              user: this.state,
              setAuth: this._setAuthState,
              save: this._update,
              refresh: this.refreshAPI,
            }}
          >
            {this.props.children}
          </AuthContext.Provider>
        }
      </>
    );
  }
}

export default AppAuth;
