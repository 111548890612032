import React from "react";
import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";
import AppDrawer from "./App.drawer";
import { CssBaseline } from "@material-ui/core";
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const queryClient = new QueryClient();
const App = () => {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <AppErrorBoundary>
          <AppAuth>
            <AppTheme>
              <CssBaseline />
              <AppAlert>
                <AppDialog>
                  <AppDrawer>
                    <AppBackdrop>
                      <RouterApp />
                    </AppBackdrop>
                  </AppDrawer>
                </AppDialog>
              </AppAlert>
            </AppTheme>
          </AppAuth>
        </AppErrorBoundary>
        <ToastContainer theme="colored" />
      </QueryClientProvider>
    </div>
  );
};
export default App;
