import { Routes } from "../router/routes";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import FastfoodRoundedIcon from "@material-ui/icons/FastfoodRounded";
import HowToRegRoundedIcon from "@material-ui/icons/HowToRegRounded";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { MdOutlineFoodBank } from "react-icons/md";
import HomeIcon from "@material-ui/icons/Home";
import { NetworkCall } from "../networkcall";
/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export const localStorageKeys = {
  auth_token: "auth_token",
  version: "version",
  projectName: "projectName",
  metaDataId: "metaDataId",
  UidesignerModelerid: "UidesignerModelerid",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

/* This list page with icon (icon can't be stored in session or db) */
export let repolist = [
  {
    id: 0,
    name: "Home",
    repo: "Home",
    icon: <HomeIcon fontSize={"small"} />,
    routePath: Routes.home,
  },
  {
    id: 1,
    name: "Organization",
    repo: "Organization",
    icon: <ApartmentIcon fontSize={"small"} />,
    routePath: Routes.Organization,
  },
  {
    id: 2,
    name: "Permission Management",
    repo: "User Management",
    icon: <AccountBalanceOutlinedIcon fontSize={"small"} />,
    routePath: Routes.repositry,
  },
  {
    id: 3,
    name: "Task And Document",
    repo: "Task And Document",
    icon: <AssignmentTurnedInOutlinedIcon fontSize={"small"} />,
    routePath: Routes.taskdocument,
  },
  {
    id: 4,
    name: "Report Viewer",
    repo: "Report Viewer",
    icon: <AssessmentOutlinedIcon fontSize={"small"} />,
    routePath: Routes.reportConfig,
  },
  {
    id: 6,
    name: "Order Catalog",
    repo: "Order Menu",
    icon: <FastfoodRoundedIcon fontSize={"small"} />,
    routePath: Routes.menu,
  },
  {
    id: 7,
    name: "General Masters",
    repo: "General Master",
    icon: <DynamicFeedIcon fontSize={"small"} />,
    routePath: Routes.generalMaster,
  },
  {
    id: 8,
    name: "Visit Registration",
    repo: "Patient Check-in",
    icon: <HowToRegRoundedIcon fontSize={"small"} />,
    routePath: Routes.patientRegistration,
  },
  {
    id: 9,
    name: "Task Viewer",
    repo: "Order Viewer",
    icon: <AssignmentTurnedInIcon fontSize={"small"} />,
    routePath: Routes.taskViewer,
  },
  {
    id: 10,
    name: "Order Configurator",
    repo: "Order Configurator",
    icon: <MdOutlineFoodBank size={"1.3rem"} />,
    routePath: Routes.orderConfigurator,
  },
  {
    id: 11,
    name: "Menu Ordering",
    repo: "Kitchen Ordering",
    icon: <FastfoodRoundedIcon fontSize={"small"} />,
    routePath: Routes.kitchenMenu,
  },
];

/* Responsive Resolution sizes of devices */
export const size = {
  mobileXS: "280px",
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

/*max and min Resolution sizes of devices */
export const device = {
  mobileXS: `(min-width: ${size.mobileXS})`,
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const customStyle = {
  Table: {
    minHeight: "70px",
    maxHeight: "auto",
  },
  TableHead: {
    minHeight: "70px",
    maxHeight: "auto",
  },
};
export const country=[ 
  {name: 'Afghanistan', code: 'AF'},
  {name: 'Åland Islands', code: 'AX'}, 
  {name: 'Albania', code: 'AL'}, 
  {name: 'Algeria', code: 'DZ'}, 
  {name: 'American Samoa', code: 'AS'}, 
  {name: 'AndorrA', code: 'AD'}, 
  {name: 'Angola', code: 'AO'}, 
  {name: 'Anguilla', code: 'AI'}, 
  {name: 'Antarctica', code: 'AQ'}, 
  {name: 'Antigua and Barbuda', code: 'AG'}, 
  {name: 'Argentina', code: 'AR'}, 
  {name: 'Armenia', code: 'AM'}, 
  {name: 'Aruba', code: 'AW'}, 
  {name: 'Australia', code: 'AU'}, 
  {name: 'Austria', code: 'AT'}, 
  {name: 'Azerbaijan', code: 'AZ'}, 
  {name: 'Bahamas', code: 'BS'}, 
  {name: 'Bahrain', code: 'BH'}, 
  {name: 'Bangladesh', code: 'BD'}, 
  {name: 'Barbados', code: 'BB'}, 
  {name: 'Belarus', code: 'BY'}, 
  {name: 'Belgium', code: 'BE'}, 
  {name: 'Belize', code: 'BZ'}, 
  {name: 'Benin', code: 'BJ'}, 
  {name: 'Bermuda', code: 'BM'}, 
  {name: 'Bhutan', code: 'BT'}, 
  {name: 'Bolivia', code: 'BO'}, 
  {name: 'Bosnia and Herzegovina', code: 'BA'}, 
  {name: 'Botswana', code: 'BW'}, 
  {name: 'Bouvet Island', code: 'BV'}, 
  {name: 'Brazil', code: 'BR'}, 
  {name: 'British Indian Ocean Territory', code: 'IO'}, 
  {name: 'Brunei Darussalam', code: 'BN'}, 
  {name: 'Bulgaria', code: 'BG'}, 
  {name: 'Burkina Faso', code: 'BF'}, 
  {name: 'Burundi', code: 'BI'}, 
  {name: 'Cambodia', code: 'KH'}, 
  {name: 'Cameroon', code: 'CM'}, 
  {name: 'Canada', code: 'CA'}, 
  {name: 'Cape Verde', code: 'CV'}, 
  {name: 'Cayman Islands', code: 'KY'}, 
  {name: 'Central African Republic', code: 'CF'}, 
  {name: 'Chad', code: 'TD'}, 
  {name: 'Chile', code: 'CL'}, 
  {name: 'China', code: 'CN'}, 
  {name: 'Christmas Island', code: 'CX'}, 
  {name: 'Cocos (Keeling) Islands', code: 'CCK'}, 
  {name: 'Colombia', code: 'CO'}, 
  {name: 'Comoros', code: 'KM'}, 
  {name: 'Congo', code: 'CG'}, 
  {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
  {name: 'Cook Islands', code: 'CK'}, 
  {name: 'Costa Rica', code: 'CR'}, 
  {name: 'Cote D\'Ivoire', code: 'CI'}, 
  {name: 'Croatia', code: 'HR'}, 
  {name: 'Cuba', code: 'CU'}, 
  {name: 'Cyprus', code: 'CY'}, 
  {name: 'Czech Republic', code: 'CZ'}, 
  {name: 'Denmark', code: 'DK'}, 
  {name: 'Djibouti', code: 'DJ'}, 
  {name: 'Dominica', code: 'DM'}, 
  {name: 'Dominican Republic', code: 'DO'}, 
  {name: 'Ecuador', code: 'EC'}, 
  {name: 'Egypt', code: 'EG'}, 
  {name: 'El Salvador', code: 'SV'}, 
  {name: 'Equatorial Guinea', code: 'GQ'}, 
  {name: 'Eritrea', code: 'ER'}, 
  {name: 'Estonia', code: 'EE'}, 
  {name: 'Ethiopia', code: 'ET'}, 
  {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
  {name: 'Faroe Islands', code: 'FO'}, 
  {name: 'Fiji', code: 'FJ'}, 
  {name: 'Finland', code: 'FI'}, 
  {name: 'France', code: 'FR'}, 
  {name: 'French Guiana', code: 'GF'}, 
  {name: 'French Polynesia', code: 'PF'}, 
  {name: 'French Southern Territories', code: 'TF'}, 
  {name: 'Gabon', code: 'GA'}, 
  {name: 'Gambia', code: 'GM'}, 
  {name: 'Georgia', code: 'GE'}, 
  {name: 'Germany', code: 'DE'}, 
  {name: 'Ghana', code: 'GH'}, 
  {name: 'Gibraltar', code: 'GI'}, 
  {name: 'Greece', code: 'GR'}, 
  {name: 'Greenland', code: 'GL'}, 
  {name: 'Grenada', code: 'GD'}, 
  {name: 'Guadeloupe', code: 'GP'}, 
  {name: 'Guam', code: 'GU'}, 
  {name: 'Guatemala', code: 'GT'}, 
  {name: 'Guernsey', code: 'GG'}, 
  {name: 'Guinea', code: 'GN'}, 
  {name: 'Guinea-Bissau', code: 'GW'}, 
  {name: 'Guyana', code: 'GY'}, 
  {name: 'Haiti', code: 'HT'}, 
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
  {name: 'Holy See (Vatican City State)', code: 'VA'}, 
  {name: 'Honduras', code: 'HN'}, 
  {name: 'Hong Kong', code: 'HK'}, 
  {name: 'Hungary', code: 'HU'}, 
  {name: 'Iceland', code: 'IS'}, 
  {name: 'India', code: 'IN'}, 
  {name: 'Indonesia', code: 'ID'}, 
  {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
  {name: 'Iraq', code: 'IQ'}, 
  {name: 'Ireland', code: 'IE'}, 
  {name: 'Isle of Man', code: 'IM'}, 
  {name: 'Israel', code: 'IL'}, 
  {name: 'Italy', code: 'IT'}, 
  {name: 'Jamaica', code: 'JM'}, 
  {name: 'Japan', code: 'JP'}, 
  {name: 'Jersey', code: 'JE'}, 
  {name: 'Jordan', code: 'JO'}, 
  {name: 'Kazakhstan', code: 'KZ'}, 
  {name: 'Kenya', code: 'KE'}, 
  {name: 'Kiribati', code: 'KI'}, 
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
  {name: 'Korea, Republic of', code: 'KR'}, 
  {name: 'Kuwait', code: 'KW'}, 
  {name: 'Kyrgyzstan', code: 'KG'}, 
  {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
  {name: 'Latvia', code: 'LV'}, 
  {name: 'Lebanon', code: 'LB'}, 
  {name: 'Lesotho', code: 'LS'}, 
  {name: 'Liberia', code: 'LR'}, 
  {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
  {name: 'Liechtenstein', code: 'LI'}, 
  {name: 'Lithuania', code: 'LT'}, 
  {name: 'Luxembourg', code: 'LU'}, 
  {name: 'Macao', code: 'MO'}, 
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
  {name: 'Madagascar', code: 'MG'}, 
  {name: 'Malawi', code: 'MW'}, 
  {name: 'Malaysia', code: 'MY'}, 
  {name: 'Maldives', code: 'MV'}, 
  {name: 'Mali', code: 'ML'}, 
  {name: 'Malta', code: 'MT'}, 
  {name: 'Marshall Islands', code: 'MH'}, 
  {name: 'Martinique', code: 'MQ'}, 
  {name: 'Mauritania', code: 'MR'}, 
  {name: 'Mauritius', code: 'MU'}, 
  {name: 'Mayotte', code: 'YT'}, 
  {name: 'Mexico', code: 'MX'}, 
  {name: 'Micronesia, Federated States of', code: 'FM'}, 
  {name: 'Moldova, Republic of', code: 'MD'}, 
  {name: 'Monaco', code: 'MC'}, 
  {name: 'Mongolia', code: 'MN'}, 
  {name: 'Montserrat', code: 'MS'}, 
  {name: 'Morocco', code: 'MA'}, 
  {name: 'Mozambique', code: 'MZ'}, 
  {name: 'Myanmar', code: 'MM'}, 
  {name: 'Namibia', code: 'NA'}, 
  {name: 'Nauru', code: 'NR'}, 
  {name: 'Nepal', code: 'NP'}, 
  {name: 'Netherlands', code: 'NL'}, 
  {name: 'Netherlands Antilles', code: 'AN'}, 
  {name: 'New Caledonia', code: 'NC'}, 
  {name: 'New Zealand', code: 'NZ'}, 
  {name: 'Nicaragua', code: 'NI'}, 
  {name: 'Niger', code: 'NE'}, 
  {name: 'Nigeria', code: 'NG'}, 
  {name: 'Niue', code: 'NU'}, 
  {name: 'Norfolk Island', code: 'NF'}, 
  {name: 'Northern Mariana Islands', code: 'MP'}, 
  {name: 'Norway', code: 'NO'}, 
  {name: 'Oman', code: 'OM'}, 
  {name: 'Pakistan', code: 'PK'}, 
  {name: 'Palau', code: 'PW'}, 
  {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
  {name: 'Panama', code: 'PA'}, 
  {name: 'Papua New Guinea', code: 'PG'}, 
  {name: 'Paraguay', code: 'PY'}, 
  {name: 'Peru', code: 'PE'}, 
  {name: 'Philippines', code: 'PH'}, 
  {name: 'Pitcairn', code: 'PN'}, 
  {name: 'Poland', code: 'PL'}, 
  {name: 'Portugal', code: 'PT'}, 
  {name: 'Puerto Rico', code: 'PR'}, 
  {name: 'Qatar', code: 'QA'}, 
  {name: 'Reunion', code: 'RE'}, 
  {name: 'Romania', code: 'RO'}, 
  {name: 'Russian Federation', code: 'RU'}, 
  {name: 'RWANDA', code: 'RW'}, 
  {name: 'Saint Helena', code: 'SH'}, 
  {name: 'Saint Kitts and Nevis', code: 'KN'}, 
  {name: 'Saint Lucia', code: 'LC'}, 
  {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
  {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
  {name: 'Samoa', code: 'WS'}, 
  {name: 'San Marino', code: 'SM'}, 
  {name: 'Sao Tome and Principe', code: 'ST'}, 
  {name: 'Saudi Arabia', code: 'SA'}, 
  {name: 'Senegal', code: 'SN'}, 
  {name: 'Serbia and Montenegro', code: 'CS'}, 
  {name: 'Seychelles', code: 'SC'}, 
  {name: 'Sierra Leone', code: 'SL'}, 
  {name: 'Singapore', code: 'SG'}, 
  {name: 'Slovakia', code: 'SK'}, 
  {name: 'Slovenia', code: 'SI'}, 
  {name: 'Solomon Islands', code: 'SB'}, 
  {name: 'Somalia', code: 'SO'}, 
  {name: 'South Africa', code: 'ZA'}, 
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
  {name: 'Spain', code: 'ES'}, 
  {name: 'Sri Lanka', code: 'LK'}, 
  {name: 'Sudan', code: 'SD'}, 
  {name: 'Suriname', code: 'SR'}, 
  {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
  {name: 'Swaziland', code: 'SZ'}, 
  {name: 'Sweden', code: 'SE'}, 
  {name: 'Switzerland', code: 'CH'}, 
  {name: 'Syrian Arab Republic', code: 'SY'}, 
  {name: 'Taiwan, Province of China', code: 'TW'}, 
  {name: 'Tajikistan', code: 'TJ'}, 
  {name: 'Tanzania, United Republic of', code: 'TZ'}, 
  {name: 'Thailand', code: 'TH'}, 
  {name: 'Timor-Leste', code: 'TL'}, 
  {name: 'Togo', code: 'TG'}, 
  {name: 'Tokelau', code: 'TK'}, 
  {name: 'Tonga', code: 'TO'}, 
  {name: 'Trinidad and Tobago', code: 'TT'}, 
  {name: 'Tunisia', code: 'TN'}, 
  {name: 'Turkey', code: 'TR'}, 
  {name: 'Turkmenistan', code: 'TM'}, 
  {name: 'Turks and Caicos Islands', code: 'TC'}, 
  {name: 'Tuvalu', code: 'TV'}, 
  {name: 'Uganda', code: 'UG'}, 
  {name: 'Ukraine', code: 'UA'}, 
  {name: 'United Arab Emirates', code: 'AE'}, 
  {name: 'United Kingdom', code: 'GB'}, 
  {name: 'United States', code: 'US'}, 
  {name: 'United States Minor Outlying Islands', code: 'UM'}, 
  {name: 'Uruguay', code: 'UY'}, 
  {name: 'Uzbekistan', code: 'UZ'}, 
  {name: 'Vanuatu', code: 'VU'}, 
  {name: 'Venezuela', code: 'VE'}, 
  {name: 'Viet Nam', code: 'VN'}, 
  {name: 'Virgin Islands, British', code: 'VG'}, 
  {name: 'Virgin Islands, U.S.', code: 'VI'}, 
  {name: 'Wallis and Futuna', code: 'WF'}, 
  {name: 'Western Sahara', code: 'EH'}, 
  {name: 'Yemen', code: 'YE'}, 
  {name: 'Zambia', code: 'ZM'}, 
  {name: 'Zimbabwe', code: 'ZW'} 
]

export const languageCodes = {
  Amharic: "am",
  Arabic: "ar",
  Bangla: "bn",
  Belarusian: "be",
  Bulgarian: "bg",
  ChineseHongKong: "yue-hant",
  ChineseSimplified: "zh",
  ChineseTraditional: "zh-hant",
  French: "fr",
  German: "de",
  Greek: "el",
  Gujarati: "gu",
  Hebrew: "he",
  Hindi: "hi",
  Italian: "it",
  Japanese: "ja",
  Kannada: "kn",
  Malayalam: "ml",
  Marathi: "mr",
  Nepali: "ne",
  Odia: "or",
  Persian: "fa",
  PortugueseBrazil: "pt",
  Punjabi: "pa",
  Russian: "ru",
  Sanskrit: "sa",
  Serbian: "sr",
  Sinhala: "si",
  Spanish: "es",
  Tamil: "ta",
  Telugu: "te",
  Tigrinya: "ti",
  Ukrainian: "uk",
  Urdu: "ur",
  Vietnamese: "vi",
};

export const iconsList = [
  "ac_unit",
  "access_alarm",
  "access_alarms",
  "access_time",
  "accessibility",
  "accessibility_new",
  "accessible",
  "accessible_forward",
  "account_balance",
  "account_balance_wallet",
  "account_box",
  "account_circle",
  "adb",
  "add",
  "add_a_photo",
  "add_alarm",
  "add_alert",
  "add_box",
  "add_circle",
  "add_circle_outline",
  "add_comment",
  "add_location",
  "add_photo_alternate",
  "add_shopping_cart",
  "add_to_home_screen",
  "add_to_photos",
  "add_to_queue",
  "adjust",
  "airline_seat_flat",
  "airline_seat_flat_angled",
  "airline_seat_individual_suite",
  "airline_seat_legroom_extra",
  "airline_seat_legroom_normal",
  "airline_seat_legroom_reduced",
  "airline_seat_recline_extra",
  "airline_seat_recline_normal",
  "airplanemode_active",
  "airplanemode_inactive",
  "airplay",
  "airport_shuttle",
  "alarm",
  "alarm_add",
  "alarm_off",
  "alarm_on",
  "album",
  "all_inbox",
  "all_inclusive",
  "all_out",
  "alternate_email",
  "android",
  "announcement",
  "apps",
  "archive",
  "arrow_back",
  "arrow_back_ios",
  "arrow_downward",
  "arrow_drop_down",
  "arrow_drop_down_circle",
  "arrow_drop_up",
  "arrow_forward",
  "arrow_forward_ios",
  "arrow_left",
  "arrow_right",
  "arrow_right_alt",
  "arrow_upward",
  "art_track",
  "aspect_ratio",
  "assessment",
  "assignment",
  "assignment_ind",
  "assignment_late",
  "assignment_return",
  "assignment_returned",
  "assignment_turned_in",
  "assistant",
  "assistant_photo",
  "atm",
  "attach_file",
  "attach_money",
  "attachment",
  "audiotrack",
  "autorenew",
  "av_timer",
  "backspace",
  "backup",
  "ballot",
  "bar_chart",
  "battery_20",
  "battery_30",
  "battery_50",
  "battery_60",
  "battery_80",
  "battery_90",
  "battery_alert",
  "battery_charging_20",
  "battery_charging_30",
  "battery_charging_50",
  "battery_charging_60",
  "battery_charging_80",
  "battery_charging_90",
  "battery_charging_full",
  "battery_full",
  "battery_std",
  "battery_unknown",
  "beach_access",
  "beenhere",
  "block",
  "bluetooth",
  "bluetooth_audio",
  "bluetooth_connected",
  "bluetooth_disabled",
  "bluetooth_searching",
  "blur_circular",
  "blur_linear",
  "blur_off",
  "blur_on",
  "book",
  "bookmark",
  "bookmark_border",
  "bookmarks",
  "border_all",
  "border_bottom",
  "border_clear",
  "border_color",
  "border_horizontal",
  "border_inner",
  "border_left",
  "border_outer",
  "border_right",
  "border_style",
  "border_top",
  "border_vertical",
  "branding_watermark",
  "brightness_1",
  "brightness_2",
  "brightness_3",
  "brightness_4",
  "brightness_5",
  "brightness_6",
  "brightness_7",
  "brightness_auto",
  "brightness_high",
  "brightness_low",
  "brightness_medium",
  "broken_image",
  "brush",
  "bubble_chart",
  "bug_report",
  "build",
  "burst_mode",
  "business",
  "business_center",
  "cached",
  "cake",
  "calendar_today",
  "calendar_view_day",
  "call",
  "call_end",
  "call_made",
  "call_merge",
  "call_missed",
  "call_missed_outgoing",
  "call_received",
  "call_split",
  "call_to_action",
  "camera",
  "camera_alt",
  "camera_enhance",
  "camera_front",
  "camera_rear",
  "camera_roll",
  "cancel",
  "cancel_presentation",
  "card_giftcard",
  "card_membership",
  "card_travel",
  "casino",
  "cast",
  "cast_connected",
  "cast_for_education",
  "category",
  "cell_wifi",
  "center_focus_strong",
  "center_focus_weak",
  "change_history",
  "chat",
  "chat_bubble",
  "chat_bubble_outline",
  "check",
  "check_box",
  "check_box_outline_blank",
  "check_circle",
  "check_circle_outline",
  "chevron_left",
  "chevron_right",
  "child_care",
  "child_friendly",
  "chrome_reader_mode",
  "class",
  "clear",
  "clear_all",
  "close",
  "closed_caption",
  "cloud",
  "cloud_circle",
  "cloud_done",
  "cloud_download",
  "cloud_off",
  "cloud_queue",
  "cloud_upload",
  "code",
  "collections",
  "collections_bookmark",
  "color_lens",
  "colorize",
  "comment",
  "commute",
  "compare",
  "compare_arrows",
  "compass_calibration",
  "computer",
  "confirmation_number",
  "contact_mail",
  "contact_phone",
  "contact_support",
  "contacts",
  "control_camera",
  "control_point",
  "control_point_duplicate",
  "copyright",
  "create",
  "create_new_folder",
  "credit_card",
  "crop",
  "crop_16_9",
  "crop_3_2",
  "crop_5_4",
  "crop_7_5",
  "crop_din",
  "crop_free",
  "crop_landscape",
  "crop_original",
  "crop_portrait",
  "crop_rotate",
  "crop_square",
  "dashboard",
  "data_usage",
  "date_range",
  "dehaze",
  "delete",
  "delete_forever",
  "delete_outline",
  "delete_sweep",
  "departure_board",
  "description",
  "desktop_access_disabled",
  "desktop_mac",
  "desktop_windows",
  "details",
  "developer_board",
  "developer_mode",
  "device_hub",
  "device_unknown",
  "devices",
  "devices_other",
  "dialer_sip",
  "dialpad",
  "directions",
  "directions_bike",
  "directions_boat",
  "directions_bus",
  "directions_car",
  "directions_railway",
  "directions_run",
  "directions_subway",
  "directions_transit",
  "directions_walk",
  "disc_full",
  "dns",
  "dock",
  "domain",
  "domain_disabled",
  "done",
  "done_all",
  "done_outline",
  "donut_large",
  "donut_small",
  "drafts",
  "drag_handle",
  "drag_indicator",
  "drive_eta",
  "duo",
  "dvr",
  "edit",
  "edit_attributes",
  "edit_location",
  "eject",
  "email",
  "enhanced_encryption",
  "equalizer",
  "error",
  "error_outline",
  "euro_symbol",
  "ev_station",
  "event",
  "event_available",
  "event_busy",
  "event_note",
  "event_seat",
  "exit_to_app",
  "expand_less",
  "expand_more",
  "explicit",
  "explore",
  "explore_off",
  "exposure",
  "exposure_neg_1",
  "exposure_neg_2",
  "exposure_plus_1",
  "exposure_plus_2",
  "exposure_zero",
  "extension",
  "face",
  "fast_forward",
  "fast_rewind",
  "fastfood",
  "favorite",
  "favorite_border",
  "featured_play_list",
  "featured_video",
  "feedback",
  "fiber_dvr",
  "fiber_manual_record",
  "fiber_new",
  "fiber_pin",
  "fiber_smart_record",
  "file_copy",
  "filter",
  "filter_1",
  "filter_2",
  "filter_3",
  "filter_4",
  "filter_5",
  "filter_6",
  "filter_7",
  "filter_8",
  "filter_9",
  "filter_9_plus",
  "filter_b_and_w",
  "filter_center_focus",
  "filter_drama",
  "filter_frames",
  "filter_hdr",
  "filter_list",
  "filter_none",
  "filter_tilt_shift",
  "filter_vintage",
  "find_in_page",
  "find_replace",
  "fingerprint",
  "first_page",
  "fitness_center",
  "flag",
  "flare",
  "flash_auto",
  "flash_off",
  "flash_on",
  "flight",
  "flight_land",
  "flight_takeoff",
  "flip",
  "flip_to_back",
  "flip_to_front",
  "folder",
  "folder_open",
  "folder_shared",
  "folder_special",
  "font_download",
  "format_align_center",
  "format_align_justify",
  "format_align_left",
  "format_align_right",
  "format_bold",
  "format_clear",
  "format_color_fill",
  "format_color_reset",
  "format_color_text",
  "format_indent_decrease",
  "format_indent_increase",
  "format_italic",
  "format_line_spacing",
  "format_list_bulleted",
  "format_list_numbered",
  "format_list_numbered_rtl",
  "format_paint",
  "format_quote",
  "format_shapes",
  "format_size",
  "format_strikethrough",
  "format_textdirection_l_to_r",
  "format_textdirection_r_to_l",
  "format_underlined",
  "forum",
  "forward",
  "forward_10",
  "forward_30",
  "forward_5",
  "four_k",
  "free_breakfast",
  "fullscreen",
  "fullscreen_exit",
  "functions",
  "g_translate",
  "gamepad",
  "games",
  "gavel",
  "gesture",
  "get_app",
  "gif",
  "golf_course",
  "gps_fixed",
  "gps_not_fixed",
  "gps_off",
  "grade",
  "gradient",
  "grain",
  "graphic_eq",
  "grid_off",
  "grid_on",
  "group",
  "group_add",
  "group_work",
  "hd",
  "hdr_off",
  "hdr_on",
  "hdr_strong",
  "hdr_weak",
  "headset",
  "headset_mic",
  "healing",
  "hearing",
  "help",
  "help_outline",
  "high_quality",
  "highlight",
  "highlight_off",
  "history",
  "home",
  "horizontal_split",
  "hot_tub",
  "hotel",
  "hourglass_empty",
  "hourglass_full",
  "how_to_reg",
  "how_to_vote",
  "http",
  "https",
  "image",
  "image_aspect_ratio",
  "image_search",
  "import_contacts",
  "import_export",
  "important_devices",
  "inbox",
  "indeterminate_check_box",
  "info",
  "input",
  "insert_chart",
  "insert_chart_outlined",
  "insert_comment",
  "insert_drive_file",
  "insert_emoticon",
  "insert_invitation",
  "insert_link",
  "insert_photo",
  "invert_colors",
  "invert_colors_off",
  "iso",
  "keyboard",
  "keyboard_arrow_down",
  "keyboard_arrow_left",
  "keyboard_arrow_right",
  "keyboard_arrow_up",
  "keyboard_backspace",
  "keyboard_capslock",
  "keyboard_hide",
  "keyboard_return",
  "keyboard_tab",
  "keyboard_voice",
  "kitchen",
  "label",
  "label_important",
  "label_off",
  "landscape",
  "language",
  "laptop",
  "laptop_chromebook",
  "laptop_mac",
  "laptop_windows",
  "last_page",
  "launch",
  "layers",
  "layers_clear",
  "leak_add",
  "leak_remove",
  "lens",
  "library_add",
  "library_books",
  "library_music",
  "line_style",
  "line_weight",
  "linear_scale",
  "link",
  "link_off",
  "linked_camera",
  "list",
  "list_alt",
  "live_help",
  "live_tv",
  "local_activity",
  "local_airport",
  "local_atm",
  "local_bar",
  "local_cafe",
  "local_car_wash",
  "local_convenience_store",
  "local_dining",
  "local_drink",
  "local_florist",
  "local_gas_station",
  "local_grocery_store",
  "local_hospital",
  "local_hotel",
  "local_laundry_service",
  "local_library",
  "local_mall",
  "local_movies",
  "local_offer",
  "local_parking",
  "local_pharmacy",
  "local_phone",
  "local_pizza",
  "local_play",
  "local_post_office",
  "local_printshop",
  "local_see",
  "local_shipping",
  "local_taxi",
  "location_city",
  "location_disabled",
  "location_off",
  "location_on",
  "location_searching",
  "lock",
  "lock_open",
  "looks",
  "looks_3",
  "looks_4",
  "looks_5",
  "looks_6",
  "looks_one",
  "looks_two",
  "loop",
  "loupe",
  "low_priority",
  "loyalty",
  "mail",
  "mail_outline",
  "map",
  "markunread",
  "markunread_mailbox",
  "maximize",
  "meeting_room",
  "memory",
  "menu",
  "merge_type",
  "message",
  "mic",
  "mic_none",
  "mic_off",
  "minimize",
  "missed_video_call",
  "mms",
  "mobile_friendly",
  "mobile_off",
  "mobile_screen_share",
  "mode_comment",
  "monetization_on",
  "money",
  "money_off",
  "monochrome_photos",
  "mood",
  "mood_bad",
  "more",
  "more_horiz",
  "more_vert",
  "motorcycle",
  "mouse",
  "move_to_inbox",
  "movie",
  "movie_creation",
  "movie_filter",
  "multiline_chart",
  "music_note",
  "music_off",
  "music_video",
  "my_location",
  "nature",
  "nature_people",
  "navigate_before",
  "navigate_next",
  "navigation",
  "near_me",
  "network_cell",
  "network_check",
  "network_locked",
  "network_wifi",
  "new_releases",
  "next_week",
  "nfc",
  "no_encryption",
  "no_meeting_room",
  "no_sim",
  "not_interested",
  "not_listed_location",
  "note",
  "note_add",
  "notes",
  "notification_important",
  "notifications",
  "notifications_active",
  "notifications_none",
  "notifications_off",
  "notifications_paused",
  "offline_bolt",
  "offline_pin",
  "ondemand_video",
  "opacity",
  "open_in_browser",
  "open_in_new",
  "open_with",
  "outlined_flag",
  "pages",
  "pageview",
  "palette",
  "pan_tool",
  "panorama",
  "panorama_fish_eye",
  "panorama_horizontal",
  "panorama_vertical",
  "panorama_wide_angle",
  "party_mode",
  "pause",
  "pause_circle_filled",
  "pause_circle_outline",
  "pause_presentation",
  "payment",
  "people",
  "people_outline",
  "perm_camera_mic",
  "perm_contact_calendar",
  "perm_data_setting",
  "perm_device_information",
  "perm_identity",
  "perm_media",
  "perm_phone_msg",
  "perm_scan_wifi",
  "person",
  "person_add",
  "person_add_disabled",
  "person_outline",
  "person_pin",
  "person_pin_circle",
  "personal_video",
  "pets",
  "phone",
  "phone_android",
  "phone_bluetooth_speaker",
  "phone_callback",
  "phone_forwarded",
  "phone_in_talk",
  "phone_iphone",
  "phone_locked",
  "phone_missed",
  "phone_paused",
  "phonelink",
  "phonelink_erase",
  "phonelink_lock",
  "phonelink_off",
  "phonelink_ring",
  "phonelink_setup",
  "photo",
  "photo_album",
  "photo_camera",
  "photo_filter",
  "photo_library",
  "photo_size_select_actual",
  "photo_size_select_large",
  "photo_size_select_small",
  "picture_as_pdf",
  "picture_in_picture",
  "picture_in_picture_alt",
  "pie_chart",
  "pin_drop",
  "place",
  "play_arrow",
  "play_circle_filled",
  "play_circle_filled_white",
  "play_circle_outline",
  "play_for_work",
  "playlist_add",
  "playlist_add_check",
  "playlist_play",
  "plus_one",
  "poll",
  "polymer",
  "pool",
  "portable_wifi_off",
  "portrait",
  "power",
  "power_input",
  "power_off",
  "power_settings_new",
  "pregnant_woman",
  "present_to_all",
  "print",
  "print_disabled",
  "priority_high",
  "public",
  "publish",
  "query_builder",
  "question_answer",
  "queue",
  "queue_music",
  "queue_play_next",
  "radio",
  "radio_button_checked",
  "radio_button_unchecked",
  "rate_review",
  "receipt",
  "recent_actors",
  "record_voice_over",
  "redeem",
  "redo",
  "refresh",
  "remove",
  "remove_circle",
  "remove_circle_outline",
  "remove_from_queue",
  "remove_red_eye",
  "remove_shopping_cart",
  "reorder",
  "repeat",
  "repeat_one",
  "replay",
  "replay_10",
  "replay_30",
  "replay_5",
  "reply",
  "reply_all",
  "report",
  "report_off",
  "report_problem",
  "restaurant",
  "restaurant_menu",
  "restore",
  "restore_from_trash",
  "restore_page",
  "ring_volume",
  "room",
  "room_service",
  "rotate_90_degrees_ccw",
  "rotate_left",
  "rotate_right",
  "rounded_corner",
  "router",
  "rowing",
  "rss_feed",
  "rv_hookup",
  "satellite",
  "save",
  "save_alt",
  "scanner",
  "scatter_plot",
  "schedule",
  "school",
  "score",
  "screen_lock_landscape",
  "screen_lock_portrait",
  "screen_lock_rotation",
  "screen_rotation",
  "screen_share",
  "sd_card",
  "sd_storage",
  "search",
  "security",
  "select_all",
  "send",
  "sentiment_dissatisfied",
  "sentiment_satisfied",
  "sentiment_satisfied_alt",
  "sentiment_very_dissatisfied",
  "sentiment_very_satisfied",
  "settings",
  "settings_applications",
  "settings_backup_restore",
  "settings_bluetooth",
  "settings_brightness",
  "settings_cell",
  "settings_ethernet",
  "settings_input_antenna",
  "settings_input_component",
  "settings_input_composite",
  "settings_input_hdmi",
  "settings_input_svideo",
  "settings_overscan",
  "settings_phone",
  "settings_power",
  "settings_remote",
  "settings_system_daydream",
  "settings_voice",
  "share",
  "shop",
  "shop_two",
  "shopping_basket",
  "shopping_cart",
  "short_text",
  "show_chart",
  "shuffle",
  "shutter_speed",
  "signal_cellular_0_bar",
  "signal_cellular_1_bar",
  "signal_cellular_2_bar",
  "signal_cellular_3_bar",
  "signal_cellular_4_bar",
  "signal_cellular_alt",
  "signal_cellular_connected_no_internet_0_bar",
  "signal_cellular_connected_no_internet_1_bar",
  "signal_cellular_connected_no_internet_2_bar",
  "signal_cellular_connected_no_internet_3_bar",
  "signal_cellular_connected_no_internet_4_bar",
  "signal_cellular_no_sim",
  "signal_cellular_null",
  "signal_cellular_off",
  "signal_wifi_0_bar",
  "signal_wifi_1_bar",
  "signal_wifi_1_bar_lock",
  "signal_wifi_2_bar",
  "signal_wifi_2_bar_lock",
  "signal_wifi_3_bar",
  "signal_wifi_3_bar_lock",
  "signal_wifi_4_bar",
  "signal_wifi_4_bar_lock",
  "signal_wifi_off",
  "sim_card",
  "skip_next",
  "skip_previous",
  "slideshow",
  "slow_motion_video",
  "smartphone",
  "smoke_free",
  "smoking_rooms",
  "sms",
  "sms_failed",
  "snooze",
  "sort",
  "sort_by_alpha",
  "spa",
  "space_bar",
  "speaker",
  "speaker_group",
  "speaker_notes",
  "speaker_notes_off",
  "speaker_phone",
  "spellcheck",
  "star",
  "star_border",
  "star_half",
  "star_rate",
  "stars",
  "stay_current_landscape",
  "stay_current_portrait",
  "stay_primary_landscape",
  "stay_primary_portrait",
  "stop",
  "stop_screen_share",
  "storage",
  "store",
  "store_mall_directory",
  "straighten",
  "streetview",
  "strikethrough_s",
  "style",
  "subdirectory_arrow_left",
  "subdirectory_arrow_right",
  "subject",
  "subscriptions",
  "subtitles",
  "subway",
  "supervised_user_circle",
  "supervisor_account",
  "surround_sound",
  "swap_calls",
  "swap_horiz",
  "swap_horizontal_circle",
  "swap_vert",
  "swap_vertical_circle",
  "switch_camera",
  "switch_video",
  "sync",
  "sync_disabled",
  "sync_problem",
  "system_update",
  "tab",
  "tab_unselected",
  "table_chart",
  "tablet",
  "tablet_android",
  "tablet_mac",
  "tag_faces",
  "tap_and_play",
  "terrain",
  "text_fields",
  "text_format",
  "text_rotate_up",
  "text_rotate_vertical",
  "text_rotation_down",
  "text_rotation_none",
  "textsms",
  "texture",
  "theaters",
  "three_sixty",
  "three_d_rotation",
  "thumb_down",
  "thumb_down_alt",
  "thumb_up",
  "thumb_up_alt",
  "thumbs_up_down",
  "time_to_leave",
  "timelapse",
  "timeline",
  "timer",
  "timer_10",
  "timer_3",
  "timer_off",
  "title",
  "toc",
  "today",
  "toggle_off",
  "toggle_on",
  "toll",
  "tonality",
  "touch_app",
  "toys",
  "track_changes",
  "traffic",
  "train",
  "tram",
  "transfer_within_a_station",
  "transform",
  "transit_enterexit",
  "translate",
  "trending_down",
  "trending_flat",
  "trending_up",
  "trip_origin",
  "tune",
  "turned_in",
  "turned_in_not",
  "tv",
  "tv_off",
  "unarchive",
  "undo",
  "unfold_less",
  "unfold_more",
  "unsubscribe",
  "update",
  "usb",
  "verified_user",
  "vertical_align_bottom",
  "vertical_align_center",
  "vertical_align_top",
  "vertical_split",
  "vibration",
  "video_call",
  "video_label",
  "video_library",
  "videocam",
  "videocam_off",
  "videogame_asset",
  "view_agenda",
  "view_array",
  "view_carousel",
  "view_column",
  "view_comfy",
  "view_compact",
  "view_day",
  "view_headline",
  "view_list",
  "view_module",
  "view_quilt",
  "view_stream",
  "view_week",
  "vignette",
  "visibility",
  "visibility_off",
  "voice_chat",
  "voice_over_off",
  "voicemail",
  "volume_down",
  "volume_mute",
  "volume_off",
  "volume_up",
  "vpn_key",
  "vpn_lock",
  "wallpaper",
  "warning",
  "watch",
  "watch_later",
  "waves",
  "wb_auto",
  "wb_cloudy",
  "wb_incandescent",
  "wb_iridescent",
  "wb_sunny",
  "wc",
  "web",
  "web_asset",
  "weekend",
  "whatshot",
  "where_to_vote",
  "widgets",
  "wifi",
  "wifi_lock",
  "wifi_off",
  "wifi_tethering",
  "work",
  "work_off",
  "work_outline",
  "wrap_text",
  "youtube_searched_for",
  "zoom_in",
  "zoom_out",
  "zoom_out_map",
];

export const getProjectPages = (auth) => {
  let pages = [];
  console.log(auth.user.AllScreenList);
  auth.user.AllScreenList.forEach((page) => {
    if (page.type === "Screen" && !page.isLayoutScreen) {
      pages.push({
        name: page.name,
        pageId: page.id,
        page: page.path,
        masterLayoutId: auth.user?.componentAttr.id,
        size: 0,
        icon: "",
        childPageSelect: [],
      });
    }
  });
  return pages;
};

export const getComponents = async () => {
  const res = await NetworkCall(process.env.REACT_APP_READ_DOCUMENT, "post", {
    db_name: process.env.REACT_APP_DBNAME,
    entity: "form_component",
    filter: "form_component.active==true",
    return_fields: "form_component",
  });
  return res.data;
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) => {
  console.log(file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
