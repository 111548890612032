import React from "react";
import {
  InputBase,
  makeStyles,
  IconButton,
  Collapse,
  ListItemText,
  ListItem,
  List,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { AuthContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  inputInput: {
    height: "0.5em",
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)}px) !important`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  inputRoot: {
    fontSize: "12px",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  searchIcon: {
    padding: "3px 5px !important",
    position: "absolute !important",
    marginTop: "2px",
    pointerEvents: "none !important",
    display: "flex!important",
    alignItems: "center !important",
    justifyContent: "center!important",
  },
  search: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    borderTop: "none",
    borderBottom: "1.5px solid rgba(0, 0, 0, 0.12) !important",
    marginBottom: "10px",
    width: "100%",
  },
  catagerie: {
    textTransform: "capitalize",
    padding: "3px 10px",
    marginBottom: "4px",
    borderBottom: "1.2px solid #e7e7e7",
    color: "#5b5b5c",
  },
}));

const SectionComp = ({ setShowSection, setOpenDialog, groupedComponents }) => {
  const auth = React.useContext(AuthContext);
  const classes = useStyles();
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleSearch = (e, index) => {
    setSearch(e.target.value.toLowerCase());
  };
  const handleFilter = React.useMemo(
    () =>
      groupedComponents?.section?.length > 0 &&
      groupedComponents?.section?.filter((e) =>
        e?.componentName?.toLowerCase().includes(search)
      ),
    [groupedComponents?.section, search]
  );
  console.log(handleFilter);
  const handleSectionScreen = (index, id) => {
    setShowSection(true);
    auth.user.selectedSection = id;
    setSelectedIndex(index);
    auth.setAuth({
      ...auth.user,
    });
  };
  return (
    <>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon
            style={{
              fontSize: "16px",
            }}
            fontSize={"small"}
            color={"action"}
          />
        </div>
        <div>
          <InputBase
            placeholder="search"
            value={search}
            onChange={handleSearch}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div>
        <div>
          <IconButton
            size="small"
            onClick={() => {
              setShowSection(true);
              setOpenDialog(true);
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <List>
        <ListItem
          component="nav"
          disablePadding
          className={classes.catagerie}
          button
          onClick={handleClick}
        >
          <ListItemText>Section</ListItemText>
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="nav" disablePadding>
            {handleFilter &&
              handleFilter.map((e, i) => (
                <ListItem
                  button
                  selected={selectedIndex === i}
                  className={classes.catagerie}
                >
                  <ListItemText
                    primary={e.componentName}
                    onClick={() => handleSectionScreen(i, e.id)}
                  />
                </ListItem>
              ))}
          </List>
        </Collapse>
      </List>
    </>
  );
};

export default SectionComp;
