import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";
import { Home, NotFound } from "../screens";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Home Route */}

        <Route exact path={Routes.home}>
          <Home />
        </Route>

        
        {/* Login Route */}

        <Route exact path="*" component={NotFound} />



      </Switch>
    </Router>
  );
};

export default RouterApp;
