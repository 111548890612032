import React from "react";
import { TextField, Chip } from "@material-ui/core";

function AddOptions(props) {
  const inputValue = React.useRef(null);
  const [data, setData] = React.useState([]);
  const [moveTitle, setMoveTitle] = React.useState(false);
  const [mouseOver, setMouseover] = React.useState(false);
  React.useEffect(() => {
    props.handleChange(props.name, data);
  }, [props.name, data]);

  const handleDelete = (item) => {
    const selectedData = [...data];
    selectedData.splice(selectedData.indexOf(item), 1);
    setData(selectedData);
  };
  console.log(data);
  const handleKeyEnter = (e) => {
    console.log("checkonKeyEnter", inputValue.current.value.trim(), data);
    if (
      e.key === "Enter" &&
      data.indexOf(inputValue.current.value.trim()) === -1
    ) {
      setData((pre) => [...pre, inputValue.current.value.trim()]);
      inputValue.current.value = "";
    } else if (
      e.key === "Enter" &&
      data.indexOf(inputValue.current.value.trim()) !== -1
    ) {
      inputValue.current.value = "";
    }
  };
  console.log(data);
  return (
    <>
      <div
        style={{
          position: "relative",
          padding: "0.5rem",
          border: `1px solid ${mouseOver ? "#000" : " #F2F3F6"}`,
          borderRadius: "4px",
          display: "flex",
          flexWrap: "wrap",
          boxSizing: "border-box",
        }}
        onBlur={() => data.length === 0 && setMoveTitle((pre) => !pre)}
        onClick={() => setMoveTitle(true)}
        onMouseEnter={() => setMouseover(true)}
        onMouseLeave={() => setMouseover(false)}
      >
        <label
          style={{
            color: "rgba(0, 0, 0, 0.54)",
            fontWeight: "300",
            lineHeight: "normal",
            fontSize: "12px",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            top: moveTitle ? "-10px" : "25%",
            left: moveTitle ? "1px" : "5px",
            backgroundColor: "white",
            transition: "all .2s ease",
            transform: moveTitle ? "scale(0.7)" : "scale(0.8)",
            padding: "0 0.8em 0 0.5em",
          }}
          onClick={() => setMoveTitle((pre) => !pre)}
        >
          Options
        </label>
        {data.map((item, index) => (
          <Chip
            variant="outlined"
            size="small"
            key={item}
            label={item}
            onDelete={() => handleDelete(index)}
          />
        ))}
        <input
          style={{ border: "none", outline: "none" }}
          ref={inputValue}
          onKeyDown={(e) => handleKeyEnter(e)}
        />
      </div>
    </>
  );
}

export default AddOptions;
