import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({}));

export const MuiTextArea = (props) => {
  const classes = useStyles(props);
  const { value, placeholder, onChange, minRows, maxRows } = props;
  return (
    <TextareaAutosize
      maxRows={maxRows}
      minRows={minRows}
      aria-label="text-area"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={clsx(classes.textArea)}
      style={{
        border: "1px solid lightGrey",
        borderRadius: "5px",
      }}
      //   {...props}
    />
  );
};

MuiTextArea.defaultProps = {
  onChange: () => {},
  maxRows: 6,
  minRows: 3,
  placeholder: "",
  fontSize: "14px",
  value: "",
};
