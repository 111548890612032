import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import { AuthContext } from "../../../../contexts";
// import { PropertiesPanel, IdmPanel, StylesPanel } from "../../components";
import { PropertiesPanel } from "./propertyspanel";
import { StylesPanel } from "./stylesPanel";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "fixed",
    height: "100vh",
    borderLeft: "2px solid #ebebeb",
  },
  tapcss: {
    fontSize: 11,
    textTransform: "none",
    // borderTopLeftRadius: 6,
    // borderTopRightRadius: 6,
    minWidth: 50,
    backgroundColor: "#fff",
    "&.MuiTab-root": {
      textTransform: "capitalize",
      padding: 0,
      margin: 0,
    },
  },
  scroller: {
    backgroundSize: "20px 20px",
    backgroundColor: "#ebebeb6e",
    "& .MuiTabs-scroller": {
      overflowX: "auto !important",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "white",
    },
    "& .MuiTabs-indicator": {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      height: 4,
    },
  },
  close: {
    position: "absolute",
    right: 0,
    zIndex: 9,
  },
  panelHead: {
    display: "flex",
    justifyContent: "space-between",
    backgroundSize: "20px 20px",
    backgroundColor: "#ebebeb6e",
    // backgroundImage: 'linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)',
    "& .text": {
      backgroundColor: "#fff",
      display: "inline-block",
      padding: 10,
      borderTopRightRadius: 6,
    },
  },
  closeIconMargin: {
    // margin: theme.spacing(1),
  },
  tabfont: {
    fontFamily: "Roboto Helvetica Arial sans-serif",
    fontWeight: 400,
    // "& .MuiTab-root": {
    //   minWidth: "30px",
    //   minHeight: "35px",
    // },
  },
}));

export default function UiBuilderPanel(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {/* <IconButton
        color="primary"
        component="span"
        className={classes.close}
        onClick={() => props.onClose("right")}
      >
        <CloseIcon />
      </IconButton> */}
      {/* <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={
          {
            paddingTop:"5px",
            borderBottom:"1px solid #e7e7e7",
            height:"40px",
        }
        }
      >
      <Button
              disabled={auth.user.loading}
              variant={"outlined"}
              color={"primary"}
              size="small"
              style={{ fontSize:"10px", marginLeft:"5px",}}
              disableElevation
              onClick={handleSaveScreen}
              
            >
              Save Screen
            </Button>
            <Button
              disabled={auth.user.loading}
              onClick={(e) => props.build()}
              variant={"outlined"}
              color={"primary"}
              size={"small"}
              style={{ fontSize:"10px", marginRight:"5px",  }}
            >
              Build
            </Button>
      </Grid>  */}
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        indicatorColor="primary"
        // className={`${classes.scroller} scroll-bar`}
        textColor="primary"
        centered
        variant="fullWidth"
        TabIndicatorProps={{ style: { background: "#808080" } }}
        className={classes.tabfont}
      >
        <Tab
          label={
            <h6
              style={{
                fontSize: "10px",
                textTransform: "capitalize !important",
                fontWeight: 300,
                margin: "0",
                color: "#9f9f9f",
              }}
            >
              Properties
            </h6>
          }
          {...a11yProps(0)}
          style={{
            minWidth: 50,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: "white",
          }}
          className={classes.tapcss}
        />
        <Tab
          label={
            <h6
              style={{
                fontSize: "10px",
                textTransform: "capitalize !important",
                fontWeight: 300,
                margin: "0",
                color: "#9f9f9f",
              }}
            >
              Styles
            </h6>
          }
          {...a11yProps(1)}
          style={{
            minWidth: 50,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: "white",
          }}
          className={classes.tapcss}
        />
        {/* <Tab
          label={
            <h6
              style={{
                fontSize: "10px",
                textTransform: "capitalize !important",
                fontWeight: 300,
                margin: "0",
                color: "#9f9f9f",
              }}
            >
              Idm Settings
            </h6>
          }
          {...a11yProps(2)}
          style={{
            minWidth: 50,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: "white",
          }}
          className={classes.tapcss}
        /> */}
      </Tabs>
      <div style={{ overflow: "auto", height: "80vh" }}>
        <TabPanel value={value} index={0}>
          {props.showproperties && (
            <PropertiesPanel
              onClose={props.onClose}
              showSection={props.showSection}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {props.showproperties && <StylesPanel onClose={props.onClose} />}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* <IdmPanel close={props.onClose} /> */}
        </TabPanel>
      </div>
    </div>
  );
}
