import makeStyles from "@material-ui/core/styles/makeStyles";

////property panel styles
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  panelHead: {
    display: "flex",
    justifyContent: "space-between",
    backgroundSize: "20px 20px",
    backgroundColor: "#ebebeb6e",
    // backgroundImage: 'linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)',
    "& .text": {
      backgroundColor: "#fff",
      display: "inline-block",
      padding: 10,
      borderTopRightRadius: 6,
    },
  },
  container: {
    padding: "0px",
  },
  propertyList: {
    fontSize: "12px",
    color: "#5e5f60",
  },
  styleList: {
    color: "#848181",
    fontSize: "12px",
  },
  expandIcon: {
    fontSize: "15px",
    color: "#737375",
  },

  listHeaderActive: {
    backgroundColor: "#ebebeb",
    // "& .MuiTypography-root":{
    //   fontSize:14,
    //   fontWeight:500,
    // },
    "& .MuiListItem-root": {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  listHeader: {
    // "& .MuiTypography-root":{
    //   fontSize:14,
    //   fontWeight:500,
    // },
    "& .MuiListItem-root": {
      fontSize: 14,
      fontWeight: 500,
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  lists: {
    marginTop: 10,
    // marginBottom: 10,
    // paddingLeft: 10,
    // paddingRight: 10,

    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F6F6F8 !important",
  },
  delete: {
    cursor: "pointer",
  },
  panelCategory: {
    paddingLeft: 4,
    color: "rgba(0, 0, 0, 0.38)",
    fontWeight: 600,
  },
  selectText: {
    "& .MuiSelect-select.MuiSelect-select": {
      fontSize: 14,
    },
  },
  divider: {
    boxShadow:
      "0 1.5px 1px rgb(0 0 0 / 6%), 0 -0.4px 2px 1px rgb(202 195 195 / 11%)",
    backgroundColor: "white",
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  closeIconMargin: {
    margin: theme.spacing(1),
  },
  IconCard: {
    padding: 5,
    width: 95,
    height: 95,
    display: "flex",
    margin: 5,
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dialog: {
    minWidth: 450,
    padding: "20px 8px",
    overflowY: "inherit",
  },
  dialogContent: {
    display: "Grid",
    gridGap: "16px",
    overflowY: "inherit",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },
  dialogActions: {
    marginTop: "15px",
  },
  TitleInput: {
    margin: "12px 24px",
  },
  endAdornment: {
    right: "0px !important",
    left: "17px !important",
  },
  inputWithDrop: {
    "& label": {
      fontSize: 10,
      lineHeight: "normal",
      fontWeight: 600,
    },
    "& input": {
      fontSize: 10,
      fontWeight: 600,
      padding: "10px 1px",
      marginLeft: 5,
      marginRight: 5,
    },
    "& fieldset": {
      border: "1px solid #ebebeb",
      // borderRadius: "4px 0px 0px 4px",
      // width:"15px",
    },
  },
  inputTypeDropDown: {
    "& label": {
      fontSize: 10,
      lineHeight: "normal",
      fontWeight: 600,
    },
    "& input": {
      fontSize: 10,
      fontWeight: 600,
    },
    "& fieldset": {
      border: "1px solid #ebebeb",
      borderRadius: "0px 4px 4px 0px",
    },
  },
  inputs: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "0px",
      borderBottom: "1px solid #CACACA",
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px",
    },
    "& label": {
      fontSize: 10,
      lineHeight: "normal",
      fontWeight: 600,
    },
    "& input": {
      fontSize: 10,
      fontWeight: 600,
    },
    "& :hover": {
      "& label": {
        fontSize: 10,
        lineHeight: "normal",
        fontWeight: 600,
      },
      "& input": {
        fontSize: 10,
        fontWeight: 600,
        padding: "10px 1px",
        marginLeft: 5,
        marginRight: 5,
      },
      "& fieldset": {
        border: "1px solid #ebebeb",
        borderRadius: "4px 4px 4px 4px",
        // width:"15px",
      },
    },
  },
  inputsforauto: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "0px",
      // borderBottom: "1px solid #CACACA"
    },
    "& .MuiOutlinedInput-input": {
      // paddingTop: "13px",
      padding: "0px",
    },
  },
  inputformarginpadd: {
    "& label": {
      fontSize: 10,
      lineHeight: "normal",
      fontWeight: 600,
    },
    "& input": {
      fontSize: 10,
      fontWeight: 600,
      padding: "10px 3px",
    },
    "& fieldset": {
      border: "1px solid #ebebeb",
      // borderRadius: "4px 0px 0px 4px",
      // width:"15px",
    },
  },
  label: {
    fontSize: 12,
    lineHeight: "normal",
    fontWeight: 300,
  },
  autocompelete: {
    "& label": {
      fontSize: 12,
      lineHeight: "normal",
      fontWeight: 300,
    },
    "& input": {
      fontSize: 10,
      fontWeight: 600,
    },
    "& fieldset": {
      border: "1px solid #ebebeb",
    },
    "& .ui-builder-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']":
      {
        padding: "6px 6px 9px 6px",
      },
  },
  inputField: {
    "& label": {
      fontSize: 11,
      lineHeight: "normal",
      fontWeight: 300,
    },
    "& input": {
      fontSize: 9,
      fontWeight: 600,
    },
    "& fieldset": {
      border: "1px solid #ebebeb",
    },
  },
  textfieldcss: {
    "& label": {
      fontSize: 12,
      lineHeight: "normal",
      fontWeight: 300,
    },
    "& input": {
      fontSize: 10,
      fontWeight: 600,
    },
    "& fieldset": {
      border: "1px solid #ebebeb",
    },
    "& .ui-builder-MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "13.5px",
    },
  },
  muiselect: {
    "& fieldset": {
      border: "1px solid #ebebeb",
    },
    // "& .MuiSelect-outlined ": {
    //   fontSize: 9,
    //   fontWeight: 600,
    // },
    // "& .MuiSelect-nativeInput": {
    //   fontSize: 9,
    //   fontWeight: 600,
    // },
    "& .ui-builder-MuiSelect-selectMenu": {
      fontSize: 10,
      fontWeight: 600,
      minHeight: "1.5em",
      height: "1.5em",
    },
    "& input": {
      fontSize: 10,
      fontWeight: 600,
    },
  },
}));

export default useStyles;
