/**
 * @author Hari Ram
 * @email hariram@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-01-29
 * @desc Input component
 */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography, Grid } from "@material-ui/core";
import { RequiredField } from "../../common";
import PropTypes from "prop-types";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  date: {
    "& fieldset": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      // borderRight:'px solid',
      borderColor: (props) =>
        props?.errorValidation?.error ? "red" : "rgba(0, 0, 0, 0.23)",
    },
  },
  textField: {
    "& fieldset": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeft: 0,
      borderColor: (props) =>
        props?.errorValidation?.error ? "red" : "rgba(0, 0, 0, 0.23)",
    },
    "& input": {
      backgroundColor: "rgb(245, 245, 245)",
    },
  },
  labelStyle: {
    fontSize: 16,
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));

const DOB = (props) => {

  let {
    inputVariant,
    duration = false,
    size,
    margin,
    format,
    disabled,
    required = false,
    topLabel = "Date Of Birth",
    style,
    ...other
  } = props;

  const classes = useStyles(props);

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props.topLabel}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  const maximumDate = () => {
    return moment(new Date()).format("YYYY-MM-DD");
  };

  const [dob, setDob] = React.useState({
    age: 0,
    periods: "year",
  });

  const [selectedDate, setSelectedDate] = React.useState(null);


  const findAge = (value) => {
    setSelectedDate(value)
    let age;
    let years = moment().diff(value, "years");

    if (years === 0) {
      let months = moment().diff(value, "months");
      if (months === 0) {
        let days = moment().diff(value, "days");
        age = parseFloat("0.0" + days);
        setDob({ age: days, periods: "day" });
      } else {
        age = parseFloat("0." + months);
        setDob({ age: months, periods: "month" });
      }
    } else {
      age = years;
      setDob({ age: years, periods: "year" });
    }


  };

  //   React.useEffect(() => {
  //     if (props?.value?.dob) {
  //       findAge(props?.value?.dob);
  //     }
  //     // eslint-disable-next-line
  //   }, []);

  return (

    <Grid item sm={duration ? 12 : 6} {...other}>
      <Grid
        item
        container
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Typography>
          {topLabel} {required && <RequiredField color={"red"} />}
        </Typography>
      </Grid>


      <Grid item xl={6} md={6} sm={6} xs={12} >
        <TextField
          //onKeyPress={(e) => e.preventDefault()}
          id={props?.id ? props?.id + "Date" : "date"}
          fullWidth
          type={"date"}
          value={selectedDate}
          className={classes.date}
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{
            readOnly: props.isReadonly ?? false,
            max: maximumDate(),
          }}
          onChange={(e) => findAge(e.target.value)}
          disabled={props?.disabled ?? false}
          variant={inputVariant}
          margin={margin}
          format={format}
          size={size}
        />
      </Grid>
      <Grid item xl={6} md={6} sm={6} xs={12} >
        <TextField
          id={props?.id ? props?.id + "Age" : "Age"}
          type={"text"}
          required={props.isrequired}
          fullWidth
          className={classes.textField}
          InputLabelProps={{
            shrink: false,
          }}
          inputProps={{ readOnly: true }}
          variant={inputVariant}
          margin={margin}
          //format={format}
          size={size}
          disabled={true}
          placeholder="Age"
          // onChange={(e) => props.onChange(e.target.value)}
          value={`${dob?.age >= 0
              ? dob?.age > 1
                ? dob?.age + " " + dob?.periods + "s old"
                : dob?.age + " " + dob?.periods + " old"
              : ""
            }`}
        />

      </Grid>
    </Grid>


  );
};

export default DOB;
